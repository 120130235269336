import React, { useState, useEffect, useCallback } from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import PageNotFound from './components/PageNotFound/PageNotFound';
import WatershedReporting from './components/WatershedReporting/WaterShedReporting';
import ProjectManagerStart from './components/ProjectManager/Start';
import ProjectManagerFilter from './components/ProjectManager/Filter';
import ProjectManager from './components/ProjectManager/Manager';
import SubmitProjectInfo from './components/SubmitProjectInfo/SubmitProjectInfo';
import ImportData from './components/ImportData/ImportData';
import Header from './components/Header/Header';
import NavBar from './components/NavBar/NavBar';
import Map from './components/Map/Map';
import MapTools from './components/Map/MapTools';
import TableWrapper from './components/Tables/TableWrapper';
import Home from './components/Home/Home';
import Spinner from './components/Spinner/Spinner';
import AlertModal from './components/AlertModal/AlertModal';
import AlertModal_Export from './components/AlertModal_Export/AlertModal_Export';
import AlertModal_Calc from './components/AlertModal_Calc/AlertModal_Calc';
import SliderContainer from './components/Slider/SliderContainer'; 
// import PopupTemplate from './components/Map/PopupTemplate';
import { useAppState } from './services/App_State_Service';
import { useRegions } from './services/Region_Service';
import { useCriteria } from './services/Criteria_Service';
import { useProjects } from './services/Projects_Service';
import {BiChevronsUp, BiChevronsDown} from 'react-icons/bi';
import './App.css';
import { SplitPaneBottom } from './components/Slider/SplitPane';

function App() {
  const [mapView,setMapView] = useState(null);
  const [canOpenOutcome, setCanOpenOutcomes] = useState(false);
  const [tableSelected, setTableSelected] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showOutcomesButton, setShowOutcomesButton] = useState(false);  
  const [selectedRegion,setSelectedRegion] = useState({scale:null,region:null});
  const [selectedScale, setSelectedScale] = useState('');
  const [selectedRegionName, setSelectedRegionName] = useState('');
  const appStateSvc = useAppState();
  const regionSvc = useRegions();
  const criteriaSvc = useCriteria();
  const projectSvc = useProjects();



  // Init & One-Time Data Load
  useEffect( () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // setSelectedRegionName(selectedRegion.selectedRegion.region.value);
    projectSvc.fetchWQProjectList();
  },[]);

  return (
    <>
    {appStateSvc.makeAlert == true ? <AlertModal/> : null}
    {appStateSvc.makeAlert_Export == true ? <AlertModal_Export/> : null}
    {appStateSvc.makeAlert_Calc == true ? <AlertModal_Calc/> : null}
    <div className="App">
      <Header/>
      <NavBar/>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
              return (
                <Redirect to="/home" />
              )
          }}
        />
        <Route exact path="/home" children={routeProps => (
          <div className="MainContentWrapper" style={{}}>
            <Map/>
            <Home></Home>
          </div>
        )} />
        <Route exact path="/explore-data" children={routeProps => (
          <>
                <div className="MainContentWrapper" id="MainContentWrapper" style={{}}>
                  <Map />
                  {appStateSvc.mapView ? <MapTools /> : null}
                </div>
                {projectSvc.filteredProjectPlanningList.length ? <SliderContainer>
                  <div className="hide-upper-node">
                    {appStateSvc.showTable == true ? <button onClick={() => appStateSvc.addSliderHeight()} title="Move Slider Up" class="slider-nav-buttons"><span><BiChevronsUp/></span></button> : null}
                    {appStateSvc.showTable == true ? <button onClick={() => appStateSvc.decreaseSliderHeight()} title="Move Slider Down" class="slider-nav-buttons"><span><BiChevronsDown/></span></button> : null}
                  </div>
                  <TableWrapper />
                </SliderContainer> : null}
          </>
        )} />
        <Route path="/project-planning/start" children={routeProps => (
          <div className="MainContentWrapper" style={{}}>
            <Map/>
            <ProjectManagerStart/>
          </div>
        )}/>
        <Route path="/project-planning/filter" children={routeProps => (
          <div className="MainContentWrapper" style={{}}>
            <Map/>
            <ProjectManagerFilter />
          </div>
        )}/>
        <Route path="/project-planning/manage" children={routeProps => {
          if(regionSvc.selectedRegionName && criteriaSvc.selectedScale){
            return(
              <>
                <div className="MainContentWrapper" id="MainContentWrapper" style={{}}>
                  <Map />
                  <ProjectManager />
                  {appStateSvc.mapView ? <MapTools /> : null}
                  {/* <SliderContainer>
                    <div className="hide-upper-node"></div>
                    <TableWrapper />
                  </SliderContainer> */}
                </div>
                <SliderContainer>
                  <div className="hide-upper-node">
                    {appStateSvc.showTable == true ? <button onClick={() => appStateSvc.addSliderHeight()} title="Move Slider Up" class="slider-nav-buttons"><span><BiChevronsUp/></span></button> : null}
                    {appStateSvc.showTable == true ? <button onClick={() => appStateSvc.decreaseSliderHeight()} title="Move Slider Down" class="slider-nav-buttons"><span><BiChevronsDown/></span></button> : null}
                  </div>
                  <TableWrapper />
                </SliderContainer>
              </>
            )
          }
          else{
            return(<Redirect to="/project-planning/filter"/>);
          }
        }}/>
        <Route path="/submit-project-info" children={routeProps => (
          <div className="MainContentWrapper" style={{height: "100%"}}>
            <Map />
            <SubmitProjectInfo></SubmitProjectInfo>
          </div>
        )}/>
        <Route path="/import-data" children={routeProps => (
          <div className="MainContentWrapper" style={{height: "100%"}}>
            <Map />
            <ImportData></ImportData>
          </div>
        )}/>
        <Route path="/watershed-reporting" children={routeProps => (
          <div className="MainContentWrapper" style={{}}>
            {appStateSvc.buffering ? <Spinner/>: null}
            <Map />
            <WatershedReporting></WatershedReporting>
          </div>
        )}/>
        {/* Route to test out components in isolation */}
        <Route path="/workshop">
          <Spinner/>
        </Route>
        <Route component={PageNotFound}/>
        
      </Switch>
      {/* <Footer/> */}
    </div>
    </>
  );
}

export default App;
