import React, {useContext, useState, createContext} from 'react';
import { useProjects } from './Projects_Service';
import { useAppState } from './App_State_Service';
import config from '../config';

const RegionContext = createContext(null);


export const RegionProvider = ({children, ...service}) => {
    const [region, setRegion] = useState([]);
    const [selectedRegion,setSelectedRegion] = useState({scale:null,region:null});
    const [selectedRegionName, setSelectedRegionName] = useState('');
    const [filteredRegion, setFilteredRegion] = useState([]);
    const [toggleRegions, setToggleRegions] = useState(false);
    const [floodplainConn, setFloodplainConn] = useState([]);
    const [streamConn, setStreamConn] = useState([]);
    const [protectionRank, setProtectionRank] = useState([]);
    const [watershedReporting, setWatershedReporting] = useState([]);
    const [aggregationScale, setAggregationScale] = useState([]);
    const projectSvc = useProjects();
    const appStateSvc = useAppState();
    

    const getAllRegions = () => {
        return (
            fetch(`${config.API_HOST}/get_regions`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Application/JSON'
            },
            body: JSON.stringify({}) 
            })
        )
    };

    //Floodplain (Lateral-Vertical) Connectivity
    const get_LatConnFilters = () => {
        return fetch(`${config.API_HOST}/get_latconnfilters`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Application/JSON'
            },
            body: JSON.stringify({})
        })
    };

    //Stream (Longitidinal-Temporal) Connectivity
    const get_LongConnFilters = () => {
        return fetch(`${config.API_HOST}/get_longconnfilters`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Application/JSON'
            },
            body: JSON.stringify({})
        })
    };

    //Protection Rank
    const get_ProtectionFilters = () => {
        return fetch(`${config.API_HOST}/get_protectionfilters`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Application/JSON'
            },
            body: JSON.stringify({})
        })
    };

    //Watershed Position
    const get_PositionFilters = () => {
        return fetch(`${config.API_HOST}/get_positionfilters`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Applicaiton/JSON'
            },
            body: JSON.stringify({})
        })
    };

    //Aggregation Scale
    const get_AggregationScale = () => {
        return fetch(`${config.API_HOST}/get_aggregationscale`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Applicaiton/JSON'
            },
            body: JSON.stringify({})
        })
    };

    const get_ConnSummaryReport = (payload, str) => {
        return fetch(`${config.API_HOST}/get_connectivitysummaryreport`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Application/JSON'
            },
            body: JSON.stringify(payload)
        })
        .then((res) => {
            return res.json()
        })
        .then((resJson) => {
            console.log('resJson', resJson);
            console.log('resJson[0].get_connectivitysummaryreport', resJson[0].get_connectivitysummaryreport);
            appStateSvc.setBuffering(false);
            if(resJson[0].get_connectivitysummaryreport.length){
                console.log('if');
                appStateSvc.setMakeAlert_Export(true);
                appStateSvc.setReportToExport(str);
                return projectSvc.setWatershedExportData(resJson[0].get_connectivitysummaryreport);
            } else {
                console.log('else');
                appStateSvc.setMakeAlert(true);
                appStateSvc.setAlertText('No results found');
                return;
            }
        })
        .catch((err) => console.log('get_ConnSummaryReport err', err));
    }

    const get_ConnDetailsReport = (payload, str) => {
        return fetch(`${config.API_HOST}/get_connectivitydetailreport`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Application/JSON'
            },
            body: JSON.stringify(payload)
        })
        .then((res) => {
            return res.json()
        })
        .then((resJson) => {
            appStateSvc.setBuffering(false);
            if(resJson[0].get_connectivitydetailreport.length){
                appStateSvc.setMakeAlert_Export(true);
                appStateSvc.setReportToExport(str);
                return projectSvc.setConnDetailsExportData(resJson[0].get_connectivitydetailreport)
            } else {
                appStateSvc.setMakeAlert(true);
                appStateSvc.setAlertText('No results found');
                return;
            }
        })
        .catch((err) => console.log('get_connectivitydetailreport err', err));
    }

    const get_ProjectReport = (payload, str) => {
        return fetch(`${config.API_HOST}/get_projectreport`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Application/JSON'
            },
            body: JSON.stringify(payload)
        })
        .then((res) => {
            return res.json();
        })
        .then((resJson) => {
            console.log('get_projectreport resJson', resJson);
            appStateSvc.setBuffering(false);
            if(resJson[0].get_projectreport.length){
                appStateSvc.setMakeAlert_Export(true);
                appStateSvc.setReportToExport(str);
                return projectSvc.setProjReportExport(resJson[0].get_projectreport);
            } else {
                appStateSvc.setMakeAlert(true);
                appStateSvc.setAlertText('No results found');
                return;
            }
        })
        .catch((err) => console.log('get_projectreport err', err));
    }
    //should the promise handling happen 
    const get_ResiliencyReport = (payload, str) => {
        return fetch(`${config.API_HOST}/get_resiliencyreport`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Application/JSON'
            },
            body: JSON.stringify(payload)
        })
        .then((res) => {
            return res.json();
        })
        .then((resJson) => {
            console.log('get_resiliencyreport resJson', resJson);
            appStateSvc.setBuffering(false);

            if(resJson[0].get_resiliencyreport.length){
                appStateSvc.setMakeAlert_Export(true);
                appStateSvc.setReportToExport(str);
                return projectSvc.setResilReportExport(resJson[0].get_resiliencyreport);
            } else {
                appStateSvc.setMakeAlert(true);
                appStateSvc.setAlertText('No results found');
                return;
            }
        })
        .catch((err) => console.log("get_resiliencyreport err", err));
    }
//this db function gets the filter for the WQ resiliency report
    const get_projectSizeFilters = () => {
        return fetch(`${config.API_HOST}/get_projectsizefilters`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Application/JSON'
            },
            body: JSON.stringify({})
        })
        .then((res) => {
            return res.json()
        })
        .then((resJson) => {
            return resJson[0].get_projectsizefilters;
        })
        .catch((err) => console.log("get_projectsizefilters err", err))
    }
//this db function gets the filter for the WQ project report
    const get_projectPriorityFilters = () => {
        return fetch(`${config.API_HOST}/get_projectpriorityfilters`, {
            method: 'POST',
            headers: {
                'authorization': config.token,
                'Content-Type': 'Application/JSON'
            },
            body: JSON.stringify({})
        })
        .then((res) => {
            return res.json()
        })
        .then((resJson) => {
            // console.log("get_projectpriorityfilters resJson", resJson[0].get_projectpriorityfilters)
            return resJson[0].get_projectpriorityfilters
        })
        .catch((err) => console.log('get_projectpriorityfilters err', err));
    }



    const value = {
        selectedRegion: selectedRegion,
        selectedRegionName: selectedRegionName,
        region: region,
        filteredRegion: filteredRegion,
        toggleRegions: toggleRegions,
        floodplainConn: floodplainConn,
        streamConn: streamConn,
        protectionRank: protectionRank,
        watershedReporting: watershedReporting,
        aggregationScale: aggregationScale,
        setSelectedRegion: (val) => setSelectedRegion(val),
        setSelectedRegionName: (val) => setSelectedRegionName(val),
        setRegion: (val) => setRegion(val),
        setFilteredRegion: (val) => setFilteredRegion(val),
        setToggleRegions: (val) => setToggleRegions(val),
        setFloodplainConn: (val) => setFloodplainConn(val),
        setStreamConn: (val) => setStreamConn(val),
        setProtectionRank: (val) => setProtectionRank(val),
        setWatershedReporting: (val) => setWatershedReporting(val),
        setAggregationScale: (val) => setAggregationScale(val),
        getAllRegions: getAllRegions,
        get_LatConnFilters: get_LatConnFilters,
        get_LongConnFilters: get_LongConnFilters,
        get_ProtectionFilters: get_ProtectionFilters,
        get_PositionFilters: get_PositionFilters,
        get_ConnSummaryReport: get_ConnSummaryReport,
        get_AggregationScale: get_AggregationScale,
        get_ConnDetailsReport: get_ConnDetailsReport,
        get_ProjectReport: get_ProjectReport,
        get_ResiliencyReport: get_ResiliencyReport,
        get_projectSizeFilters: get_projectSizeFilters,
        get_projectPriorityFilters: get_projectPriorityFilters
    };
    return (
        <RegionContext.Provider value={value}>
            {children}
        </RegionContext.Provider>
    );
};

export const useRegions = () => {
    return useContext(RegionContext);
};