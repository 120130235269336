import React, { useEffect, useState } from 'react';
import LayerList from "@arcgis/core/widgets/LayerList";
import {LayersIcon} from '../SVGs/Icon';
import { useAppState } from '../../services/App_State_Service';
import './Layers.scss';

export default function Layers(props) {
    const [layersExpanded, setLayersExpanded] = useState(false);
    const appStateSvc = useAppState();
    const [hideManager, setHideManager] = useState(false);
    useEffect( () => {
			var layerList = new LayerList({
				view: appStateSvc.mapView,
				container: document.getElementById("layer-widget-target"),
                listItemCreatedFunction: function(event) {
                    const item = event.item;
                    if (item.layer.type != "group") {
                      // don't show legend twice
                      item.panel = {
                        content: "legend",
                        open: true
                      };
                    }
                  }
			});
	},[appStateSvc.mapView]);

    return (
        // <div className={hideManager? "layers-container layers-container-hidden" : "layers-container layers-container-shown"}>
        <div className="layers-container">
            <div className="layers-content">
                <div className="layers">
                    <div className="layers-header">
                        {/* <button 
                            onClick={() => {setHideManager(!hideManager)}} 
                            style={
                                hideManager ? 
                                {transform: "translateX(-32px) rotate(0deg)", transition: "200ms ease", margin: 0}  : 
                                {transform: "translateX(270px) rotate(-180deg)", transition: "200ms ease", margin: 0}
                            }
                        >
                            {"<"}
                        </button> */}
                    </div>
                    <div className="layer-accordion-container" id="layers-container">
                        <button className="layer-accordion" onClick={() => setLayersExpanded(!layersExpanded)} id="layers-button">
                            <LayersIcon/>
                            Layers
                        </button>
                        <div id="layer-widget-target" 
                            style={layersExpanded ? {'display': 'block'} : {'display': 'none'}}
                            className="layer-panel">
                            Layers
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}