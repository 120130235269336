import SplitPane, {
    Divider,
    SplitPaneTop,
    SplitPaneBottom
} from './SplitPane';
import React, {useState} from 'react';
import './SliderContainer.scss';

function SliderContainer(props){
    return(
        <div className="slider-container" id="slider-container">
            <SplitPane className="split-pane-row" splitdirection="updown" id="split-pane-row">
                <SplitPaneTop>
                    {props.children[0]}
                </SplitPaneTop>
                <Divider className="separator-row"/>
                <SplitPaneBottom>
                    {props.children[1]}
                </SplitPaneBottom>
            </SplitPane>
        </div>
    )
};

export default SliderContainer;