import {React, useEffect, useState} from 'react';
import {useProjects} from '../../services/Projects_Service';
import {useAppState} from '../../services/App_State_Service';
import { useRegions } from '../../services/Region_Service';
import { useCriteria } from '../../services/Criteria_Service';
import {BsFillTriangleFill} from 'react-icons/bs';
import Select from 'react-select';

export default function ResilReport(props){
    const [scaleLevels, setScaleLevels] = useState([
        { value: 'BASINS', label: 'Watershed Planning Basins' },
        { value: 'HUC10', label: 'HUC10' },
        { value: 'HUC12', label: 'HUC12'},
        { value: 'TOWNS', label: 'By Town' },
        { value: 'COUNTIES', label: 'By County' }
    ]);
    const [geographyOpen, setGeographyOpen] = useState(false);
    const [floodplainOpen, setFloodplainOpen] = useState(false);
    const [streamOpen, setStreamOpen] = useState(false);
    const [protectionOpen, setProtectionOpen] = useState(false);
    const [watershedOpen, setWatershedOpen] = useState(false);
    const [aggregationOpen, setAggregationOpen] = useState(false);
    const [projSizeOpen, setProjSizeOpen] = useState(false);

    const [checkedGeography, setCheckedGeography] = useState([]);
    const [checkedFloodplain, setCheckedFloodplain] = useState([]);
    // const [checkedStream, setCheckedStream] = useState([]);
    const [checkedProtectionRank, setCheckedProtectionRank] = useState([]);
    const [checkedWatershed, setCheckedWatershed] = useState([]);
    const [checkedAggScale, setCheckedAggScale] = useState('');
    const [projSize, setProjSize] = useState('');
    const [projSizeRes, setProjSizeRes] = useState([]);
    //used in tracking seperate instances of the filtered region in each reporrt
    const [localFilteredRegion, setLocalFilteredRegion] = useState([]);
    const [selectedScale, setSelectedScale] = useState(null);


    const projectSvc = useProjects();
    const appStateSvc = useAppState();
    const regionSvc = useRegions();
    const criteriaSvc = useCriteria();

    useEffect(() => {
        let fullScale = [
            { value: 'BASINS', label: 'Watershed Planning Basins' },
            { value: 'HUC10', label: 'HUC10' },
            { value: 'HUC12', label: 'HUC12'},
            { value: 'TOWNS', label: 'By Town' },
            { value: 'COUNTIES', label: 'By County' }
        ];
        let filteredScale;
        if(checkedAggScale == 'Basin'){
            filteredScale = fullScale.filter((item) => item.value === 'BASINS');
        } else if (checkedAggScale == 'Huc10'){
            filteredScale = fullScale.filter((item) => item.value === 'BASINS' || item.value === 'HUC10');
        } else if (checkedAggScale == 'Huc12'){
            console.log('checkedAggScale', checkedAggScale);
            filteredScale = fullScale.filter((item) => item.value !== 'COUNTIES' && item.value !== 'TOWNS');
        } else if (checkedAggScale == 'County'){
            filteredScale = fullScale.filter((item) => item.value !== 'TOWNS');
        } else {
            filteredScale = fullScale;
        }
        setScaleLevels(filteredScale);
    }, [checkedAggScale]);

    function clearResults(){
        setCheckedGeography([]);
        setCheckedFloodplain([]);
        setCheckedProtectionRank([]);
        setCheckedWatershed([]);
        setCheckedAggScale('');
        setProjSize('');
        setSelectedScale(null);
        setLocalFilteredRegion([])
    }

    function scaleSelected(val){
        setCheckedGeography([]);
        
        if(!val || val == ''){
            regionSvc.setToggleRegions(false);
            criteriaSvc.setToggleCriteria(false);
            criteriaSvc.setSelectedScale('');
            regionSvc.setSelectedRegion('');
            criteriaSvc.setCriteriaList({});
            setSelectedScale(null)
            return null;
        }
        regionSvc.setSelectedRegion(''); // ARH
        regionSvc.setSelectedRegionName(''); // ARH
        regionSvc.setToggleRegions(true);
        criteriaSvc.setSelectedScale(val.value);
        setSelectedScale(val.value)
        sortRegions(val.value);
    }

    function sortRegions(val){
        let catcher = [];
        if(regionSvc.region.length && val){
            for(let i = 0; i < regionSvc.region.length; i++){
                if(val === regionSvc.region[i].scale.toUpperCase()){
                    catcher.push({
                        value: regionSvc.region[i].region,
                        label: regionSvc.region[i].region,
                        regionid: regionSvc.region[i].regionid,
                        scale: regionSvc.region[i].scale
                    });
                }
            }
            setLocalFilteredRegion(catcher)
        };
    };

    function toggleProjSize(str){
        //what are the conditions?
        //checkedAggScale !== 'FFI Sub-unit'
        if(str == projSize){
            setProjSize('');
        }
        else{
            setProjSize(str);
        }
    }

    function genResilPdf(str){
        let regionsArr = []
        let regionsArrName = [];
        for(let i = 0; i < checkedGeography.length; i++){
            regionsArr.push(checkedGeography[i].regionid);
            regionsArrName.push(checkedGeography[i].value);
        }
        let payload = {
            'regionsfilter': regionsArr,
            'latconnfilters': checkedFloodplain,
            // 'longconnfilters': checkedStream,
            'protectionfilters': checkedProtectionRank,
            'positionfilters': checkedWatershed,
            'scale': selectedScale,
            'aggregation': checkedAggScale,
            //resiliency
            'prjtsizefilters': projSize
        };

        let payload2 = {
            'regionsfilter': regionsArrName.length ? regionsArrName : '-',
            'latconnfilters': checkedFloodplain.length ? checkedFloodplain.toString() : '-',
            // 'longconnfilters': checkedStream,
            'protectionfilters': checkedProtectionRank.length ? checkedProtectionRank.toString() : '-',
            'positionfilters': checkedWatershed.length ? checkedWatershed.toString() : '-',
            'scale': selectedScale ? selectedScale : '-',
            'aggregation': checkedAggScale ? checkedAggScale : '-',
            //resiliency
            'prjtsizefilters': projSize.length ? projSize.toString() : '-'
        };

        if(payload.aggregation == '' || (payload.aggregation == 'FFI Sub-unit' && payload.prjtsizefilters == '')){
            appStateSvc.setMakeAlert(true);
            appStateSvc.setAlertText('Please Enter an Aggregation Scale and Project Filter Size');
            return;
        }
        appStateSvc.setBuffering(true);
        regionSvc.get_ResiliencyReport(payload, str);
        projectSvc.setWqReportHeader(payload2);
    }

    function generateProjectSizeCheckbox(){
        if(projSizeRes.length){
            return projSizeRes.map((size) => {
                return (
                    <div>
                        <input 
                            id={'reg-' + size.priority} 
                            type="checkbox" 
                            onChange={() => toggleProjSize(size.priority)} 
                            checked={size.priority === projSize ? true : false}
                            disabled={checkedAggScale !== 'FFI Sub-unit'}
                        />
                        <label for={'reg-' + size.priority}>{size.priority}</label>
                    </div>
                )
            })
        }
    }

    useEffect(() => {
        // let test = regionSvc.get_projectSizeFilters();
        // console.log('test', test);
        regionSvc.get_projectSizeFilters().then((res) => {
            console.log('ResilReport', res);
            let dataRes = res;
            dataRes = dataRes.sort((a, b) => {
                if(a.sorder > b.sorder){
                    return 1;
                } else{
                    return -1;
                }
            })  
            console.log('dataRes', dataRes)      
            setProjSizeRes(dataRes);
        })
    }, [])

    useEffect(() => {
        if(checkedAggScale !== 'FFI Sub-unit'){
            setProjSize('');
        }
    }, [checkedAggScale]);

    return <>
        <span className="clear-results"><button onClick={() => clearResults()}>Clear</button></span>
        <button className="selectGroupLabel light" onClick={() => setAggregationOpen(!aggregationOpen)}><span className={aggregationOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Aggregation Scale <span id="required">*required</span></button>
        {aggregationOpen ? props.generateAggregationCheckboxes(checkedAggScale, setCheckedAggScale) : null}
        <button className="selectGroupLabel dark" onClick={() => setProjSizeOpen(!projSizeOpen)} aria-label="Project Priority"><span className={projSizeOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Project Size Filter <span id="required">*required</span></button>
        {projSizeOpen ? generateProjectSizeCheckbox() : null}
        <button className="selectGroupLabel light" onClick={() => setGeographyOpen(!geographyOpen)}><span className={geographyOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Geography</button>
        {geographyOpen ? <>
            <Select
                name="select-scale-level"
                className="selectMenu"
                id="scale-select"
                inputId="scale-select-options"
                // name="scale-options"
                options={scaleLevels}
                isMulti={false}
                onChange={(i) => scaleSelected(i)}
                data-testid="scale-select-options"
                // isDisabled={
                //     checkedAggScale === 'Basin' ||

                // }
                value = {
                    scaleLevels.filter(option => option.value == selectedScale)
                }
            />
            {props.generateGeographyCheckboxes(checkedGeography, setCheckedGeography, localFilteredRegion)}
        </> : null}
        <button className="selectGroupLabel dark" onClick={() => setFloodplainOpen(!floodplainOpen)} aria-label="Floodplain (Lateral-Vertical) Connectivity"><span className={floodplainOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Floodplain (Lateral-Vertical) Connectivity</button>
        {floodplainOpen ? props.generateFloodplainCheckboxes(checkedFloodplain, setCheckedFloodplain) : null}
        {/* <button className="selectGroupLabel light" onClick={() => setStreamOpen(!streamOpen)} aria-label="Stream (Longitidinal-Temporal) Connectivity"><span className={streamOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Stream (Longitidinal-Temporal) Connectivity</button> */}
        {/* {streamOpen ? props.generateStreamCheckboxes(checkedStream, setCheckedStream) : null} */}
        <button className="selectGroupLabel light" onClick={() => setProtectionOpen(!protectionOpen)} aria-label="Protection Rank"><span className={protectionOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Protection Rank</button>
        {protectionOpen ? props.generateProtection(checkedProtectionRank, setCheckedProtectionRank) : null}
        <button className="selectGroupLabel dark" onClick={() => setWatershedOpen(!watershedOpen)} aria-label="Watershed Position"><span className={watershedOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Watershed Position</button>
        {watershedOpen ? props.generateResiliency(checkedWatershed, setCheckedWatershed) : null}
        <button className="gen-watershed-report" onClick={() => genResilPdf('resil-report')}>Generate Report</button>
    </>
};