import React, {useEffect, useState} from 'react';
import { useAppState } from '../../services/App_State_Service';
import { useProjects } from '../../services/Projects_Service';
import './Storage.scss'

export default function Storage(){
    const [storageData, setStorageData] = useState({
        stor_conn: null,
        prop_proj_area: null
    });
    const appStateSvc = useAppState();
    const projectsSvc = useProjects();
    // const [conn_proj_area, set_con_proj_area] = useState(null);
    // const [prop_proj_area, set_prop_proj_area] = useState(null);

    function numberInput(e, field, theId){
        let newArr = [];
        let targetObj;
        // if(field == 'prop_proj_area'){
        //     let localProjectArea = appStateSvc.projectArea;
        //     for(let i = 0; i < localProjectArea.length; i++){
        //         if(localProjectArea[i].id == theId){
        //             localProjectArea[i][field] = e.target.value;
        //             break;
        //         };
        //     };
        //     appStateSvc.setProjectArea(localProjectArea);
        // };
        appStateSvc.storageRowObj.forEach((i) => {
            if(Object.keys(i)[0] == theId){
                targetObj = Object.values(i);
            }
        })
        if(!e.target.value){
            targetObj[0][field] = null;
        }else{
            targetObj[0][field] = e.target.value;
        }
        
        appStateSvc.storageRowObj.forEach((i) => {
            if(Object.keys[i] === theId){
                targetObj = i
            }
            newArr.push(i);
        });
        // console.log('newArr', newArr);
        appStateSvc.setStorageRowObj(newArr);
    };

    function lookUpValue(theId, str){
        for(let i = 0; i < appStateSvc.storageRowObj.length; i++){
            // console.log('appStateSvc.storageRowObj', appStateSvc.storageRowObj[i]);
            if(Object.keys(appStateSvc.storageRowObj[i])[0] == theId){
                let target = Object.values(appStateSvc.storageRowObj[i])[0];
                return target[str];
            }
        }
    };

    function generateExistingStorageData(){
        // console.log('appStateSvc.storageRowObj', appStateSvc.storageRowObj)
        return projectsSvc.riverStabilityRes.map((i) => {
            return(
                <tbody>
                    <tr className="storage-table-data">
                        <td className="storage-river-existing-id">
                            <div className="storage-river-existing">
                                <p>{i.ffi_id}</p>
                                <p>Existing</p>
                            </div>
                        </td>
                        <td>{i.stor_conn}</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr className="storage-proposed-data-row">
                        <td className="storage-river-header">
                            <div className="storage-river-proposed">
                                <p></p>
                                <p>Proposed Project Values</p>
                            </div>
                        </td>
                        <td>
                            <label className="hide" for={i.ffi_id + ' exist_stor_conn'}/>
                            <select value = {lookUpValue(i.ffi_id, 'exist_stor_conn')} id={i.ffi_id + ' exist_stor_conn'} onChange={(val) => numberInput(val, 'exist_stor_conn', i.ffi_id)}>
                                {/* <option value="" disabled selected hidden>Select your option</option> */}
                                <option {...i.stor_conn === 'Low' ? 'selected' : null} value="low">Low</option>
                                <option {...i.stor_conn === 'Medium' ? 'selected' : null} value="medium">Medium</option>
                                <option {...i.stor_conn === 'High' ? 'selected' : null} value="high">High</option>
                            </select>
                        </td>
                        <td>
                            <label className="hide" for={i.ffi_id + ' stor_conn-selector'}/>
                            <select value = {lookUpValue(i.ffi_id, 'stor_conn')} id={i.ffi_id + ' stor_conn-selector'} onChange={(val) => numberInput(val, 'stor_conn', i.ffi_id)}>
                                <option value="" disabled selected hidden>Select your option</option>
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                            </select>
                        </td>
                        <td>
                            <input className="storage-number-input" type="number" value = {lookUpValue(i.ffi_id, 'prop_proj_area')} onChange = {(val) => numberInput(val, 'prop_proj_area', i.ffi_id)}/>
                        </td>
                    </tr>
                </tbody>
            )
        })
    }

    return(
        <>
        <table className="storage-table">
            <thead>
                <tr>
                    <th id="storage-river-header">River Corridor ID</th>
                    <th>Existing Reach Connectivity</th>
                    <th>Project Area Connectivity</th>
                    <th>Proposed Project Area (acres)</th>
                </tr>
            </thead>
            {generateExistingStorageData()}
        </table>
        {/* <button>Storage Data Button</button> */}
        </>
    )
}