import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import LocalPopupItem from '../Map/LocalPopupItem';
import {LayersIcon} from '../SVGs/Icon';
import {BsSearch, BsTrash} from 'react-icons/bs';
// import {BsTrash} from 'react-icons/bs';
import {BsFillTriangleFill} from 'react-icons/bs';
import { useProjects } from '../../services/Projects_Service';
import { useCriteria } from '../../services/Criteria_Service';
import { useAppState } from '../../services/App_State_Service';
import { useRegions } from '../../services/Region_Service';
import config from '../../config';


export default function ProjectScreening(props){
    const projectsSvc = useProjects();
    const criteriaSvc = useCriteria();
    const regionSvc = useRegions();
    const appStateSvc = useAppState();

    function renderFilteredProjectPlanningList(){
        // console.log('Testing', projectsSvc.filteredProjectPlanningList);
        let noRiverCount = 1
        for(let i = 0; i < projectsSvc.filteredProjectPlanningList.length; i++){
            if(projectsSvc.filteredProjectPlanningList[i].ffi_id == "No River Corridor"){
                projectsSvc.filteredProjectPlanningList[i].ffi_id = `No River Corridor ${noRiverCount}`;
                noRiverCount++
            }
        };
        return projectsSvc.filteredProjectPlanningList.map((i, idx) => {
            // console.log('i', i)
            let preventCheckbox = false;
            let preventPopup = false;
            //ternary to prevent adding 'No River Corridor' river corridors
            if(i.ffi_id.includes("No River Corridor")){
                preventCheckbox = true;
            }
            if(i.ffi_id.includes("No River Corridor")){
                preventPopup = true;
                // console.log('preventPopup should be true', preventPopup)
            }
            /*
                            <p className="table-spacing table-spacing-6">Habitat Priority</p>
                <p className="table-spacing table-spacing-7">Existing Inundation Vulnerability</p>
                <p className="table-spacing table-spacing-8">Existing Erosion Vulnerability</p>
                <p className="table-spacing table-spacing-9">Number of Priority Projects</p>
                <p className="table-spacing table-spacing-10">Add to Project Calculations</p>
            */
            return <>
                    <div className="dynm_table">
                        <div className="rc-table-spacing-1" onClick={() => toggleRiverCorrSection(i.ffi_id)}>
                            <span className={props.riverCorrShown.length && props.riverCorrShown.includes(i.ffi_id) ? 'arrow-open': 'arrow'}><BsFillTriangleFill/></span>
                            <p className="rc-table-spacing-1">{i.ffi_id}</p>
                        </div>
                        <p className="rc-table-spacing-2">{i.conn_scr}</p>
                        <p className="rc-table-spacing-3">{i.feas_tot}</p>
                        <p className="rc-table-spacing-4">{i.conn_kgyr}</p>
                        <p className="rc-table-spacing-5">{i.ir}</p>
                        <p className="rc-table-spacing-6">{i.ind_vul}</p>
                        <p className="rc-table-spacing-7">{i.er_vul}</p>
                        <p className="rc-table-spacing-8">-</p>
                        <p className="rc-table-spacing-9">{i.rcprojcount}</p>
                        {preventCheckbox ? 
                            <>
                                <label for={i.ffi_id} class="hide">{i.ffi_id}</label><input type="checkbox" className="rc-table-spacing-9 rc-table-spacing-input" aria-labeledby={i.ffi_id} id={i.ffi_id}/>
                            </>
                        :
                            <>
                                <label for={i.ffi_id} class="hide">{i.ffi_id}</label><input type="checkbox" className="rc-table-spacing-9 rc-table-spacing-input" onChange={() => toggleCheckedProjects_River(i)} checked={props.checkedRiverFFI_Ids.indexOf(i.ffi_id) > -1} aria-labeledby={i.ffi_id} id={i.ffi_id}/>
                            </>
                        }
                        {/* <label for={i.ffi_id} class="hide">{i.ffi_id}</label><input type="checkbox" className="rc-table-spacing-9 rc-table-spacing-input" onChange={() => toggleCheckedProjects_River(i)} checked={props.checkedRiverFFI_Ids.indexOf(i.ffi_id) > -1} aria-labeledby={i.ffi_id} id={i.ffi_id}/> */}
                        <div className="gen-sub-section-action">
                            <span className="magnify" onClick={() => magnifyClick(i)}><BsSearch/></span>
                            {preventPopup ? 
                                <>
                                    <span className="outline" id="true"><LayersIcon/></span>
                                </> 
                            : 
                                <>
                                    <span className="outline" id="false" onClick={() => tabIconClick(i.ffi_id, 'river')}><LayersIcon/></span>
                                </>
                            }
                            {/* <span className="outline" onClick={() => tabIconClick(i.ffi_id, 'river')}><LayersIcon/></span> */}
                            <span className="magnify" onClick={() => removeRiverRow(i.ffi_id)}><BsTrash/></span>
                        </div>
                    </div>
                     <section className={props.riverCorrShown.length && props.riverCorrShown.includes(i.ffi_id) ? 'rc_section': 'hide'}>
                        <div className={i.rc_projects.length ? 'river-segment-header' : 'hide'}>
                            <p className="rc-table-spacing rc-sub-table-spacing-1">Project Type</p>
                            <p className="rc-table-spacing rc-sub-table-spacing-2">Connectivity Priority</p>
                            <p className="rc-table-spacing rc-sub-table-spacing-3">Typical Project Unit Cost ($)</p>
                            <p className="rc-table-spacing rc-sub-table-spacing-4">Simulated Watershed Average P Credit (kg/ac-yr)</p>
                        </div>
                        {generateSubSection(i.rc_projects)}
                        <div className={i.st_data.length && i.st_data[0] !== null ? 'stream-segment-header' : 'hide'}>
                            <p>Stream Segment ID</p>
                            <p title="'Max = 100'">Stream (Long-Temp) Connectivity (Score of 0-100)</p>
                            <p>TP Load Assigned to Streams (kg)</p>
                            <p>Number of Priority Projects</p>
                            <p>Add to Project Calculations</p>
                        </div>
                        {streamSegmentSection(i.st_data, i)}
                    </section>
            </>
            
            
        });
    };

    function toggleRiverCorrSection(val){
        if(!props.riverCorrShown.length){
            let localVal = [];
            localVal.push(val);
            props.setRiverCorrShown(localVal);
            return;
        }
        else if(props.riverCorrShown.indexOf(val) < 0){
            let localVal = [];
            for(let i = 0; i < props.riverCorrShown.length; i++){
                localVal.push(props.riverCorrShown[i]);
            }
            // props.localVal.push(val);
            props.setRiverCorrShown(localVal);
            return;
        }
        else{
            let localVal  = props.riverCorrShown.filter((i) => i !== val);
            props.setRiverCorrShown(localVal);
            return;
        }
    };

    function generateSubSection(rc){
        return rc.map((i) => {
            // console.log('i in case this crashes', i);
            if(!i || i == null){
                return null;
            }else{
                return <>
                    <div className="gen-sub-section">
                        <p className="gen-sub-section-text gen-sub-section-content-1">{i.project}</p>
                        <p className="gen-sub-section-text gen-sub-section-content-2">{i.priority}</p>
                        <p className="gen-sub-section-text gen-sub-section-content-3">{i.est_cost ? i.est_cost : '-'}</p>
                        <p className="gen-sub-section-textgen-sub-section-content-4">{i.wshd_pcredit || i.wshd_pcredit == 0 ? i.wshd_pcredit : '-'}</p>
                    </div>
                </>
            }

        })
    };

    function streamSegmentSection(val, riverRow){
        // console.log('val', val)
            return val.map((i) => {
                if(i && i !== null){
                    return <>
                        <div className="stream-segment-content">
                            <p onClick={() => toggleStreamSegmentSection(i.ffi_id)} className="arrow-container"><span /*className="arrow" */ className={props.streamSegmentShown.length && props.streamSegmentShown.includes(i.ffi_id) ? 'arrow-open': 'arrow'}><BsFillTriangleFill/></span> {i.ffi_id}</p>
                            <p>{i.st_con_scr}</p>
                            <p>{i.stcon_kgyr ? i.stcon_kgyr : '0'}</p>
                            <p>{i.stprojcount}</p>
                            <input className="stream-segment-content-input" type="checkbox" onChange={() => toggleCheckedProjects_Streams(i)} /*checked={Object.keys(appStateSvc.streamRowObj).indexOf(i.ffi_id) > -1} */ checked={appStateSvc.checkedStreams.length && appStateSvc.checkedStreams[0].ffi_id == i.ffi_id}/>
                            <div className="gen-sub-section-action">
                                <span className="magnify" onClick={() => magnifyClick(i)}><BsSearch/></span>
                                <span className="outline" onClick={() => tabIconClick(i.ffi_id, 'stream')}><LayersIcon/></span>
                                <span className="magnify" onClick={() => removeStreamRow(i, riverRow)}><BsTrash/></span>
                            </div>
                        </div>
                        <section className={props.streamSegmentShown.length && props.streamSegmentShown.includes(i.ffi_id) ? null: 'hide'}>
                            <div className={i.st_projects.length ? "st-projects-header" : 'hide'} >
                                {/* <p id="st-projects-header-proj-id">Project Id</p> */}
                                <p id="st-projects-header-proj-type">Project Type</p>
                                <p className="filler">Connectivity Priority</p>
                                <p>Typical Project Unit Cost ($)</p>
                                {/* <p>Avoided Damages ($)</p> */}
                                <p>Simulated Watershed Average P Credit (kg/ac-yr) </p>
                            </div>
                            {generateStreamSegmentContent(i.st_projects)}
                        </section>
                    </>
                }else{
                    return null
                }
            })
    };

    function generateStreamSegmentContent(val){
        // console.log('generateStreamSegmentContent val', val);
        return val.map((i) => {
            return <>
                <div className="st-projects-content">
                    {/* <p className="st-projects-contents-text" id="st-proj-id-content">{i.projid}</p> */}
                    <p className="st-projects-contents-text" id="st-proj-type-content">{i.project}</p>
                    <p className="st-projects-contents-text">{i.priority}</p>
                    <p className="st-projects-contents-text">{i.est_cost}</p>
                    {/* <p className="st-projects-contents-text">{i.avoid_damages}</p> */}
                    <p className="st-projects-contents-text">{i.wshd_pcredit ?  i.wshd_pcredit : '-'}</p>
                    {/* <input className="st-projects-contents-input" type="checkbox"/> */}
                </div>
            </>
        })
    };

    function magnifyClick(a){
        // console.log('firing', a);
        if(a.featuretype == 'stream'){
            projectsSvc.setSelectedFeatureType('stream');
            return projectsSvc.setSelectedProjects(a.ffi_id);
        }else if (a.featuretype == 'rivercorridor'){
            projectsSvc.setSelectedFeatureType('rivercorridor');
            return projectsSvc.setSelectedProjects(a.ffi_id);
        }
    };

    function removeRiverRow(id){
        let newData = projectsSvc.filteredProjectPlanningList.filter((i) => i.ffi_id !== id);
        if(!newData.length){
            props.cancelButton_PJP();
            appStateSvc.setCheckedRivers([]);
            appStateSvc.setRiverRowObj([]);
        }else{
            if(appStateSvc.checkedRivers.length){
                let catcherArr = [];
                for(let i = 0; i < appStateSvc.checkedRivers.length; i++){
                    if(appStateSvc.checkedRivers[i].ffi_id !== id){
                        catcherArr.push(appStateSvc.checkedRivers[i]);
                    }
                }
                if(catcherArr.length !== appStateSvc.checkedRivers.length){
                    appStateSvc.setCheckedRivers(catcherArr);
                }
            }

            if(appStateSvc.riverRowObj.length){
                let catcherArr = [];
                for(let i = 0; i < appStateSvc.riverRowObj.length; i++){
                    if(Object.keys(appStateSvc.riverRowObj[i])[0] !== id){
                        catcherArr.push(appStateSvc.riverRowObj[i]);
                    }
                }
                if(catcherArr.length !== appStateSvc.riverRowObj.length){
                    appStateSvc.setRiverRowObj(catcherArr);
                }
            }
        }
        projectsSvc.setFilteredProjectPlanningList(newData);
    }

    function removeStreamRow(row, val){
        // console.log('removeStreamRow', row, val);
        let catcher = [];
        let riverId = val.ffi_id
        for(let i = 0; i < projectsSvc.filteredProjectPlanningList.length; i++){
            if(projectsSvc.filteredProjectPlanningList[i].ffi_id === riverId){
                // console.log('row check', projectsSvc.filteredProjectPlanningList[i]);
                let localTarget = projectsSvc.filteredProjectPlanningList[i];
                localTarget.st_data = localTarget.st_data.filter((k) => k.ffi_id !== row.ffi_id);
                // console.log('final check of localTarget', localTarget);
                catcher.push(localTarget);
            }else{
                // console.log('else')
                catcher.push(projectsSvc.filteredProjectPlanningList[i]);
            }
        }
        projectsSvc.setFilteredProjectPlanningList(catcher);
    }

    function toggleRiverCorrSection(val){
        if(!props.riverCorrShown.length){
            let localVal = [];
            localVal.push(val);
            props.setRiverCorrShown(localVal);
            return;
        }
        else if(props.riverCorrShown.indexOf(val) < 0){
            let localVal = [];
            for(let i = 0; i < props.riverCorrShown.length; i++){
                localVal.push(props.riverCorrShown[i]);
            }
            localVal.push(val);
            props.setRiverCorrShown(localVal);
            return;
        }
        else{
            let localVal  = props.riverCorrShown.filter((i) => i !== val);
            props.setRiverCorrShown(localVal);
            return;
        }
    };

    function toggleStreamSegmentSection(val){
        if(!props.streamSegmentShown.length){
            let localVal = [];
            localVal.push(val);
            props.setStreamSegmentShown(localVal);
            return;
        }else if (props.streamSegmentShown.indexOf(val) < 0){
            let localVal = [];
            for(let i = 0; i < props.streamSegmentShown.length; i++){
                localVal.push(props.streamSegmentShown[i]);
            }
            localVal.push(val);
            props.setStreamSegmentShown(localVal);
            return;
        }else{
            let localVal = props.streamSegmentShown.filter((i) => i !== val);
            props.setStreamSegmentShown(localVal);
            return;
        }
    };

    function tabIconClick(uid, target){
        // console.log('tabiconClick is firing', uid, target);
        appStateSvc.setPopupType(target);
        appStateSvc.setSelectedMapId(uid);
        appStateSvc.mapView.when().then(() => {
            let targetLayer;
            let targetLayer_title;
            if(appStateSvc.mapView){
                for(let i = 0; i < appStateSvc.mapView.allLayerViews.items.length; i++){
                    if(target == 'river' && appStateSvc.mapView.allLayerViews.items[i].layer.title == config.riverName){                        
                        targetLayer = appStateSvc.mapView.allLayerViews.items[i].layer;
                        targetLayer_title = appStateSvc.mapView.allLayerViews.items[i].layer.title;
                        break;
                    }else if (target == 'stream' && appStateSvc.mapView.allLayerViews.items[i].layer.title == config.streamName){
                        targetLayer_title = appStateSvc.mapView.allLayerViews.items[i].layer.title;
                        targetLayer = appStateSvc.mapView.allLayerViews.items[i].layer;
                        break;
                    }
                }
                if (appStateSvc.mapView.layerViews){
                    let popupWidgetTarget = document.getElementById("popup-widget-target");
                    if(popupWidgetTarget){
                        ReactDOM.unmountComponentAtNode(document.getElementById("popup-widget-target"))
                        document.getElementById("popup-widget-target").innerHTML = "";
                    }
                    let layerQuery = targetLayer.createQuery();
                    
                    if(target == 'river'){
                        layerQuery.where = "FFI_ID = '"  + uid + "'";
                    }else if(target == 'stream'){
                        layerQuery.where = "dissid2021 = '" + uid + "'"
                    }
                    
                    
                    let htmlString = targetLayer.popupTemplate.content[0].text;
                    // console.log('htmlString', htmlString);
                    // console.log('layerQuery', layerQuery);
                    targetLayer.queryFeatures(layerQuery).then((theRes) => {
                        // console.log('theRes', theRes);
                        let idToFetch;
                        if(targetLayer_title == config.streamName){
                            idToFetch = theRes.features[0].attributes.dissid2021;
                            // console.log('idToFetch', idToFetch);
                            projectsSvc.fetchPopupProjectList_Stream(idToFetch).then((res) => {
                                return res.json()
                            })
                            .then((resJson) => {
                                // console.log('stream resJson', resJson);
                                let projects = resJson[0].get_projectlistbystream;
                                // console.log('projects', projects, projects[0])
                                if(projects[0] !== null){
                                    // console.log('firing')
                                    handleStreamProjects(projects, idToFetch);
                                }
                                if(projects == null){
                                    appStateSvc.setHideProjScreeningBtn(true)
                                  }else{
                                    appStateSvc.setHideProjScreeningBtn(false)
                                  }
                                let newContainer = document.getElementById("popup-widget-target");
                                ReactDOM.render(<LocalPopupItem popupAttributes={theRes.features[0].attributes} htmlStr = {htmlString} layerTitle={targetLayer_title} projects={projects}/>, newContainer)
                            })
                            .catch((err) => {
                                console.log('query err', err);
                            });
                        }else if (targetLayer_title == config.riverName){
                            idToFetch = theRes.features[0].attributes.ffi_id;
                            projectsSvc.fetchPopupProjectList_River(idToFetch).then((res) => {
                                return res.json()
                              })
                            .then((resJson) => {
                                // console.log('river resJson', resJson);
                                let projects = resJson[0].get_projectlistbyriver;
                                if(projects == null){
                                    appStateSvc.setHideProjScreeningBtn(true)
                                  }else{
                                    appStateSvc.setHideProjScreeningBtn(false)
                                  }
                                let newContainer = document.getElementById("popup-widget-target");
                                ReactDOM.render(<LocalPopupItem popupAttributes={theRes.features[0].attributes} htmlStr = {htmlString} layerTitle={targetLayer_title} projects={projects}/>, newContainer)
                            })
                            .catch((err) => {
                                console.log('query err', err);
                            });
                        }
                    })
                    .catch((err) => console.log('query error', err))
                }
            }
        });
    };

    function handleStreamProjects(arr, id){
        // console.log('arr', arr, id);
        let localArr = []
        for(let i = 0; i < arr.length; i++){
            // console.log('arr of i', arr[i])
            if(appStateSvc.streamProjects.indexOf(arr[i].projects) < 0){
                localArr.push({
                    'project': arr[i].project,
                    'checked': false,
                    'projectid': arr[i].projectid
                });
            }
        };
        // console.log('localArr post loop', localArr);
        appStateSvc.setStreamProjects(localArr);
        // console.log('final test', appStateSvc.streamProjects);
    };

    function toggleCheckedProjects_River(row){
        // console.log('firing')
        // console.log('row', row);
        let theId = row.ffi_id
        let newArr = [];
        let localRiverObjData = [];
        let localStorageObjData = [];
        // let localProjectArea = [];
        let remove = false;

        if(appStateSvc.summaryRows_Rivers.length || 
        appStateSvc.wq_Rivers.length || 
        appStateSvc.wq_Streams.length ||
        appStateSvc.resilUnits.length ||
        appStateSvc.resilRisk.length ||
        appStateSvc.resilSummary){
            appStateSvc.setSummaryRows_Rivers([]);
            appStateSvc.setWq_Rivers([]);
            appStateSvc.setWq_Streams([]);
            appStateSvc.setResilUnits([]);
            appStateSvc.setResilRisk([]);
            appStateSvc.setResilSummary([]);
            props.setHighlight_wqBenefit(false);
            props.setHighlight_floodBenefit(false);
            // props.setHighlight_calcInput(false);
        }

        for(let i = 0; i < appStateSvc.checkedRivers.length; i++){
            if(appStateSvc.checkedRivers[i].ffi_id == theId){
                remove = true;
                break;
            }
        };
        if(remove){
            //logic for the check box defaultCheck
            let checkbox = props.checkedRiverFFI_Ids.filter(i => i !== theId);
            props.setCheckedRiverFFI_Ids(checkbox);
            //logic for dynamic rows in phosphorus calc table
            appStateSvc.riverRowObj.forEach((i) => {
                // console.log('method check', i, Object.keys(i)[0]);
                if(Object.keys(i)[0] !== theId){
                    localRiverObjData.push(i);
                }
            })
            // console.log('localRiverObjData', localRiverObjData)
            appStateSvc.setRiverRowObj(localRiverObjData);

            appStateSvc.storageRowObj.forEach((i) => {
                if(Object.keys(i)[0] !== theId){
                    localStorageObjData.push(i);
                };
            });
            appStateSvc.setStorageRowObj(localStorageObjData);

            for(let i = 0; i < appStateSvc.checkedRivers.length; i++){
                if(appStateSvc.checkedRivers[i].ffi_id !== theId){
                    newArr.push(appStateSvc.checkedRivers[i]);
                }
            }
            // console.log('newArr removal', newArr);
            if(!newArr.length && !appStateSvc.checkedStreams.length){
                props.setHighlight_wqBenefit(false);
                props.setHighlight_floodBenefit(false);
                props.setHighlight_calcInput(false);
            } else if (newArr.length){
                console.log('got ya!')
                props.setHighlight_calcInput(true);
            }
            return appStateSvc.setCheckedRivers(newArr);
        }else{
            //logic for the check box defaultCheck
            props.setHighlight_calcInput(true);
            appStateSvc.setDefaultAutoCheck_river(true);
            let checkbox = props.checkedRiverFFI_Ids;
            checkbox.push(theId);
            props.setCheckedRiverFFI_Ids(checkbox);


            let storageValues = {
                stor_conn: null,
                exist_stor_conn: null,
                prop_proj_area: null
            };

            let newStorageObj = {
                [theId]: storageValues
            };


            localStorageObjData.push(newStorageObj);
            for(let i = 0; i < appStateSvc.storageRowObj.length; i++){
                localStorageObjData.push(appStateSvc.storageRowObj[i]);
            }
            // console.log('localStorageObjData', localStorageObjData);
            appStateSvc.setStorageRowObj(localStorageObjData);
            // console.log('else')
            //logic for dynamic rows in phosphorus calc table
            let values = {
                prop_ir: null,
                ext_ir: null,
                // rd_miles: null,
                // temp_deduc: null,
                ag_acres: null,
                robust: null,
                mod: null,
                low: null,
                none: null,
                veg_ac50: null,
                unconsac: null,
                areavertchange: null
            };
            let newObj = {
                [theId]: values
            };
            localRiverObjData.push(newObj);
            for(let i = 0; i < appStateSvc.riverRowObj.length; i++){
                localRiverObjData.push(appStateSvc.riverRowObj[i]);
            };
            appStateSvc.setRiverRowObj(localRiverObjData);
            //logic to capture the river row object from the table
            for(let i = 0; i < appStateSvc.checkedRivers.length; i++){
                newArr.push(appStateSvc.checkedRivers[i]);
            }
            newArr.push(row);
            // console.log('newArr new add', newArr);
            return appStateSvc.setCheckedRivers(newArr);
        }
    };

    function toggleCheckedProjects_Streams(row){
        console.log('row', row);
        //add logic here to track the cells as objects
        let theId = row.ffi_id;
        props.setHighlight_wqBenefit(false);
        props.setHighlight_floodBenefit(false);
        console.log('checkedStreams', appStateSvc.checkedStreams);
        if(!appStateSvc.checkedStreams.length || appStateSvc.checkedStreams[0].ffi_id !== theId){
            appStateSvc.mapView.when().then(() => {
                let targetLayer;
                let targetLayer_title;
                if(appStateSvc.mapView){
                    for(let i = 0; i < appStateSvc.mapView.allLayerViews.items.length; i++){
                        if(appStateSvc.mapView.allLayerViews.items[i].layer.title == config.streamName){
                            targetLayer_title = appStateSvc.mapView.allLayerViews.items[i].layer.title;
                            targetLayer = appStateSvc.mapView.allLayerViews.items[i].layer;
                            break;
                        }
                    }
                    if (appStateSvc.mapView.layerViews){
                        let popupWidgetTarget = document.getElementById("popup-widget-target");
                        if(popupWidgetTarget){
                            ReactDOM.unmountComponentAtNode(document.getElementById("popup-widget-target"))
                            document.getElementById("popup-widget-target").innerHTML = "";
                        }
                        let layerQuery = targetLayer.createQuery();
                        layerQuery.where = "dissid2021 = '" + theId + "'";
                        let htmlString = targetLayer.popupTemplate.content[0].text;
                        // console.log('htmlString', htmlString);
                        // console.log('layerQuery', layerQuery);
                        targetLayer.queryFeatures(layerQuery).then((theRes) => {
                            // console.log('theRes', theRes);
                            let idToFetch;
                            if(targetLayer_title == config.streamName){
                                idToFetch = theRes.features[0].attributes.dissid2021;
                                // console.log('idToFetch', idToFetch);
                                projectsSvc.fetchPopupProjectList_Stream(idToFetch).then((res) => {
                                    return res.json()
                                })
                                .then((resJson) => {
                                    // console.log('stream resJson', resJson);
                                    let projects = resJson[0].get_projectlistbystream;
                                    // console.log('projects', projects, projects[0])
                                    if(projects[0] !== null){
                                        // console.log('firing')
                                        handleStreamProjects(projects, idToFetch);
                                        // appStateSvc.setStreamQueryArr(projects);
                                    }
                                    let newContainer = document.getElementById("popup-widget-target");
                                    ReactDOM.render(<LocalPopupItem popupAttributes={theRes.features[0].attributes} htmlStr = {htmlString} layerTitle={targetLayer_title} projects={projects}/>, newContainer)
                                })
                                .catch((err) => {
                                    console.log('query err', err);
                                });
                            }else if (targetLayer_title == config.riverName){
                                idToFetch = theRes.features[0].attributes.ffi_id;
                                projectsSvc.fetchPopupProjectList_River(idToFetch).then((res) => {
                                    return res.json()
                                  })
                                .then((resJson) => {
                                    // console.log('river resJson', resJson);
                                    let projects = resJson[0].get_projectlistbyriver;
                                    let newContainer = document.getElementById("popup-widget-target");
                                    ReactDOM.render(<LocalPopupItem popupAttributes={theRes.features[0].attributes} htmlStr = {htmlString} layerTitle={targetLayer_title} projects={projects}/>, newContainer)
                                })
                                .catch((err) => {
                                    console.log('query err', err);
                                });
                            }
                        })
                        .catch((err) => console.log('query error', err))
                    }
                }
            });
            let values = {
                rd_miles: null,
                ag_acres: null,
                ir: null,
                prop_str_projects: null,
                ffi_id: theId
                // long_deduc: null,
                // temp_dudc: null,
            };
            let newStream = [];
            newStream.push(row);
            // console.log('what am I putting in here', theId, values, newObj);
            appStateSvc.setStreamRowObj([values]);
            appStateSvc.setCheckedStreams(newStream);
            props.setHighlight_calcInput(true);
        }else{
            console.log('else');
            appStateSvc.setCheckedStreams([]);
            appStateSvc.setStreamRowObj([]);
            appStateSvc.setStreamProjects([]);
            if(appStateSvc.checkedRivers.length){
                props.setHighlight_calcInput(true);
            }else{
                props.setHighlight_calcInput(false);
            }
        }
    };

    return(
        <div className="table" style={{paddingBottom: appStateSvc.currentSliderHeight + 20}}>
            <div class="river_corr_div">
                <p className="table-spacing table-spacing-1">River Corridor ID</p>
                <p className="table-spacing table-spacing-2" title="'Max = 100'">Floodplain (Lat-Vert) Connectivity (Score of 0-100)</p>
                <p className="table-spacing table-spacing-3" title="'Max = 100'">Total Feasibility (Score of 0-100)</p>
                <p className="table-spacing table-spacing-4">TP Load Assigned to Floodplains (kg)</p>
                <p className="table-spacing table-spacing-5">Incision Ratio</p>
                <p className="table-spacing table-spacing-6">Existing Inundation Vulnerability</p>
                <p className="table-spacing table-spacing-7">Existing Erosion Vulnerability</p>
                <p className="table-spacing table-spacing-9">Habitat Priority</p>
                <p className="table-spacing table-spacing-8">Number of Priority Projects</p>
                <p className="table-spacing table-spacing-10">Add to Project Calculations</p>
                <p className="table-spacing table-spacing-11"></p>
            </div>
            {renderFilteredProjectPlanningList()}
        </div>
    )
}