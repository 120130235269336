import React, { useEffect, useState } from 'react';
import SearchWidget from "@arcgis/core/widgets/Search";
import { useAppState } from '../../services/App_State_Service';
import './Search.scss'

export default function Search(props) {
	const [searchExpanded,setSearchExpansion] = useState(false);
	const [searchWidget,setSearchListWidget] = useState(null);
    const appStateSvc = useAppState();

    useEffect( () => {
        console.log(appStateSvc.mapView)
        if (appStateSvc.mapView) {
			var newSearchWidget = new SearchWidget({
				view: appStateSvc.mapView,
				container: document.getElementById("searchWidget"),
				popupEnabled: false
			});
			setSearchListWidget(newSearchWidget);
		}
	},[appStateSvc.mapView]);

    const clickExpand = (e) => {
		setSearchExpansion(!searchExpanded);
	}

    return (
        <div className="search-container">
            <div className="search-content">
                <div className="search-accordion-container">
                    <div className="search-header"></div>
                    <button className="search-accordion" onClick={clickExpand} id="find-a-place-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.01" height="24.01" viewBox="0 0 24.01 24.01">
                            <circle cx="10.04" cy="11.04" r="9" style={{"fill":"#fff"}}/>
                            <path d="M23.41,20.59l-5.07-5.08a10,10,0,1,0-2.83,2.83l5.08,5.07a2,2,0,0,0,2.82-2.82ZM3,10a7,7,0,1,1,7,7A7,7,0,0,1,3,10Z" transform="translate(0.04 0.04)" style={{"fill":"#20558b"}}/>
                        </svg>
                        Find a Place
                    </button>
                    <div className="search-panel" style={{display: (searchExpanded ? 'block': 'none')}}>
                        <div id="searchWidget"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
