import React, {useEffect, useState} from 'react';
import StreamStabilityCreditSummary from '../StabilityCreditSummary/StreamStabilityCreditSummary';
import SummarySection from '../SummarySection/SummarySection';
import {BsFillTriangleFill} from 'react-icons/bs';
import { useAppState } from '../../services/App_State_Service';
import { useCriteria } from '../../services/Criteria_Service';
import './WaterQualityBenefit.scss'

export default function WaterQualityBenefit(props){
    const [openSection, setOpenSection] = useState(false);
    const appStateSvc = useAppState();

    function genFloodplain_Conn_Section(){
        // console.log('appStateSvc.wq_Rivers',appStateSvc.wq_Rivers);
        if(!appStateSvc.wq_Rivers.length){
            return null
        };
        return appStateSvc.wq_Rivers.map((i) => {
            // console.log('wq_river', i);
            return<>
                <tr className="floodplain-conn-row">
                    <td>{i.p_ffi_id}</td>
                    {/* <td>{i.stream_name}</td> */}
                    <td>{i.prjt_conncredit}</td>
                    <td>{i.curr_conn_scr}</td>
                    <td>{i.prj_latconncredit}</td>
                    <td>{i.prjt_vertconnncredit}</td>
                    <td>{i.prop_conn_scr}</td>
                    <td>{i.prjt_latpcred}</td>
                    <td>{i.prjt_vertpcred}</td>
                    <td>{i.prjt_pcredit_kgyr}</td>
                    <td>{i.prjt_pcredit_lbyr}</td>
                </tr>
            </>
        })
    };

    function genStream_Conn_Section(){
        // console.log('genStream_Conn_Section firing', appStateSvc.wq_Streams)
        if(!appStateSvc.wq_Streams.length){
            // alert('no length')
            return null
        }
        return appStateSvc.wq_Streams.map((i) => {
            // console.log('wq_stream', i)
            return <>
            <tr className="stream-conn-row">
                <td>{i.p_ffi_id}</td>
                {/* <td>{i.stream_name}</td> */}
                <td>{i.proj_con_scr}</td>
                <td>{i.curr_st_con_scr}</td>
                <td>{i.prop_long_crdt}</td>
                <td>{i.prop_temporal_crdt}</td>
                <td>{i.prop_st_con_scr}</td>
                <td>{i.proj_longp_crdt_kgyr}</td>
                <td>{i.proj_tempp_crdt_kgyr}</td>
                <td>{i.proj_pcrdt_kgyr}</td>
                <td>{i.proj_pcrdt_lbyr}</td>
            </tr>
            </>
        })
    };

    useEffect(() => {props.setHighlight_wqBenefit(false)}, [])

    return(
        <div className="wq-benefit" style={{paddingBottom: appStateSvc.currentSliderHeight + 20}}>
            <SummarySection/>
            <StreamStabilityCreditSummary/>
            <div id="toggle-arrow-header-container" onClick={() => setOpenSection(!openSection)}>
                <span className={openSection ? 'arrow-open' : 'arrow'}><BsFillTriangleFill /></span>
                <h2 className="wq-header">Stream Stability Credit and Connectivity Details</h2>
            </div>
            <div className={openSection ? 'expandable-floodplain-table' : 'hide'} >
                <h3 className="wq-river-header">Floodplain Connectivity (Lateral-Vertical)</h3>
                <table class="wq-benefit-table">
                    <thead>
                        <tr>
                            <th>River Corridor ID</th>
                            {/* <th>Name</th> */}
                            <th>Project Connectivity Credit Score</th>
                            <th>Existing Subunit Floodplain/Corridor Connectivity Score</th>
                            <th>Proposed Lateral Credit Score</th>
                            <th>Proposed Vertical Credit Score</th>
                            <th>Proposed Subunit Floodplain/Connectivity Score</th>
                            <th>Lateral P Reduction Credit (kg/yr)</th>
                            <th>Vertical P Reduction Credit (kg/yr)</th>
                            <th>Total P Reduction Credit (kg/yr)</th>
                            <th>Total P Reduction Credit (lb/yr)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {genFloodplain_Conn_Section()}
                    </tbody>
                </table>
                <h3 className="wq-stream-header">Stream Connectivity (Longitudinal-Temporal)</h3>
                <table className="wq-benefit-table">
                    <thead>
                        <tr>
                            <th>Stream ID</th>
                            {/* <th>Name</th> */}
                            <th>Project Connectivity Credit Score</th>
                            <th>Existing Stream Segment Connectivity Score</th>
                            <th>Proposed Longitudinal Credit Score</th>
                            <th>Proposed Temporal Credit Score</th>
                            <th>Proposed Stream Segment Connectivity Score</th>
                            <th>Longitudinal P Reduction Credit (kg/yr)</th>
                            <th>Temporal P Reduction Credit (kg/yr)</th>
                            <th>Total Stream Connectivity P Credit (kg/yr)</th>
                            <th>Total Stream Connectivity P Credit (lb/yr)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {genStream_Conn_Section()}
                    </tbody>
                </table>
            </div>
        </div>
    )
} 