import React from 'react';
import './Spinner.scss';

export default function Spinner(props){
    return(
        <>
            <div id="spinner-container">
                <div id="spinner"></div>
            </div>
            
        </>
    )
}