import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {CriteriaProvider} from './services/Criteria_Service';
import {ProjectProvider} from './services/Projects_Service';
import {AppStateProvider} from './services/App_State_Service';
import {RegionProvider} from './services/Region_Service';

ReactDOM.render(
  <AppStateProvider>
    <CriteriaProvider>
      <ProjectProvider>
        <BrowserRouter>
            <RegionProvider>
              <App />
            </RegionProvider>
        </BrowserRouter>
      </ProjectProvider>
    </CriteriaProvider>
  </AppStateProvider>
,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
