import React, { useState, createContext, useContext } from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { useAppState } from './App_State_Service';
import config from '../config';

/*
  The Criteria Service is used by the project planning filter
  components Filter.jsx, Manager.jsx, and Criteria.jsx to 
  manage the loading, filtering, and accessing the selected
  criteria by which projects are filtered. This does not
  include the scale and region (for now), but instead
  the project function, project type, applicability, and
  priority.

  To use this service:

  1. Add reference to the service component...
      "import { useCriteria } from '../../services/Criteria_Service';"
  2. In the top of a component's function add a call to
      "const criteriaSvc = useCriteria();"
  3. Then just call the functions on the service...
      "criteriaSvc.getFilterCriteria(scale,region);"

  The service functions that load and process criteria from
  the API save the criteria data into the service, so there
  is no longer a neeed to write something like:

    "criteriaSvc.setCriteriaList(criteriaSvc.getFilterCriteria(scale,region));"
  
*/

const CriteriaContext = createContext(null);

export const CriteriaProvider = ({children, ...services}) => {
  const [checkedFunctions, setCheckedFunctions] = useState({});
  const [checkedProjects, setCheckedProjects] = useState({});
  const [checkedApplicability, setCheckedApplicability] = useState({});
  const [checkedPriorities, setCheckedPriorities] = useState({});
  const [fullFunctionIds, setFullFunctionIds] = useState([]);
  const [fullProjTypeIds, setFullProjTypeIds] = useState([]);
  const [criteriaList,setCriteriaList] = useState({});
  const [selectedScale, setSelectedScale] = useState('');
  const [toggleCriteria, setToggleCriteria] = useState(false);
  const [fullFunctionList, setFullFunctionList] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [riverProjectTypes, setRiverProjectTypes] = useState([]);
  const appStateSvc = useAppState();

  function resetCriteriaChoices(){
    setCheckedFunctions({});
    setCheckedProjects({});
    setCheckedApplicability({});
    setCheckedPriorities({});
  };

  const getAllCriteria = () => {
    return fetch(
      `${config.API_HOST}/get_criteria`, 
      {
        method: 'POST',
        headers: {
          'authorization': config.token,
          'Content-Type': 'Application/JSON'
        },
        body: JSON.stringify({}) 
      }
    )
  };
  
  // needs the scale as the string and the region as the id
  const getFilterCriteria = (theScale, theRegion) => {
    setCheckedFunctions({});
    setCheckedProjects({});
    setCheckedApplicability({});
    setCheckedPriorities({});
    return(
      fetch(
        `${config.API_HOST}/get_functions`, 
        {
          method: 'POST',
          headers: {
            'content-type': config.content_type,
            'authorization': config.token,
          },
          body: JSON.stringify(
            {
              "scale": theScale,
              "region": theRegion
            }
          )
        }
      )
      .then(res => res.json())
      .then(
        resJson => {
          if (resJson[0].get_functions && (resJson[0].get_functions != [])) {
            let tmpCritList = [];
            //targets get_functions
            tmpCritList = resJson[0].get_functions;
            console.log('db res', tmpCritList);
            let tmpFuncList = {};
            let tmpProjTypes = {};
            let tempApplicability = {};
            setFullFunctionList(tmpCritList);
            stashCheckboxes(tmpCritList);
           return filterProjectCriteria(tmpCritList, 'function_call');
          }
        }
      )
      .catch(error => console.error(error))
    )
  }

  const filterProjectCriteria = (val, str) => {
    // console.log('val', val);
    // console.log('criteriaList', criteriaList);
    // console.log('filterProjectCriteria is calling')
    // console.log('checkedFunctions', checkedFunctions);
    // console.log('checkedProjects', checkedProjects);
    // console.log('checkedApplicability', checkedApplicability);
    // console.log('checkedProjects', checkedProjects);
    let outFunctions = {};
    let outTypes = {};
    let outApplicability = [];
    let outPriority = [];
    let outCriteria = {};
    let tmpFuncList = {};
    let tmpProjTypes = {};
    let tempApplicability = {};
    let tempPriority = {};
    let tmpCritList = [];

    if(str == 'function_call'){
      // no length => nothing select; include full val in tmpCritList
      tmpCritList = val;
    }else{
      val.forEach((datum) => {
        // console.log('datum', datum)
        let include = {
          function: false,
          project: false,
          applicability: false,
          priority: false
        };
        // console.log('datum', datum, checkedFunctions, checkedProjects, checkedApplicability, checkedPriorities);

        let functions = Object.keys(checkedFunctions);
        let projects = Object.keys(checkedProjects);
        let applicability = Object.keys(checkedApplicability);
        let priority = Object.keys(checkedPriorities);

        // console.log('testing', functions, projects, applicability, priority);
        if(str !== 'func'){
          if(functions.length){
            for(let i = 0; i < functions.length; i++){
              if(datum['function'] == functions[i]){
                // it includes a checked thing, push it
                include.function = true;
              }
            }
          }
          else{
            include.function = true
          }
        }
        else{
          include.function = true
        }


        // another if/else related to the string which is passed in;
        // if str !== function, then set it to 
        if(str !== 'proj'){
          if(projects.length){
            for(let i = 0; i < projects.length; i++){
              if(datum['project'] == projects[i]){
                // it includes a checked thing, push it
                include.project = true;
              }
            }
          }else{
            include.project = true
          }
        }else{
          include.project = true;
        }
        
        if(str !== 'app'){
          if(applicability.length ){
            for(let i = 0; i < applicability.length; i++){
              if(datum['functionapplicability'] == applicability[i]){
                // it includes a checked thing, push it
                include.applicability = true;
              }
            }
          }else{
            include.applicability = true
          }
        }else{
          include.applicability = true;
        }

        if(str !== 'prior'){
          if(priority.length){
            for(let i = 0; i < priority.length; i++){
              if(datum['priority'] == priority[i]){
                // it includes a checked thing, push it
                include.priority = true;
              }
            }
          }else{
            include.priority = true;
          }
        }else{
          include.priority = true
        }



      if(include.function == true && include.project == true && include.applicability == true && include.priority == true){
        tmpCritList.push(datum)
      }

      })
    }


    console.log('final product', tmpCritList)
    

    tmpCritList.forEach(
      (item) => {
        //this gets all the ky-value pairs we need
        if (!tmpFuncList[item.functiongroup]) {
          tmpFuncList[item.functiongroup] = {};
        }
        if (!tmpProjTypes[item.projecttype]){
          tmpProjTypes[item.projecttype] = {};
        }
        if(!tempApplicability[item.functionapplicability]) {
          tempApplicability[item.functionapplicability] = {};
        }
        if(!tempApplicability[item.priority]){
          tempPriority[item.priority] = {};
        }
        tmpFuncList[item.functiongroup][item.function] = item.functionid;
        tmpProjTypes[item.projecttype][item.project] = item.projectid;
        tempApplicability[item.functionapplicability] = item.functionapplicability;
        tempPriority[item.priority] = item.priority;
      }
    );

    // console.log('tmpFuncList', tmpFuncList)
    // console.log('tempApplicability', tempApplicability)
    
    // null check for project type key
    let tmpProjTypes_nullCheck = Object.keys(tmpProjTypes);
    for (let i = 0; i < tmpProjTypes_nullCheck.length; i++){
      if(tmpProjTypes_nullCheck[i] == 'null' || !tmpProjTypes_nullCheck[i] || tmpProjTypes_nullCheck[i] == ''){
        delete tmpProjTypes[tmpProjTypes_nullCheck[i]]
      }
    }
    // null check for function types
    let tmpFuncList_nullCheck = Object.keys(tmpProjTypes);
    for (let i = 0; i < tmpFuncList_nullCheck; i++){
      if(tmpFuncList_nullCheck[i] == 'null' || tmpFuncList_nullCheck == '' || !tmpFuncList_nullCheck){
        delete tmpFuncList[tmpFuncList_nullCheck[i]]
      }
    }
    // null check for applicability
    let tempApplicability_nullCheck = Object.keys(tempApplicability);
    for (let i = 0; i < tempApplicability_nullCheck; i++){
      if(tempApplicability_nullCheck[i] == 'null' || tempApplicability_nullCheck == '' || !tempApplicability_nullCheck){
        delete tempApplicability[tempApplicability_nullCheck[i]]
      }
    }
    //null check for prority
    let tempPriority_nullCheck = Object.keys(tempPriority);
    for (let i = 0; i < tempPriority_nullCheck; i++){
      if(tempPriority_nullCheck[i] == 'null' || tempPriority_nullCheck == '' || !tempPriority_nullCheck){
        delete tempPriority[tempPriority_nullCheck[i]];
      }
    }
    Object.keys(tmpFuncList).forEach(
      (item) => {
        if (!outFunctions[item]) outFunctions[item] = [];
        Object.keys(tmpFuncList[item]).forEach(
          (funcItem) => {
            outFunctions[item].push({label: funcItem, value: tmpFuncList[item][funcItem]});
          }
        )
      }
    );
    Object.keys(tmpProjTypes).forEach(
      (item) => {
        if (!outTypes[item]) outTypes[item] = [];
        Object.keys(tmpProjTypes[item]).forEach(
          (projItem) => {
            outTypes[item].push({label: projItem, value: tmpProjTypes[item][projItem]});
          }
        )
      }
    );
    // goes through the tempApplicability and extracts its contents to be applied to the outapplicability object
    Object.keys(tempApplicability).forEach(
      (item) => {
        outApplicability.push({name: item, value: item});
      }
    )

    Object.keys(tempPriority).forEach((item) => {
      outPriority.push({name: item, value: item})
    });

    // console.log('outPriority', outPriority)
    // console.log('outTypes', outTypes);

    // stashCheckboxes(outTypes);

    outCriteria.functions = outFunctions;
    outCriteria.projectTypes = outTypes;
    outCriteria.applicability = outApplicability;
    // outCriteria.priorities = {"High": 10, "Medium":5, "Low": 1};
    outCriteria.priorities = outPriority;
    setFullFunctionIds(getAllFunctionIdList(outCriteria));
    setFullProjTypeIds(getAllProjectTypeIds(outCriteria));
    setCriteriaList(outCriteria);
    // console.log('outCriteria', outCriteria, outCriteria.projectTypes['Floodplain (Lateral-Vertical) Connectivity']);

    let riverProjectTypes = outCriteria.projectTypes['Floodplain (Lateral-Vertical) Connectivity'] ? outCriteria.projectTypes['Floodplain (Lateral-Vertical) Connectivity'] : null;
    let catcherArr = [];
    if(riverProjectTypes !== null){
          for(let i = 0; i < riverProjectTypes.length; i++){
          catcherArr.push({
            'project': riverProjectTypes[i].label,
            'checked': false,
            'value': riverProjectTypes[i].value
          });
      };
    };
    setRiverProjectTypes(catcherArr);

    return (outCriteria);
  }

  function stashCheckboxes(arr){
    console.log('argument array', arr);
    let buttons = [];
    let button_ids = [];
    for(let i = 0; i < arr.length; i++){
      // console.log('outer loop')
      if(buttons.indexOf(arr[i].project) < 0){
        buttons.push(arr[i].project)
      }
      // console.log('buttons in loop', buttons);
    };
    return setProjectTypes(buttons);
  }
  
  const getAllFunctionIdList = (functions) => {
    let catcherArray = [];
    let nestedFunctionArrays = Object.values(functions.functions);
    for(let i = 0; i < nestedFunctionArrays.length; i++){
      for (let j = 0; j < nestedFunctionArrays[i].length; j++){
        catcherArray.push(nestedFunctionArrays[i][j].value);
      }
    }
    return catcherArray;
  }
  
  const getAllProjectTypeIds = (proj_types) => {
    let catcherArray = [];
    let nestedFunctionArrays = Object.values(proj_types.projectTypes);
    for(let i = 0; i < nestedFunctionArrays.length; i++){
      for (let j = 0; j < nestedFunctionArrays[i].length; j++){
        catcherArray.push(nestedFunctionArrays[i][j].value);
      }
    }
    return catcherArray;
  }

  const getCheckedCriteria = () => {
    // Process checked functions into a list of just IDs
    let checkedFunctionKeys = Object.keys(checkedFunctions);
    let checkedFunctionIds = [];
    checkedFunctionKeys.forEach((key) => {
      checkedFunctionIds.push(checkedFunctions[key]);
    });
    let checkedFunctionsList = checkedFunctionKeys.length > 0 ? checkedFunctionIds : fullFunctionIds;
    // Process checked projects into a list of just IDs
    let checkedProjectKeys = Object.keys(checkedProjects);
    let checkedProjectIds = [];
    checkedProjectKeys.forEach((key) => {
      checkedProjectIds.push(checkedProjects[key]);
    });
    let checkedProjectList = checkedProjectKeys.length > 0 ? checkedProjectIds : fullProjTypeIds;
    // Process checked applicabilities into a list of just IDs
    let checkedApplicabilityKeys = Object.keys(checkedApplicability);
    // let checkedApplicabilityIds = [];
    // checkedApplicabilityKeys.forEach((key) => {
    //   checkedApplicabilityIds.push(checkedApplicability[key]);
    // });
    let checkedApplicabilityList = checkedApplicabilityKeys.length > 0 ? checkedApplicabilityKeys : ["High", "Medium", "Low"];
    // Process checked priorities into a list of just IDs
    let checkedPriorityKeys = Object.keys(checkedPriorities);
    // let checkedPrioritiesIds = [];
    // checkedPriorityKeys.forEach((key) => {
    //   checkedPrioritiesIds.push(checkedPriorities[key]);
    // });
    let checkedPrioritiesList = checkedPriorityKeys.length > 0 ? checkedPriorityKeys : ["High", "Medium", "Low"];
    //
    let checkedCriteria = {
      functions: checkedFunctionsList,
      applicabilities: checkedApplicabilityList,
      projects: checkedProjectList,
      priorities: checkedPrioritiesList
    };
    return checkedCriteria;
  };

  const getReport_ConnectivitySummary = () => {
    return fetch(
      `${config.API_HOST}/getreport_connectivitysummary`, {
        method: 'POST',
        headers: {
          'authorization': config.token,
          'Content-Type': config.content_type
        },
        body: JSON.stringify({})
      }
    )
  };
  //TODO: add the payloads for these DB functions
  const getReport_ConnectivityDetails = () => {
    return fetch(
      `${config.API_HOST}/getreport_connectivitydetails`, {
        method: 'POST',
        headers: {
          'authorization': config.token,
          'Content-Type': config.content_type
        },
        body: JSON.stringify({})
      }
    )
  };

  const getReport_Projects = () => {
    return fetch(
      `${config.API_HOST}/getreport_connectivitydetails`, {
        method: 'POST',
        headers: {
          'authorization': config.token,
          'Content-Type': config.content_type
        },
        body: JSON.stringify({})
      }
    )
  };

  const getReport_Resiliency = () => {
    return fetch(
      `${config.API_HOST}/getreport_resiliency`, {
        method: 'POST',
        headers: {
          'authorization': config.token,
          'Content-Type': config.content_type
        },
        body: JSON.stringify({})
      }
    )
  };

  const value = {
    checkedFunctions: checkedFunctions,
    checkedProjects: checkedProjects,
    checkedApplicability: checkedApplicability,
    checkedPriorities: checkedPriorities,
    fullFunctionIds: fullFunctionIds,
    fullProjTypeIds: fullProjTypeIds,
    criteriaList: criteriaList,
    selectedScale: selectedScale,
    toggleCriteria: toggleCriteria,
    fullFunctionList: fullFunctionList,
    projectTypes: projectTypes,
    riverProjectTypes: riverProjectTypes,
    setCheckedFunctions: (val) => setCheckedFunctions(val),
    setCheckedProjects: (val) => setCheckedProjects(val),
    setCheckedApplicability: (val) => setCheckedApplicability(val),
    setCheckedPriorities: (val) => setCheckedPriorities(val),
    setSelectedScale: (val) => setSelectedScale(val),
    setCriteriaList: (val) => setCriteriaList(val),
    setToggleCriteria: (val) => setToggleCriteria(val),
    setFullFunctionList: (val) => setFullFunctionList(val),
    setProjectTypes: (val) => setProjectTypes(val),
    setRiverProjectTypes: (val) => setRiverProjectTypes(val),
    getAllCriteria: getAllCriteria,
    getFilterCriteria: getFilterCriteria,
    getCheckedCriteria: getCheckedCriteria,
    filterProjectCriteria: filterProjectCriteria,
    resetCriteriaChoices: resetCriteriaChoices,
    getReport_ConnectivitySummary: getReport_ConnectivitySummary,
    getReport_ConnectivityDetails: getReport_ConnectivityDetails,
    getReport_Projects: getReport_Projects,
    getReport_Resiliency: getReport_Resiliency
  };
  return (
    <CriteriaContext.Provider value={value}>
      {children}
    </CriteriaContext.Provider>
  );
};

export const useCriteria = () => {
  return useContext(CriteriaContext);
};