import React, { useEffect, useState } from 'react';
import {LayersIcon} from '../SVGs/Icon';
import { useAppState } from '../../services/App_State_Service';
import { useProjects } from '../../services/Projects_Service';
import { useCriteria } from '../../services/Criteria_Service';
import './Popup.scss';

export default function Popup(props) {
    const appStateSvc = useAppState();
    const projSvc = useProjects();
    const criteriaSvc = useCriteria();
    const [mapIdsToTrack, setMapIdsToTrack] = useState([]);
    const [popupExpanded,setPopupExpanded] = useState(true);
    useEffect( () => {
			// Do stuff when mapview exists
	},[appStateSvc.mapView, appStateSvc.hideProjScreeningBtn]);

    function callPopupIdFunction(cal){
        projSvc.getProjectList().then((res) => {
            // console.log('get project list res', res)
            return res.json();
        })
        .then((resJson) => {
            // console.log('getProjectList resJson', resJson)
            let data = resJson[0].get_projectlist;
            console.log('get_projectlist data', data)
            let catcherArr = [];
            for(let i = 0; i < data.length; i++){
                if(data[i].projecttype == 'Floodplain (Lateral-Vertical) Connectivity'){
                    let newRow = {
                        checked: false,
                        project: data[i].project,
                        value: data[i].projectid
                    };
                    catcherArr.push(newRow);
                }                
            };
            // console.log('catcherArr', catcherArr);
            criteriaSvc.setRiverProjectTypes(catcherArr);
        })
        .catch((e) => console.log('project list error', e));
        projSvc.fetchProjectPlanningByPopupId(appStateSvc.selectedMapId, appStateSvc.popupType).then((res) => {
            return res.json();
        })
        .then((resJson) => {
            let theRes = resJson[0].get_projectplanning_byffiid;
            // if res is empty, then nothing happens
            if(theRes == null || !theRes.length){
                appStateSvc.setMakeAlert(true);
                appStateSvc.setAlertText('No projects found based on your selection')
                return;
            }
            //collects array of ids to check quickly if the ffi_id is included
            let filteredProjIds = [];
            let localFilteredProjs = [];

            for(let i = 0; i < projSvc.filteredProjectPlanningList.length; i++){
                filteredProjIds.push(projSvc.filteredProjectPlanningList[i].ffi_id);
                localFilteredProjs.push(projSvc.filteredProjectPlanningList[i])
            }
            for(let i = 0; i < theRes.length; i++){
                if(!theRes[i].rc_projects){
                    theRes[i].rc_projects = [];
                }
                
                // if filteredProjId is empty, then no ids, so add it anyway
                if(!filteredProjIds.length){
                    localFilteredProjs.push(theRes[i]);
                    
                }else if(filteredProjIds.indexOf(theRes[i].ffi_id) < 0){
                    //its not included in the index, so push it
                    localFilteredProjs.push(theRes[i]);
                }
                else{
                    //condition set when the item is already included in the o.g. project screening table
                    let idx = filteredProjIds.indexOf(theRes[i].ffi_id);
                    let target = localFilteredProjs.filter((val) => val.ffi_id == theRes[i].ffi_id);
                    if(theRes[i].rc_projects.length && theRes[i].rc_projects[0] !== null){
                        target[0].rc_projects = theRes[i].rc_projects;
                        // target[0].rcprojcount = theRes
                        target[0].rcprojcount = theRes[i].rc_projects.length;
                    }
                    if(theRes[i].st_data.length && theRes[i].st_data[0] !== null){
                        target[0].st_data = theRes[i].st_data;
                    }
                    localFilteredProjs = localFilteredProjs.filter((val) => val.ffi_id !== theRes[i].ffi_id);
                    localFilteredProjs.splice(idx, 0, target[0]);
                    projSvc.setFilteredProjectPlanningList(localFilteredProjs);
                    appStateSvc.setShowTable(true);
                }
            }
            if(!projSvc.filteredProjectPlanningList.length){
                //no length originally, push it directly
                console.log('adding to table')
                appStateSvc.setShowTable(true);
                appStateSvc.setCurrentSliderHeight(500);
                projSvc.setFilteredProjectPlanningList(localFilteredProjs);
                appStateSvc.setTableSelected('projects');
                return;
            }
            if(projSvc.filteredProjectPlanningList.length == localFilteredProjs.length){
                //if these are equal, then nothing new was added
                appStateSvc.setMakeAlert(true);
                appStateSvc.setAlertText('Projects have been added to the project planning table')
                return;
            }else{
                // console.log('mystery condition')
                projSvc.setFilteredProjectPlanningList(localFilteredProjs);
                appStateSvc.setShowTable(true);
                appStateSvc.setCurrentSliderHeight(500);
                appStateSvc.setTableSelected('projects');
            }
        })
        .catch((err) => console.log('fetchProjectPlanningByPopupId err', err))
    }

    return (
        <div className="popup-container">
            <div className="popup-content">
                <div className="popup-accordion-container" id="popup-container">
                    <div className="popup-accordion" id="popup-button">
                        <section onClick={() => setPopupExpanded(!popupExpanded)}>
                            <LayersIcon/>
                            Feature Data 
                        </section>
                        {/* add appStateSvc.selectedMapId to the button */}
                        {appStateSvc.selectedMapId ? <button className="add-proj-screening" onClick={() => callPopupIdFunction(appStateSvc.selectedMapId)}>Add to Project Screening</button> : null}
                    </div>
                    <div id="popup-widget-target" 
                        style={popupExpanded ? {'display': 'block'} : {'display': 'none'}}
                        className="layer-panel">
                        
                    </div>
                </div>
            </div>
        </div>
    )
}