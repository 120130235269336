import React, {useEffect, useState} from 'react';
import { useAppState } from '../../services/App_State_Service';
import { useProjects } from '../../services/Projects_Service';
import { useCriteria } from '../../services/Criteria_Service';
import {BsFillTriangleFill} from 'react-icons/bs';
import SummarySection from '../SummarySection/SummarySection';
import {VscTriangleRight, VscTriangleDown} from 'react-icons/vsc';
import './Resiliency.scss';

export default function Resiliency(props){
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [resilUnits, setResilUnits] = useState([]);
    const [resilRisk, setResilRisk] = useState([]);
    const [resilSummary, setResilSummary] = useState([]);
    const [showInundation, setShowInundation] = useState(false);
    const [showErosion, setShowErosion] = useState(false);
    const appStateSvc = useAppState();
    const projectSvc = useProjects();
    const criteriaSvc = useCriteria();

    useEffect(() => {
        let payload = [];
        let checkedProjs = [];
        // console.log('criteriaSvc.riverProjectTypes + appStateSvc.streamProjects', criteriaSvc.riverProjectTypes, appStateSvc.streamProjects);
        for(let i = 0; i < criteriaSvc.riverProjectTypes.length; i++){
            if(criteriaSvc.riverProjectTypes[i].checked && checkedProjs.indexOf(criteriaSvc.riverProjectTypes[i].value) == -1){
                let projId = criteriaSvc.riverProjectTypes[i].value;
                checkedProjs.push(projId);
            };
        }
        for(let i = 0; i < appStateSvc.streamProjects.length; i++){
            if(appStateSvc.streamProjects[i].checked && checkedProjs.indexOf(appStateSvc.streamProjects[i].value) == -1){
                let projId = appStateSvc.streamProjects[i].projectid;
                checkedProjs.push(projId);
            }
        };
        // console.log('prop_projects', checkedProjs)
        for(let i = 0; i < appStateSvc.riverRowObj.length; i++){
            let unconsac = Object.values(appStateSvc.riverRowObj[i])[0].unconsac ? Object.values(appStateSvc.riverRowObj[i])[0].unconsac : 0;
            let areavertchange = Object.values(appStateSvc.riverRowObj[i])[0].areavertchange ? Object.values(appStateSvc.riverRowObj[i])[0].areavertchange : 0;
            let prop_proj_area = Object.values(appStateSvc.storageRowObj[i])[0].prop_proj_area ? Object.values(appStateSvc.storageRowObj[i])[0].prop_proj_area : 0;
            let max = Math.max(unconsac, areavertchange, prop_proj_area);
            let item = {
                'ffi_id': Object.keys(appStateSvc.riverRowObj[i])[[0]],
                'projectarea': max,
                'prop_projects': checkedProjs
            };
            payload.push(item);
        };
        if(payload.length){
            projectSvc.getProjectResiliency_Unit(payload).then((res) => {
                // console.log('res', res);
                return res.json();
            })
            .then((resJson) => {
                if(resJson[0].calcproject_unitresiliency.length){
                    return appStateSvc.setResilUnits(resJson[0].calcproject_unitresiliency);
                }
            })
            .catch((err) => console.log('getProjectResiliency err', err));
    
            projectSvc.getProjectResiliency_Risk(payload).then((res) => {
                // console.log('res', res);
                return res.json();
            })
            .then((resJson) => {
                if(resJson[0].calcproject_unitrisk.length){
                    return appStateSvc.setResilRisk(resJson[0].calcproject_unitrisk);
                }
            })
            .catch((err) => console.log('getProjectResiliency_Risk err', err));
    
            projectSvc.getProjectResiliency_Summary(payload).then((res) => {
                // console.log('res', res);
                return res.json();
            })
            .then((resJson) => {
                if(resJson[0].calcproject_summaryresiliency.length){
                    return appStateSvc.setResilSummary(resJson[0].calcproject_summaryresiliency);
                }
            })
            .catch((err) => console.log('getProjectResiliency_Summary err', err));
        };
    }, []);

    useEffect(() => {props.setHighlight_floodBenefit(false)}, []);
    //table for Project Vulnerability Benefits Summary
    function projResiliencyTable(){
        if(appStateSvc.resilSummary.length){
            return appStateSvc.resilSummary.map((sum) => {
                return(
                    <>
                        <tr>
                            <td onClick={() => setShowInundation(!showInundation)}><span>{showInundation ? <VscTriangleDown/> : <VscTriangleRight/>}</span>Total</td>
                            <td>Inundation (floodplain)</td>
                            <td className="center">{sum.tot_ibg ? sum.tot_ibg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_itg ? sum.tot_itg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_iag ? sum.tot_iag.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_ig ? sum.tot_ig.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_ibr ? sum.tot_ibr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_itr ? sum.tot_itr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_iar ? sum.tot_iar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_ir ? sum.tot_ir.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        </tr>
                        {inundationTableRow()}
                        <tr>
                            <td onClick={() => setShowErosion(!showErosion)}><span>{showErosion ? <VscTriangleDown/> : <VscTriangleRight/>}</span>Total</td>
                            <td>Erosion (Corridor)</td>
                            <td className="center">{sum.tot_ebg ? sum.tot_ebg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_etg ? sum.tot_etg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_eag ? sum.tot_eag.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_eg ? sum.tot_eg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_ebr ? sum.tot_ebr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_etr ? sum.tot_etr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_ear ? sum.tot_ear.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{sum.tot_er ? sum.tot_er.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        </tr>
                        {erosionTableRow()}
                    </>
                );
            });
        };
    };

    function inundationTableRow(){
        return appStateSvc.resilUnits.map((unit) => {
            return <>
                 <tr style={showInundation ? null : {'display': 'none'}}>
                    <td>{unit.ffi_id_floodplain}</td>
                    <td>Inundation (floodplain)</td>
                    <td className="center">{unit.unit_ibg ? unit.unit_ibg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_itg ? unit.unit_itg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_iag ? unit.unit_iag.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_ig ? unit.unit_ig.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_ibr ? unit.unit_ibr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_itr ? unit.unit_itr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_iar ? unit.unit_iar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_ir ? unit.unit_ir.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                </tr>
            </>
        });
    };

    function erosionTableRow(){
        return appStateSvc.resilUnits.map((unit) => {
            return <>
                <tr style={showErosion ? null : {'display': 'none'}}>
                    <td>{unit.ffi_id}</td>
                    <td>Erosion (corridor)</td>
                    <td className="center">{unit.unit_ebg ? unit.unit_ebg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): 0}</td>
                    <td className="center">{unit.unit_etg ? unit.unit_etg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_eag ? unit.unit_eag.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_eg ? unit.unit_eg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_ebr ? unit.unit_ebr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_etr ? unit.unit_etr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_ear ? unit.unit_ear.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    <td className="center">{unit.unit_er ? unit.unit_er.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                </tr>
            </>
        })
    }

    function riskReductionTable(){
        if(appStateSvc.resilRisk.length){
            return appStateSvc.resilRisk.map((risk) => {
                // console.log('resilRisk', risk)
                return (<>
                    <tr>
                        <td>{risk.ffi_id_floodplain}</td>
                        <td>Inundation (floodplain)</td>
                        <td className="center">{risk.unit_ig_1 ? risk.unit_ig_1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        <td className="center">{risk.unit_ic ? risk.unit_ic.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        <td className="center">{risk.unit_prop_ic_val ? risk.unit_prop_ic_val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        <td className="center">{risk.unit_prop_ic ? risk.unit_prop_ic.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        <td className="center">{risk.i_red_pct ? risk.i_red_pct.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    </tr>
                    <tr>
                        <td>{risk.ffi_id}</td>
                        <td>Erosion (corridor)</td>
                        <td className="center">{risk.unit_eg_1 ? risk.unit_eg_1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        <td className="center">{risk.unit_ec ? risk.unit_ec.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        <td className="center">{risk.unit_prop_ec_val ? risk.unit_prop_ec_val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        <td className="center">{risk.unit_prop_ec ? risk.unit_prop_ec.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        <td className="center">{risk.e_red_pct ? risk.e_red_pct.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                    </tr>
                </>)
            })
        }
    };

    function unitReductionTable(){
        if(appStateSvc.resilUnits.length){
            return appStateSvc.resilUnits.map((unit) => {
                return(
                    <>
                        <tr>
                            <td >{unit.ffi_id_floodplain}</td>
                            <td>Inundation (floodplain)</td>
                            <td className="center">{unit.unit_ibg ? unit.unit_ibg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_itg ? unit.unit_itg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_iag ? unit.unit_iag.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_ig ? unit.unit_ig.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_ibr ? unit.unit_ibr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_itr ? unit.unit_itr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_iar ? unit.unit_iar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_ir ? unit.unit_ir.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        </tr>
                        <tr>
                            <td>{unit.ffi_id}</td>
                            <td>Erosion (corridor)</td>
                            <td className="center">{unit.unit_ebg ? unit.unit_ebg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): 0}</td>
                            <td className="center">{unit.unit_etg ? unit.unit_etg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_eag ? unit.unit_eag.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_eg ? unit.unit_eg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_ebr ? unit.unit_ebr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_etr ? unit.unit_etr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_ear ? unit.unit_ear.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                            <td className="center">{unit.unit_er ? unit.unit_er.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</td>
                        </tr>
                    </>
                )
            })
        }
    }

    return(
        <div className="resiliency-container" style={{paddingBottom: appStateSvc.currentSliderHeight + 20}}>
            <SummarySection/>
            <h3 className="resiliency-table-header">Potential Reduction in Vulnerability (Including Upstream and Downstream Benefits)</h3>
            <div className="resiliency-table-container">
                <table className="resiliency-table">
                    <thead>
                        <tr>
                            <th>{null}</th>
                            <th colspan="4">Value of Vulnerable Infrastructure and Property ($)</th>
                            <th colspan="4">Potential Reduction in Vulnerability ($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="resiliency-header">Corridor or Floodplain Id</td>
                            <td className="resiliency-header">Vulnerability Process</td>
                            <td className="resiliency-header">Building</td>
                            <td className="resiliency-header">Transportation</td>
                            <td className="resiliency-header">Agriculture</td>
                            <td className="resiliency-header">Total</td>
                            <td className="resiliency-header">Building</td>
                            <td className="resiliency-header">Transportation</td>
                            <td className="resiliency-header">Agriculture</td>
                            <td className="resiliency-header">Total</td>
                        </tr>
                        {projResiliencyTable()}
                    </tbody>
                </table>
            </div>
            <h3 className="resiliency-table-header">Local Vulnerability Reduction (Based on Single Corridor or Floodplain Subunit)</h3>
            <div className="resiliency-table-container">
                <table class="resiliency-table">
                    <thead>
                        <tr>
                            <th colSpan={2}>{null}</th>
                            <th colSpan={2}>Existing</th>
                            <th colSpan={2}>Proposed</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="resiliency-header">Corridor or Floodplain ID</td>
                            <td className="resiliency-header">Vulnerability Process</td>
                            <td className="resiliency-header">Initial Value of Vulnerable Infrastructure and Property ($)</td>
                            <td className="resiliency-header">Vulnerability Rank</td>
                            <td className="resiliency-header">Remaining Value of Vulnerable Infrastructure and Property ($)</td>
                            <td className="resiliency-header">Vulnerability Rank</td>
                            <td className="resiliency-header">Vulnerability Reduction (%)</td>
                        </tr>
                        {riskReductionTable()}
                    </tbody>
                </table>
            </div>
            {/* <div className="dropdown-button" onClick={() => setDetailsOpen(!detailsOpen)}>
                <span className={detailsOpen ? 'arrow-open' : 'arrow'}><BsFillTriangleFill /></span>
                <h3 className="resiliency-table-header">Vulnerability Details</h3>
            </div>
            <div className={detailsOpen ? "resiliency-table-container" : "hide"}>
                <table className="resiliency-table">
                    <thead>
                        <tr>
                            <th>{null}</th>
                            <th>{null}</th>
                            <th colspan="4">Value of Vulnerable Infrastructure and Property ($)</th>
                            <th colspan="4">Potential Reduction in Vulnerability ($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="resiliency-header">Corridor or Floodplain ID</td>
                            <td className="resiliency-header">Vulnerability Process</td>
                            <td className="resiliency-header">Building</td>
                            <td className="resiliency-header">Transportation</td>
                            <td className="resiliency-header">Agriculture</td>
                            <td className="resiliency-header">Total</td>
                            <td className="resiliency-header">Building</td>
                            <td className="resiliency-header">Transportation</td>
                            <td className="resiliency-header">Agriculture</td>
                            <td className="resiliency-header">Total</td>
                        </tr>
                        {unitReductionTable()}
                    </tbody>
                </table>
            </div> */}
        </div>
    );
};