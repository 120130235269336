import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Icon } from '../SVGs/Icon';
import Criteria from '../Criteria/Criteria';
import {useRegions} from '../../services/Region_Service';
import { useCriteria } from '../../services/Criteria_Service';
import { useProjects } from '../../services/Projects_Service';
import { useAppState } from '../../services/App_State_Service';
import './Filter.scss';

export default function Filter(props) {
    const history = useHistory();
    const criteriaSvc = useCriteria();
    const regionSvc = useRegions();
    const projectsSvc = useProjects();
    const appStateSvc = useAppState();
    const [scaleLevels, setScaleLevels] = useState([
        { value: 'BASINS', label: 'Watershed Planning Basins' },
        { value: 'HUC10', label: 'HUC10' },
        { value: 'HUC12', label: 'HUC12'},
        { value: 'TOWNS', label: 'By Town' },
        { value: 'COUNTIES', label: 'By County' }
    ]);
    const [toggleThirdStep, setToggleThirdStep] = useState(false);
    const [showSeeProjectsButton, setShowSeeProjectsButton] = useState(false);

    function scaleSelected(val){
        console.log('scale val', val);
        if(!val || val == ''){
            regionSvc.setToggleRegions(false);
            criteriaSvc.setToggleCriteria(false);
            setToggleThirdStep(false);
            criteriaSvc.setSelectedScale('');
            regionSvc.setSelectedRegion('');
            criteriaSvc.setCriteriaList({});
            return null;
        }
        regionSvc.setSelectedRegion(''); // ARH
        regionSvc.setSelectedRegionName(''); // ARH
        regionSvc.setToggleRegions(true);
        sortRegions(val.value);
        criteriaSvc.setSelectedScale(val.value);
    }

    function regionSelected(val){
        console.log('region val', val);
        if(!val || val == ''){
            console.log('if fired')
            console.log('results')
            criteriaSvc.setToggleCriteria(false);
            criteriaSvc.setCriteriaList({});
            setToggleThirdStep(false);
            regionSvc.setSelectedRegion('');
            regionSvc.setSelectedRegionName('');
            return null;
        }
        // setToggleThirdStep(true);
        criteriaSvc.setToggleCriteria(true);
        // props.setSelectedRegions(val);
        fetchCriteria(val.regionid);
        regionSvc.setSelectedRegion({scale:criteriaSvc.selectedScale,region:val});
        regionSvc.setSelectedRegionName(val.value)
        document.cookie = `region_name_cookie=${val.value}`;
        renderSeeProjectsButton()
    }

    function fetchCriteria(region){
        setToggleThirdStep(true);
        console.log(region)
        console.log('it fired')
        document.cookie = `scale_cookie=${criteriaSvc.selectedScale}`;
        document.cookie= `region_cookie=${region}`;
        criteriaSvc.getFilterCriteria(criteriaSvc.selectedScale.toLowerCase(),region);
    }

    function fetchRegions(){
        regionSvc.getAllRegions()
        .then(res => {return res.json()})
        .then(resJson => {
            console.log('regions', resJson)
            let dataRes = resJson[0].get_regions;
            regionSvc.setRegion(dataRes)
        })
        .catch(e => console.warn('warning', e))
    }

    let catcher = [];
    function sortRegions(val){
        if(regionSvc.region.length && val){
            console.log(val)
            console.log(regionSvc.region)
            for(let i = 0; i < regionSvc.region.length; i++){
                if(val === regionSvc.region[i].scale.toUpperCase()){
                    catcher.push({
                        value: regionSvc.region[i].region,
                        label: regionSvc.region[i].region,
                        regionid: regionSvc.region[i].regionid,
                        scale: regionSvc.region[i].scale
                    });
                }
            }
            console.log(catcher)
            regionSvc.setFilteredRegion(catcher)
            return catcher;
        };
    };

    function renderSeeProjectsButton(){
        // ok so only the else is firing and idk why, come back to this one
        if(criteriaSvc.selectedScale && regionSvc.selectedRegion){
            setShowSeeProjectsButton(true)
        }
        else{
            setShowSeeProjectsButton(false)
        }
    }

    // function testFunction(){
    //     if(criteriaSvc.selectedScale && regionSvc.selectedRegion){
    //         regionSvc.setToggleRegions(true);
    //         setToggleThirdStep(true);
    //         criteriaSvc.setToggleCriteria(true);
    //         setShowSeeProjectsButton(true);
    //     }
    // }

    // function handleRegionDefault(){
    //     let localState = props.regionSelected
    //     if(props.regionSelected !== '' || props.regionSelected){
    //         return localState.selectedRegion.region.value
    //     }else{
    //         return null
    //     }
    // }

    const emptyResCheck = () => {
        if(projectsSvc.emptyMasterRes == true){
            // alert('No results found based on your search. Please updates your parameters and try again');
            appStateSvc.setMakeAlert(true);
            appStateSvc.setAlertText('No results found based on your search. Please updates your parameters and try again');
        }else{
            history.push('/project-planning/manage');
        }
    }

    const pushToProjects = () => {
        //final filter before pushing
        criteriaSvc.filterProjectCriteria(criteriaSvc.fullFunctionList, 'push')
        //fetch request
        projectsSvc.fetchProjectPlanningList(regionSvc.selectedRegion, criteriaSvc.getCheckedCriteria())
        emptyResCheck()
        // history.push('/project-planning/manage');
    }

    useEffect(() => {
        fetchRegions()
    }, [])

    useEffect(() => {
        // fetchRegions()
        renderSeeProjectsButton()
        // testFunction()
    }, [criteriaSvc.criteriaList,showSeeProjectsButton])

    return (
        <div className='project-manager-filter'>
            <div className='project-manager-filter-content'>
                <section className="project-filter-header">
                    <div className="icon_project-filter-header">
                        <Icon />
                    </div>
                    <h3>Project Planning</h3>
                </section>
                <div className="filters-container">
                    <form className="filter-scale" >
                        <label htmlFor="scale-select-options"><span className="numbered-list">1</span> Choose Scale</label>
                        <Select
                            name="select-scale-level"
                            className="selectMenu"
                            id="scale-select"
                            inputId="scale-select-options"
                            // name="scale-options"
                            options={scaleLevels}
                            isMulti={false}
                            onChange={(i) => scaleSelected(i)}
                            data-testid="scale-select-options"
                            placeholder={criteriaSvc.selectedScale}
                            value = {
                                scaleLevels.filter(option => option.label == criteriaSvc.selectedScale)
                            }
                        />
                    </form>
                    <div className={regionSvc.toggleRegions == true ? "filter-region" : "hidden"} id="filter-region">
                        <label htmlFor="region-select-options"><span className="numbered-list">2</span>Choose Region</label>
                        {regionSvc.region.length || regionSvc.region !== '' || regionSvc.region !== null ?
                            <Select
                                name="select-scale-option"
                                className="selectMenu"
                                inputId="region-select-options"
                                options={regionSvc.filteredRegion}
                                isMulti={false}
                                onChange={(i) => regionSelected(i)}
                                placeholder={regionSvc.selectedRegionName ? regionSvc.selectedRegion.region.value : null}
                                value = {
                                    regionSvc.filteredRegion.filter(option => option.label == regionSvc.selectedRegionName)
                                }
                            />
                            : <p>Nothing to see here</p>
                        }
                    </div>
                    {toggleThirdStep == true ?
                        <div className="filter-criteria">
                            <p><span className="numbered-list">3</span> Select Project Filter Criteria</p>
                        </div>
                        : false
                    }
                    {criteriaSvc.toggleCriteria ?
                        <Criteria selectedRegion={regionSvc.selectedRegion}/>
                        : false
                    }
                    <div className="submit-button-container">
                        {showSeeProjectsButton ?
                            <button
                                id="see-projects-button"
                                onClick={() => pushToProjects()}>
                                See projects
                            </button>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
