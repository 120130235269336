import React, {useState, useEffect} from 'react';
import {BsFillTriangleFill} from 'react-icons/bs';
import StreamStabilityRivers from '../StreamStability/StreamStabilityRivers';
import StreamStabilityStreams from '../StreamStability/StreamStabilityStreams';
import Storage from '../StorageTable/Storage';
import { useProjects } from '../../services/Projects_Service'; 
import { useAppState } from '../../services/App_State_Service';
import { useCriteria } from '../../services/Criteria_Service';

export default function PhosCreditCalc(props){
    const [showCheckboxSection, setShowCheckboxSection] = useState(false);
    const [showStreamStorageSection, setShowStreamsStorageSection] = useState(false);
    const [showStorage, setShowStorage] = useState(false);
    // const [showStreamStability, setShowStreamStability] = useState(false);
    const [showUpperStreamSection, setShowUpperStreamSection] = useState(true);
    const projectSvc = useProjects();
    const appStateSvc = useAppState();
    const criteriaSvc = useCriteria();

    function toggleCheckboxSection(){
        if(showUpperStreamSection == true){
            return setShowUpperStreamSection(false);
        }else{
            return setShowUpperStreamSection(true)
        }
    }

    function toggleShowStreamStorageSection(){
        return setShowStreamsStorageSection(!showStreamStorageSection);
    }

    function toggleStorageTab(){
        return setShowStorage(!showStorage);
    }

    useEffect(() => {
        // console.log('appStateSvc.checkedRivers', appStateSvc.checkedRivers);
        let payload = [];
        for(let i = 0; i < appStateSvc.checkedRivers.length; i++){
            payload.push(appStateSvc.checkedRivers[i].ffi_id);
        }
        // console.log('what is the payload', payload)
        if(appStateSvc.checkedRivers.length){
            projectSvc.fetchProjCalcInputs_Rivers(payload).then((res) => {
                return res.json()
            })
            .then((resJson) => {
                // console.log('resJson', resJson);
                let data = resJson[0].get_projectcalcinputs_rivers;
                projectSvc.setRiverStabilityRes(data)
            })
            .catch((err) => console.log('fetchProjCalcInputs_Rivers error', err));
        }else{
            projectSvc.setRiverStabilityRes([]);
        }
    }, [appStateSvc.checkedRivers, criteriaSvc.riverProjectTypes]);

    useEffect(() => {
        // console.log('appStateSvc.checkedStreams.ffi_id', appStateSvc.checkedStreams[0].ffi_id);
        if(appStateSvc.checkedStreams.length){
            projectSvc.fetchProjCalcInputs_Streams([appStateSvc.checkedStreams[0].ffi_id]).then((res) => {
                return res.json()
            })
            .then((resJson) => {
                // console.log('resJson', resJson)
                let data = resJson[0].get_projectcalcinputs_streams;
                // console.log('data', data)
                projectSvc.setStreamStabilityRes(data[0]);
            })
            .catch((err) => console.log('get_projectcalcinputs_streams error', err))
        }else{
            projectSvc.setStreamStabilityRes([]);
        }
    }, [appStateSvc.checkedStreams]);

    useEffect(() => {props.setHighlight_calcInput(false)}, []);

    function genRiverCheckboxSection(){
        if(showUpperStreamSection == false){
            return null;
        }
        if(appStateSvc.checkedRivers.length){
            return(
                <div className="checkbox-container-with-title">
                    <h3>Proposed River Corridor and Floodplain Projects:</h3>
                    <section class="checkbox-grid">
                        {makeRiverCheckboxes(criteriaSvc.riverProjectTypes)}
                    </section>
                </div>
            );
        }else{
            return null
        }
        
    }

    function genStreamCheckboxSection(){
        if(showUpperStreamSection == true || !appStateSvc.streamRowObj.length){
            return null;
        }

        return(
            <div className="checkbox-container-with-title">
                <h3>Stream Projects Included: </h3>
                <section class="checkbox-grid">
                    {makeStreamCheckboxes()}
                </section>
            </div>
        )
    }

    function makeRiverCheckboxes(arr){
        if(arr == null || !arr.length){
            return null
        }
        return arr.map((i, idx) => {
            // console.log('i', i)
            return(
                <span className="checkbox-row" key={idx}>
                    <input type="checkbox" id={i.project} defaultChecked={lookUpCheckedRiverStatus(i, idx)} onClick={() => toggleRiverCheckbox(i, idx)}/>
                    <label for={i.project}>{i.project}</label>
                </span>
            )
        })
    };

    function lookUpCheckedRiverStatus(val, idx){
        // console.log('val', val, idx);
        // console.log('appStateSvc.defaultAutoCheck_river', appStateSvc.defaultAutoCheck_river);
        // console.log('criteriaSvc.riverProjectTypes',  criteriaSvc.riverProjectTypes);
        if(appStateSvc.defaultAutoCheck_river == false && criteriaSvc.riverProjectTypes.length){
            // console.log('complete river project types', criteriaSvc.riverProjectTypes);
            for(let i = 0; i < criteriaSvc.riverProjectTypes.length; i++){
                if(val.project == criteriaSvc.riverProjectTypes[i].project){
                    // console.log('1', criteriaSvc.riverProjectTypes[i].checked);
                    return criteriaSvc.riverProjectTypes[i].checked;
                }
            }
        }
        for(let i = 0; i < appStateSvc.checkedRivers.length; i++){
            // console.log('checked river',appStateSvc.checkedRivers[i]);
            for(let k = 0; k < appStateSvc.checkedRivers[i].rc_projects.length; k++){
                // console.log('rc_projects', appStateSvc.checkedRivers[i].rc_projects[k])
                if(appStateSvc.checkedRivers[i].rc_projects[k].project == val.project){
                    // console.log('target check', val.project, target);
                    // console.log('2')
                    criteriaSvc.riverProjectTypes[idx].checked = true;
                    return true
                }
            }
        }
    };

    function toggleRiverCheckbox(val , idx){
        appStateSvc.setDefaultAutoCheck_river(false);
        return criteriaSvc.riverProjectTypes[idx].checked = !criteriaSvc.riverProjectTypes[idx].checked;
    }

    function makeStreamCheckboxes(){
        // console.log('streamData', appStateSvc.streamProjects, appStateSvc.checkedStreams);
        if(appStateSvc.streamProjects.length){
            return appStateSvc.streamProjects.map((i, idx) => {
                return <span className="checkbox-row" key={idx}>
                    <input type="checkbox" id={i.project} defaultChecked={lookUpCheckStreamStatus(i, idx)} onClick={() => toggleStreamCheckbox(idx)}/>
                    <label for={i.project}>{i.project}</label>
                </span>
            })
        }
    };

    function toggleStreamCheckbox(idx){
        appStateSvc.setDefaultAutoCheck_stream(false);
        return appStateSvc.streamProjects[idx].checked = !appStateSvc.streamProjects[idx].checked;
    }

    function lookUpCheckStreamStatus(val, idx){
        // console.log('firing', val, appStateSvc.checkedStreams);
        // console.log('appStateSvc.checkedStreams', appStateSvc.checkedStreams);
        if(!appStateSvc.checkedStreams.length){
            return;
        }
        if(appStateSvc.defaultAutoCheck_stream == false && appStateSvc.streamProjects.length){
            // console.log('complete stream projects', appStateSvc.streamProjects);
            for(let i = 0; i < appStateSvc.streamProjects.length; i++){
                if(val.project == appStateSvc.streamProjects[i].project){
                    return appStateSvc.streamProjects[i].checked;
                }
            }
        }
        for(let i = 0; i < appStateSvc.checkedStreams[0].st_projects.length; i++){
            if(appStateSvc.checkedStreams[0].st_projects[i].project == val.project){
                appStateSvc.streamProjects[idx].checked = true;
                // console.log('final check',appStateSvc.streamProjects[idx]);
                return true;
            }
        }
        // return false;
    }

    function genStreamStorageSection(){
        if(showUpperStreamSection == false){
            return null;
        }
        return(
            <>
            <div className="subtable-tabs-container">
                <div className="sub-table-tab" onClick={() => setShowStorage(false)} style={showStorage == false ? {backgroundColor: '#F5E6E0'} : null}>
                    <h3>Stream Stability (Floodplains)</h3>
                </div>
                <div className="sub-table-tab" onClick={() => setShowStorage(true)} style={showStorage == true ? {backgroundColor: '#F5E6E0'} : null}>
                    <h3>Storage</h3>
                </div>
            </div>
            <div>
                {showStorage == false ? <StreamStabilityRivers/> : <Storage/>}
            </div>
            </>
        )
    }

    // function findTheNull(obj, missing){
    //     console.log('obj values', obj);
    //     for(let i in obj){
    //         console.log('obj', i, obj[i])
    //         if(obj[i] == null){
    //             missing = i;
    //             console.log('missing', i)
    //             return missing
    //         }
    //     }
    // }

    function postAllProjCalcInputs(){
        //round everything
        let streamProjectsArr = [];
        //lets hash out some of these conditions
        //projectsSvc.riverStabilityRes = the data for the existing river + storage data
        //projectsSvc.streamStabilityRes = the data for the existing stream segment stuff
        let alertsToShow = [];
        for(let i = 0; i < appStateSvc.riverRowObj.length; i++){
            let showAlert = false;
            let newItem = {
                'ffi_id': Object.keys(appStateSvc.riverRowObj[i])[0]
            };
            //round this to first decimal
            let ffiTarget = projectSvc.riverStabilityRes.filter((val) => val.ffi_id == Object.keys(appStateSvc.riverRowObj[i])[0]);
            let riverCorrDiff = Number(ffiTarget[0].aoiac.toFixed(1)) - Number(ffiTarget[0].unconsac.toFixed(1));
            riverCorrDiff = riverCorrDiff.toFixed(1);
            if(riverCorrDiff < 0){
                riverCorrDiff = 0;
            }
            console.log('riverCorrDiff', riverCorrDiff);
            //check for the unconstrained river area
            let riverRow = Object.values(appStateSvc.riverRowObj[i])[0];
            let storageRow = Object.values(appStateSvc.storageRowObj[i])[0];

            //Existing Incision Ratio
            if(riverRow.ext_ir && (riverRow.ext_ir < 1 || riverRow.ext_ir > 3)){
                showAlert = true;
                console.log('Existing Incision Ratio', riverRow.ext_ir)
                newItem['Existing Incision Ratio'] = 'The existing incision ratio is out of range. Please adjust.';
            }

            //valid condition = x <= (Existing 'River Corridor Area (acres)' - Existing 'Unconstrained River Corridor Area (acres)')
            if(riverRow.unconsac && Number(riverRow.unconsac) > Number(riverCorrDiff)){
                //ok this might be cause of the rounding
                console.log('Proposed Unconstrained River Corridor (acres)', riverCorrDiff, riverRow.unconsac, typeof(Number(riverRow.unconsac)), typeof(Number(riverCorrDiff)));
                showAlert = true;
                newItem['Proposed Unconstrained River Corridor (acres)'] = 'The proposed unconstrained river corridor area results in an area larger than what exists in the reach. Please reduce. ';
            }
            //check for the protection area fields
            // console.log('riverRow', riverRow);
            // console.log('ffiTarget[0]', ffiTarget[0]);
            // console.log('adding ',(riverRow.robust? Number(riverRow.robust) : 0) + (riverRow.mod ? Number(riverRow.mod) : 0) + (riverRow.low ? Number(riverRow.low) : 0) + (riverRow.none ? Number(riverRow.none) : 0))
            //round the product here
            let riverProtectSum = (ffiTarget[0].aoiac ? Number(ffiTarget[0].aoiac.toFixed(1)) : 0) - ((ffiTarget[0].robust? Number(ffiTarget[0].robust.toFixed(1)) : 0) + (ffiTarget[0].mod ? Number(ffiTarget[0].mod.toFixed(1)) : 0) + (ffiTarget[0].low ? Number(ffiTarget[0].low.toFixed(1)) : 0) + (ffiTarget[0].none ? Number(ffiTarget[0].none.toFixed(1)) : 0));
            riverProtectSum = riverProtectSum.toFixed(1);
            if(riverProtectSum < 0){
                riverProtectSum = 0;
            }
            // console.log('rounded aoiac', Number(ffiTarget[0].aoiac.toFixed(1)));
            //round the product here
            let riverAreaSum = (riverRow.robust ? Number(riverRow.robust) : 0) + (riverRow.mod ? Number(riverRow.mod) : 0) + (riverRow.low ? Number(riverRow.low) : 0) + (riverRow.none ? Number(riverRow.none) : 0);
            // console.log('vals check', ffiTarget[0].aoiac, Number(riverRow.robust), Number(riverRow.mod), Number(riverRow.low), Number(riverRow.none));
            riverAreaSum = Number(riverAreaSum.toFixed(1));
            console.log('riverProtectSum', riverProtectSum); //now this represents area left in corridor to fill
            console.log('riverAreaSum', riverAreaSum);     // now this represents sum of user entered areas
            //idea: we should maybe break up the conditions into seperate true/fale variable
            let riverRowCheck = false;
            if (riverAreaSum > riverProtectSum){
                console.log('Proposed Protection Areas num check', ffiTarget[0].aoiac, riverRow.robust, riverRow.mod, riverRow.low, riverRow.none);
                riverRowCheck = true;
            }
            if(riverRowCheck == true){
                console.log('Proposed Protection Areas', riverRow, riverProtectSum, riverAreaSum)
                showAlert = true;
                newItem['Proposed Protection Areas'] = 'The sum of proposed protection areas results in an area larger than what exists in the reach. Please reduce one or more of your proposed protection areas.';
            }
            // if(riverRow.robust > riverProtectSum ||
            // riverRow.mod > riverProtectSum || 
            // riverRow.low > riverProtectSum ||
            // riverRow.none > riverProtectSum ||
            // //river area sum check
            // riverRow.robust > riverAreaSum || 
            // riverRow.mod > riverAreaSum || 
            // riverRow.low > riverAreaSum ||
            // riverRow.none > riverAreaSum){
            //     console.log('number check', riverRow, riverProtectSum, riverAreaSum)
            //     showAlert = true;
            //     newItem['Proposed Protection Areas'] = 'The sum of proposed protection areas results in an area larger than what exists in the reach. Please reduce one or more of your proposed protection areas.';
            // };
            //natural veg buffer check
            //round this result here
            let riverNatVegDiff = Number(ffiTarget[0].ac50.toFixed(1)) - Number(ffiTarget[0].veg_ac50.toFixed(1));
            console.log('riverNatVegDiff', Number(ffiTarget[0].ac50.toFixed(1)), Number(ffiTarget[0].veg_ac50.toFixed(1)))
            if(riverRow.veg_ac50 && riverRow.veg_ac50 > riverNatVegDiff){
                console.log('Proposed Naturally Vegetated Buffer Area', riverNatVegDiff, riverRow.veg_ac50 );
                showAlert = true;
                newItem['Proposed Naturally Vegetated Buffer Area'] = 'The proposed area of riparian buffer results in an area larger than what exists in the reach.';
            }

            //Proposed River Incision Ratio
            if(riverRow.prop_ir !== null && riverRow.prop_ir < 1 || riverRow.prop_ir > 3){
                console.log('Proposed River Incision Ratio', riverRow.prop_ir);
                showAlert = true;
                newItem['Proposed Incision Ratio'] = 'The proposed incision ratio is out of range. Please adjust.';
            }

            //proposed area of vertical change
            console.log('riverRow.areavertchange', riverRow.areavertchange, Number(ffiTarget[0].aoiac.toFixed(1)));
            if(riverRow.areavertchange > Number(ffiTarget[0].aoiac.toFixed(1))){
                showAlert = true;
                console.log('proposed area of vertical change', Number(ffiTarget[0].ac50.toFixed(1)), riverRow.areavertchange)
                newItem['Proposed Area of Vertical Change'] = 'The proposed area of vertical change results in an area larger than what exists in the reach. Please reduce. ';
            }

            // //STORAGE TAB RANGE CHECK
            // //project area
            // if(storageRow.prop_proj_area && storageRow.prop_proj_area > riverCorrDiff){
            //     // console.log('num check', storageRow.prop_proj_area, riverCorrDiff);
            //     showAlert = true;
            //     newItem['Storage Project Area'] = 'The proposed project area results in an area larger than what exists in the reach. Please reduce.';
            // }
            if(showAlert == true){
                alertsToShow.push(newItem);
            }
        }
        //STREAM SECTION
        // let streamRow = Object.values(appStateSvc.streamRowObj[0])[0];
        //round these as well
        if(Object.values(projectSvc.streamStabilityRes).length && appStateSvc.streamRowObj.length){
            // console.log('stream check values', projectSvc.streamStabilityRes, appStateSvc.streamRowOb)
            let showStreamAlert = false;
            let newItemStream = {
                'ffi_id': appStateSvc.streamRowObj[0].ffi_id
            };
            //Proposed 'Length of Road Drainage in HUC12 / Road Disconnection in Project (miles)'
            if(Number(appStateSvc.streamRowObj[0].rd_miles) > Number(projectSvc.streamStabilityRes.rd_miles.toFixed(1))){
                showStreamAlert = true;
                console.log('Length of Road Drainage in HUC12 / Road Disconnection in Project (miles)',  projectSvc.streamStabilityRes.rd_miles.toFixed(1), appStateSvc.streamRowObj[0].rd_miles);
                newItemStream['Proposed Length of Road Drainage in HUC12 / Road Disconnection in Project (miles)'] = 'The proposed length of reconnected roads results in a length larger than what exists. Please reduce.';
            }
            //Proposed 'Area of Agriculture in HUC12 / Disconnection in Project (acres)')
            if(appStateSvc.streamRowObj[0].ag_acres && Number(appStateSvc.streamRowObj[0].ag_acres) > Number(projectSvc.streamStabilityRes.ag_acres.toFixed(1))){
                showStreamAlert = true;
                console.log('Area of Agriculture in HUC12 / Disconnection in Project (acres)', projectSvc.streamStabilityRes.ag_acres.toFixed(1), appStateSvc.streamRowObj[0].ag_acres);
                newItemStream['Proposed Area of Agriculture in HUC12 / Disconnection in Project (acres)'] = 'The proposed area of agriculture reconnects is larger than what exists. Please reduce.';
            }
            //Proposed 'Incision Ratio'
            if(appStateSvc.streamRowObj[0].ir && appStateSvc.streamRowObj[0].ir < 1 || appStateSvc.streamRowObj[0].ir > 3){
                console.log('Proposed Incision Ratio',appStateSvc.streamRowObj[0].ir)
                showStreamAlert = true;
                newItemStream['Proposed Incision Ratio'] = 'The proposed incision ratio is out of range. Please adjust.';
            }
            if(showStreamAlert == true){
                alertsToShow.push(newItemStream);
            }
        }
        if(alertsToShow.length){
            appStateSvc.setAlertText_Calc(alertsToShow);
            return appStateSvc.setMakeAlert_Calc(true);
        }
        for(let i = 0; i < appStateSvc.streamProjects.length; i++){
            if(appStateSvc.streamProjects[i].checked == true){
                // console.log('row check', appStateSvc.streamProjects[i])
                streamProjectsArr.push(appStateSvc.streamProjects[i].projectid);
            }
        }
        let river_payload = [];
        for(let i = 0; i < appStateSvc.riverRowObj.length; i++){
            //update ir to have proposed and existing 
            river_payload.push({
                'ffi_id': Object.keys(appStateSvc.riverRowObj[i])[0],
                'robust': Object.values(appStateSvc.riverRowObj[i])[0].robust,
                'mod': Object.values(appStateSvc.riverRowObj[i])[0].mod,
                'low': Object.values(appStateSvc.riverRowObj[i])[0].low,
                'none': Object.values(appStateSvc.riverRowObj[i])[0].none,
                'veg_ac50': Object.values(appStateSvc.riverRowObj[i])[0].veg_ac50,
                'prop_ir': Object.values(appStateSvc.riverRowObj[i])[0].prop_ir,
                'unconsac': Object.values(appStateSvc.riverRowObj[i])[0].unconsac,
                'areavertchange': Object.values(appStateSvc.riverRowObj[i])[0].areavertchange,
                'ext_ir': Object.values(appStateSvc.riverRowObj[i])[0].ext_ir
            })
        };
        //river db function goes here
        //build out Db function when its ready

        let stream_payload = [];
        if(appStateSvc.streamRowObj.length){
            stream_payload.push({
                'ag_acres': appStateSvc.streamRowObj[0].ag_acres,
                'areavertchange': appStateSvc.streamRowObj[0].areavertchange,
                'ffi_id': appStateSvc.streamRowObj[0].ffi_id,
                'ir': appStateSvc.streamRowObj[0].ir,
                'rd_miles': appStateSvc.streamRowObj[0].rd_miles,
                'prop_str_projects': streamProjectsArr
            })
        }
        let storage_payload = [];
        for(let i = 0; i < appStateSvc.storageRowObj.length; i++){
            // if(Object.values(appStateSvc.storageRowObj[i])[0].stor_conn == null ||
            //     Object.values(appStateSvc.storageRowObj[i])[0].prop_proj_area == null
            // ){
            //     // appStateSvc.setMakeAlert(true);
            //     // appStateSvc.setAlertText('Missing required storage inputs, please check that you have entered values for all input fields');
            //     // return;
            // }
            storage_payload.push({
                'ffi_id': Object.keys(appStateSvc.storageRowObj[i])[0],
                'stor_conn': Object.values(appStateSvc.storageRowObj[i])[0].stor_conn,
                'exist_stor_conn': Object.values(appStateSvc.storageRowObj[i])[0].exist_stor_conn,
                'prop_proj_area': Object.values(appStateSvc.storageRowObj[i])[0].prop_proj_area
            })
        }

        //streams fetch
        if(!storage_payload.length && !river_payload.length && !stream_payload.length){
            appStateSvc.setMakeAlert(true);
            appStateSvc.setAlertText(`Please Enter Data to Calculate`);
            return;
        }
        projectSvc.postProjCalcInputs_Streams(stream_payload).then((res) => {
            return res.json();
        })
        .then((resJson) => {
            // console.log('postProjCalcInputs_Streams resJson', resJson);
            let data = resJson[0].calcproject_streams;
            if(data == null){
                return;
            }
            // console.log('postProjCalcInputs_Streams resJson', Object.values(resJson[0].calcproject_streams));
            if(Object.values(data) !== null){
                appStateSvc.setWq_Streams(Object.values(data));
            }
        })
        .catch((err) => console.log('postProjCalcInputs_Streams err', err));

        //rivers fetch
        projectSvc.postProjCalcInputs_Rivers(river_payload).then((res) => {
            return res.json()
        })
        .then(resJson => {
            // console.log('resJson', resJson);
            // console.log('resJson', Object.values(resJson[0])[0]);
            let data = resJson[0].calcproject_rivers;
            if(data == null){
                return;
            }
            if(Object.values(resJson[0])[0] !== null){
                appStateSvc.setWq_Rivers(Object.values(resJson[0])[0])
            }
            //target aquired: resJson[0].calcproject_rivers[0]
        })
        .catch(e => console.log('postProjCalcInputs_Rivers db function err', e));

        projectSvc.postProjCalcInputs_Storage(storage_payload).then((res) => {
            return res.json();
        })
        .then((resJson) => {
            // console.log('resJson', resJson);
            // console.log('postProjCalcInputs_Storage resJson', Object.values(resJson[0].calcproject_storage))
            let data = resJson[0].calcproject_storage;
            if(data == null){
                return;
            }
            if(Object.values(resJson[0].calcproject_storage) !== null){
                appStateSvc.setWq_Storage(Object.values(data));
            }
        })
        .catch(e => console.log('postProjCalcInputs_Storage err', e));

        appStateSvc.setMakeAlert(true);
        appStateSvc.setAlertText(`Calculations Successful`);
        props.setHighlight_wqBenefit(true);
        props.setHighlight_floodBenefit(true);
        return 
    }

    function getStreamTableSection(){
        if(showUpperStreamSection == true){
            return null;
        }
        return <StreamStabilityStreams/>
    }

    return(
        <div>
            <div className="phos-credit-calc-header">
                <h3>Enter Proposed Information for Potential Stream Stability Projects Below:</h3>
                    <button className="calc-button" onClick={() => postAllProjCalcInputs()}>Calculate</button>
                </div>
            <div className="phos-credit-calc-row" onClick={() => toggleCheckboxSection()}>
                <span className={showUpperStreamSection == true ? 'arrow-open': 'arrow'}><BsFillTriangleFill/></span>
                <h3>Floodplain Connectivity (Lateral-Vertical) and Storage Crediting</h3>
            </div>
            <div>
                {genRiverCheckboxSection()}
                {genStreamStorageSection()}
            </div>
            <div className="phos-credit-calc-row" onClick={() => toggleCheckboxSection()}>
                <span className={showUpperStreamSection == false ? 'arrow-open': 'arrow'}><BsFillTriangleFill/></span>
                <h3>Stream Connectivity (Longitudinal-Temporal) Crediting</h3>
            </div>
            <div>
                {genStreamCheckboxSection()}
                {getStreamTableSection()}
            </div>
        </div>
    )
}