const config = {
    API_HOST: process.env.REACT_APP_APIHOST,
    token: process.env.REACT_APP_TOKEN,
    VERSION_INFO: process.env.REACT_APP_VERSION,
    COMMIT_INFO: process.env.REACT_APP_COMMIT,
    content_type: 'application/json',
    scales: {
        "BASINS": {
            layer: "Watershed Planning Basins",
            idcol: "BOUNDARY"
        },
        "HUC10": {
            layer: "Watershed Boundaries - HUC10",
            idcol: "HUC10"
        },
        "HUC12": {
            layer: "HUC 12 (Subwatershed)",
            idcol: "HUC12"
        },
        "TOWNS": {
            layer: "Town Boundaries",
            idcol: "TOWNNAME"
        },
        "COUNTIES": {
            layer: "County Boundaries",
            idcol: "CNTYNAME"
        }
    },
    streamName: "Stream Connectivity",
    riverName: "River Corridor Connectivity"
}

export default config;
