import { keys } from '@material-ui/core/styles/createBreakpoints';
import React, {useState, useEffect} from 'react';
import { useAppState } from '../../services/App_State_Service';
import { useRegions } from '../../services/Region_Service';
import {BsFillTriangleFill} from 'react-icons/bs';
import './LocalPopupItem.scss';

export default function LocalPopupItem(props){
    const appStateSvc = useAppState();
    const regionSvc = useRegions();
    const [tabToShow, setTabToShow] = useState(null);
    // const [ffiHeader, setFFIHeader] = useState(null);
    // const [sgatHeader, setSGATHeader] = useState(null);

    const refactorHTML = () => {
        // console.log('titleLayer', props.layerTitle);
        // console.log('first props check', props.popupAttributes, props.htmlStr)
        // console.log('prop attributes', props.popupAttributes);
        let htmlTable = [];
        let parser = new DOMParser();
        let parsedHTML = parser.parseFromString(props.htmlStr, "text/html");
        let sectionBreakup = parsedHTML.getElementsByTagName("tr");
        for (let i = 0; i < sectionBreakup.length; i++) {
            // console.log('section breakup', sectionBreakup[i])
            // getting the h2 and storing it
            let localHeader = sectionBreakup[i].getElementsByTagName("th")[0].innerHTML;
            // break up divs
            let localContent = sectionBreakup[i].getElementsByTagName("td");
            let localObj = {};
            localObj.header = localHeader;
            for (let j = 0; j < localContent.length; j++) {
              // targets divs with child nodes for easy assembly
              if(localContent[j].getElementsByTagName('div').innerHTML == '{expression/expr0}'){
                break;
              }
              localObj[localContent[j].getElementsByTagName("div")[0].innerHTML] = localContent[j].getElementsByTagName("div")[1].innerHTML;
            }
            htmlTable.push(localObj);
        };
        return(
            <>
                {assembleHeaders(htmlTable)}
                {assembleTabs(htmlTable)}
            </>
        )
    }

    const roundDeci = (num, field) => {
        if(field === "Vulnerability Details" || num == 0){
            return Number.parseFloat(num).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
       return Number.parseFloat(num).toFixed(1);
    }

    const tabClick = (num) => {
        console.log('num', num);
        if(num == tabToShow){
            setTabToShow(null);
        }else{
            setTabToShow(num)
        }
    }

    const assembleTabs = (val) => {
        return val.map((i, idx) => {
            if(i.header == 'Priority Projects'){
            // if(i.header == 'Project Selection'){
                // console.log('props check', i, val)
                return(
                    <>
                        <div className={tabToShow === idx ? 'selected tab-text' : 'tab-text'} onClick={() => tabClick(idx)}>
                            <span><BsFillTriangleFill className={tabToShow === idx ? 'open-arrow' : 'closed-arrow'}/></span><h5 id={`header-tab-${idx}`}>Priority Projects</h5>
                        </div>
                        <div className={tabToShow === idx ? "category-section" : 'hide'}>
                            {/* <ul> */}
                                {assembleProjectSection(i)}                            
                            {/* </ul> */}
                        </div>
                    </>
                )
            }
            return(
                <>
                    <div className={tabToShow === idx ? 'selected tab-text' : 'tab-text'} onClick={() => tabClick(idx)}>
                        <span><BsFillTriangleFill className={tabToShow == idx ? 'open-arrow' : 'closed-arrow'} /></span><h5 id={`header-tab-${idx}`}>{i.header}</h5>
                    </div>
                    <div className={tabToShow === idx ? "category-section" : 'hide'}>
                        {assembleTableRows(i)}
                    </div>
                </>
            )
        })
        
    }

    const assembleHeaders = (val) => {
        let ffiHeader;
        let sgatHeader;
        val.map((i, idx) => {
            if(i['FFI ID']){
                let value = i['FFI ID'];
                value = value.replace('{', '');
                value = value.replace('}', '')
                ffiHeader = props.popupAttributes[value.toLowerCase()];
            }
            if(i['SGAT ID']){
                // console.log('i', i)
                let value = i['SGAT ID'];
                value = value.replace('{', '');
                value = value.replace('}', '')
                // console.log('value', value, props.popupAttributes[value.toLowerCase()]);
                if(!props.popupAttributes[value.toLowerCase()] || props.popupAttributes[value.toLowerCase()] == ' '){
                    sgatHeader = '-'
                }else{
                    sgatHeader = props.popupAttributes[value.toLowerCase()];
                }                
                // console.log('sgatHeader', sgatHeader);
            }
        });
        return(
            <section className="title-section">
                <h3 className="layer-title">{props.layerTitle}</h3>
                <h3 className="id_headers">FFI ID: <span className="id_headers_values">{ffiHeader}</span></h3>
                <h3 className="id_headers">SGAT ID: <span className="id_headers_values">{sgatHeader}</span></h3>
            </section>
        )
    }

    const assembleProjectSection = (val) => {
        return props.projects.map((i,idx) => {
            // console.log('assemble projects', i)
            if(i.project == null){
                return <div className="project-row"><p>No projects identified</p></div>
            }
            if(idx % 2 == 0){
                return <div className="project-row"><p>{i.project}</p></div>
            }else{
                return <div className="project-row shade"><p>{i.project}</p></div>
            }
        })
    }

    const assembleTableRows = (val) => {
        let newObjArr = []
        for(let item in val){
            let key = val[item].toLowerCase();
            key = key.replace('{', '');
            key = key.replace('}', '');
            let newObj = {'key': item, 'value': props.popupAttributes[key]}
            if(newObj.key !== 'header' &&
            newObj.key !== 'FFI ID' &&
            newObj.key !== 'SGAT ID'){
                if(typeof newObj.value == 'number'){
                    newObj.value = roundDeci(newObj.value, val.header);
                }
                newObjArr.push(newObj);
            }
        }
        return newObjArr.map((j, idx) => {
            if(idx % 2 == 0){
                return <>
                    <div key={idx} className="category-rows">
                        <p className="label-text-key">{j.key}</p>
                        <p className="label-text-value">{j.value ? j.value : '-'}</p>
                    </div>
                </>
            }else{
                return <>
                <div key={idx} className="category-rows shade">
                    <p className="label-text-key">{j.key}</p>
                    <p className="label-text-value">{j.value ? j.value : '-'}</p>
                </div>
            </>
            }

        })
    }

    return(
        <div className="template-container">
            {refactorHTML()}
        </div>
    )
}