import React, {useEffect, useState} from 'react';
import { useAppState } from '../../services/App_State_Service';
import './StreamStabilityCredit.scss';


export default function StreamStabilityCreditSummary(){
    const appStateSvc = useAppState();

    // const roundDeci = (num) => {
    //     return Number.parseFloat(num).toFixed(2);
    // };

    function roundNumbers(num){
        return Number.parseFloat(num).toFixed(1);
    }

    function assembleRiverRow(){
        let riverRow = {
            y_one: 0,
            y_two: 0,
            y_fifteen: 0
        };

        let oneTotal = 0;
        let twoTotal = 0;
        let fifteenTotal = 0;
        for(let i = 0; i < appStateSvc.wq_Rivers.length; i++){
            if(appStateSvc.wq_Rivers[i].yr1_sumriversp_kgyr && appStateSvc.wq_Rivers[i].yr1_sumriversp_kgyr !== null){
                // console.log('appStateSvc.wq_Rivers[i].yr1_sumriversp_kgyr',appStateSvc.wq_Rivers[i].yr1_sumriversp_kgyr);
                oneTotal = oneTotal + appStateSvc.wq_Rivers[i].yr1_sumriversp_kgyr;
                twoTotal = twoTotal + appStateSvc.wq_Rivers[i].yr1_sumriversp_kgyr;
            }
            // if(appStateSvc.wq_Rivers[i].yr2_sumriversp_kgyr && appStateSvc.wq_Rivers[i].yr2_sumriversp_kgyr !== null){
            //     // console.log('appStateSvc.wq_Rivers[i].yr2_sumriversp_kgyr', appStateSvc.wq_Rivers[i].yr2_sumriversp_kgyr);
            //     twoTotal = twoTotal + appStateSvc.wq_Rivers[i].yr2_sumriversp_kgyr;
            // }
            if(appStateSvc.wq_Rivers[i].yr15_sumriversp_kgyr && appStateSvc.wq_Rivers[i].yr15_sumriversp_kgyr !== null){
                // console.log('appStateSvc.wq_Rivers[i].yr15_sumriversp_kgyr', appStateSvc.wq_Rivers[i].yr15_sumriversp_kgyr);
                fifteenTotal = fifteenTotal + appStateSvc.wq_Rivers[i].yr15_sumriversp_kgyr;
            }
        };
        // console.log('twoTotal', twoTotal);
        riverRow.y_one = oneTotal;
        riverRow.y_two = twoTotal;
        riverRow.y_fifteen = fifteenTotal;
        
        appStateSvc.setSummaryRows_Rivers(riverRow);
    };

    function assembleStreamRow(){
        let streamRow = {
            y_one: 0,
            y_two: 0,
            y_fifteen: 0
        };

        let oneTotal = 0;
        let twoTotal = 0;
        let fifteenTotal = 0;
        // console.log('appStateSvc.wq_Streams', appStateSvc.wq_Streams);
        for(let i = 0; i < appStateSvc.wq_Streams.length; i++){
            if(appStateSvc.wq_Streams[i].yr1_sumstreamp_kgyr && appStateSvc.wq_Streams[i].yr1_sumstreamp_kgyr !== null){
                oneTotal = oneTotal + appStateSvc.wq_Streams[i].yr1_sumstreamp_kgyr;
                twoTotal = twoTotal + appStateSvc.wq_Streams[i].yr1_sumstreamp_kgyr;
            }
            if(appStateSvc.wq_Streams[i].yr15_sumstreamp_kgyr && appStateSvc.wq_Streams[i].yr15_sumstreamp_kgyr !== null){
                fifteenTotal = fifteenTotal + appStateSvc.wq_Streams[i].yr15_sumstreamp_kgyr;
            }
        };
        streamRow.y_one = oneTotal;
        streamRow.y_two = twoTotal;
        streamRow.y_fifteen = fifteenTotal;

        appStateSvc.setSummaryRows_Streams(streamRow);
    };

    function assembleStorageRow(){
        let storageRow = {
            y_one: 0,
            y_two: 0,
            y_fifteen: 0
        };

        let oneTotal = 0;
        let twoTotal = 0;
        let fifteenTotal = 0;
        for(let i = 0; i < appStateSvc.wq_Storage.length; i++){
            if(appStateSvc.wq_Storage[i].yr1_sumstorp_kgyr && appStateSvc.wq_Storage[i].yr1_sumstorp_kgyr !== null){
                oneTotal = oneTotal + appStateSvc.wq_Storage[i].yr1_sumstorp_kgyr;
            }
            if(appStateSvc.wq_Storage[i].yr2_sumstorp_kgyr && appStateSvc.wq_Storage[i].yr2_sumstorp_kgyr !== null){
                twoTotal = twoTotal + appStateSvc.wq_Storage[i].yr2_sumstorp_kgyr;
            }
            if(appStateSvc.wq_Storage[i].yr15_storp_kgyr && appStateSvc.wq_Storage[i].yr15_storp_kgyr !== null){
                fifteenTotal = fifteenTotal + appStateSvc.wq_Storage[i].yr15_storp_kgyr;
            }
        };
        storageRow.y_one = oneTotal;
        storageRow.y_two = twoTotal;
        storageRow.y_fifteen = fifteenTotal;

        appStateSvc.setSummaryRows_Storage(storageRow);
    };

    function yearOneTotal(){
        let total = appStateSvc.summaryRows_Rivers.y_one + appStateSvc.summaryRows_Streams.y_one + appStateSvc.summaryRows_Storage.y_one;
        return roundNumbers(total);
    };

    function yearTwoTotal(){
        let total = appStateSvc.summaryRows_Rivers.y_two + appStateSvc.summaryRows_Streams.y_two + appStateSvc.summaryRows_Storage.y_two;
        return roundNumbers(total);
    };

    function yearFifteenTotal(){
        let total = appStateSvc.summaryRows_Rivers.y_fifteen + appStateSvc.summaryRows_Streams.y_fifteen + appStateSvc.summaryRows_Storage.y_fifteen;
        return roundNumbers(total);
    };

    useEffect(() => {
        assembleStorageRow();
        assembleStreamRow();
        assembleRiverRow();
    }, []);

    return(
        <>
        <h2 className="wq-header">Stream Stability and Storage Credit Summary</h2>
        <div className="summary-container">
            
            <table className="inner-summary-container">
                <thead>
                    <tr>
                        <th>{null}</th>
                        <th>Year 1 Credit (kg)</th>
                        <th>Year 2+ Credit (kg/yr)</th>
                        <th>Estimated 15 Yr Lifespan Credit (kg)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="4" className="river-summary-header">Floodplain Connectivity (Lateral - Vertical)</td>
                    </tr>
                    <tr className="inner-summary-gray-row">
                        <td>Stream Stability</td>
                        <td>{roundNumbers(appStateSvc.summaryRows_Rivers.y_one)}</td>
                        <td>{roundNumbers(appStateSvc.summaryRows_Rivers.y_two)}</td>
                        <td>{roundNumbers(appStateSvc.summaryRows_Rivers.y_fifteen)}</td>
                    </tr>
                    <tr className="inner-summary-gray-row">
                        <td>Storage</td>
                        <td>{roundNumbers(appStateSvc.summaryRows_Storage.y_one)}</td>
                        <td>{roundNumbers(appStateSvc.summaryRows_Storage.y_two)}</td>
                        <td>{roundNumbers(appStateSvc.summaryRows_Storage.y_fifteen)}</td>
                    </tr>
                    {/* {assembleStorageRow()} */}
                    <tr>
                        <td colSpan="4" className="stream-summary-header">Stream Connectivity (Longitudinal - Temporal)</td>
                    </tr>
                    <tr className="inner-summary-gray-row">
                        <td>Stream Stability</td>
                        <td>{roundNumbers(appStateSvc.summaryRows_Streams.y_one)}</td>
                        <td>{roundNumbers(appStateSvc.summaryRows_Streams.y_two)}</td>
                        <td>{roundNumbers(appStateSvc.summaryRows_Streams.y_fifteen)}</td>
                    </tr>
                    <tr className="inner-summary-gray-row">
                        <td>TOTAL</td>
                        <td>{yearOneTotal()}</td>
                        <td>{yearTwoTotal()}</td>
                        <td>{yearFifteenTotal()}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        </>
    )
}