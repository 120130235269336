import React from 'react';
import './Start.scss';
import {useHistory} from 'react-router-dom';
import {Icon} from '../SVGs/Icon';
import { useCriteria } from '../../services/Criteria_Service';
import { useRegions } from '../../services/Region_Service';

export default function Start(){
    const history = useHistory();
    const criteriaSvc = useCriteria();
    const regionSvc = useRegions();

    function routeToFilter(){
        // reset checkboxes in filter page
        criteriaSvc.setCheckedFunctions([]);
        criteriaSvc.setCheckedApplicability([]);
        criteriaSvc.setCheckedProjects([]);
        criteriaSvc.setCheckedPriorities([]);
        regionSvc.setToggleRegions(false);
        criteriaSvc.setToggleCriteria(false);
        criteriaSvc.setSelectedScale('');
        regionSvc.setSelectedRegionName('');
        regionSvc.setSelectedRegion({scale:null,region:null})
        history.push('/project-planning/filter');
    }

    return(
        <div className='project-manager-start'>
            <div className='project-manager-start-content'>
                <section className="info-section">
                    <div className="icon_stage_one">
                        <Icon/>
                    </div>
                    <h3>Project Planning</h3>
                <div id="modal-container-text">
                    <p>
                        The following pages will enable you to identify and prioritize infrastructure projects in a desired location or that meet desired criteria.<br/>
                    </p><br/>
                    <p>
                        Begin by selecting projects for a specific location on a map, or start by selecting project criteria.
                    </p>
                </div>
                </section>
                
                <div className="button-container">
                    <button id="select-project-criteria" onClick={() => routeToFilter()}>Select Project Criteria</button><span id="or">or</span><button id="select-specific-location" onClick={() => history.push('/explore-data')}>Select Project for a Specific Location</button>
                </div>
            </div>
        </div>
    )
}