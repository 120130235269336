import React, {useState, useEffect} from 'react';
import { useProjects } from '../../services/Projects_Service';
import { useAppState } from '../../services/App_State_Service';
import './StreamStabilityStreams.scss';

export default function StreamStabilityStreams(){
    const projectsSvc = useProjects();
    const appStateSvc = useAppState();

    function roundNumbers(num){
        return Number.parseFloat(num).toFixed(1)
    }
    //TODO: Update this function to not be a dumpster fire
    function updateFunction(str, e){
    //     // console.log('firing');
    //     let newObj = appStateSvc.streamRowObj[0];
    //     // console.log('values', Object.values(newObj)[0])
    //     // console.log('newObj', newObj, newObj[projectsSvc.streamStabilityRes.ffi_id])
    //     if(!e.target.value){
    //         newObj[str] = null;
    //     }else{
    //         newObj[str] = e.target.value;
    //     }
    //     // console.log('newObj first', newObj);
    //     // let newTest = newObj;
    //     // console.log('obj test',Object.is(newObj, appStateSvc.streamRowObj));
    //     console.log('obj console compare', newObj, appStateSvc.streamRowObj);
    //     let newArr = appStateSvc.streamRowObj[0].splice(0, 1, newObj);
    //     console.log('new arr', newArr);
    //    return  appStateSvc.setStreamRowObj(newArr);
        // console.log('old obj', appStateSvc.streamRowObj);
        let newObj = appStateSvc.streamRowObj[0];
        newObj[str] = e.target.value;

        // console.log('new object', newObj);
        return appStateSvc.setStreamRowObj([newObj]);
    };

    function lookUpValue(str){
        // if(appStateSvc.streamRowObj.length){
        //     // console.log('appStateSvc.streamRowOb', appStateSvc.streamRowObj);
        //     if(appStateSvc.streamRowObj[str]){
        //         // console.log('Object.values(appStateSvc.streamRowObj)[0][str]', Object.values(appStateSvc.streamRowObj)[0][str]);
        //         let target = appStateSvc.streamRowObj[str];
        //         return target;
        //     }else{
        //         return ''
        //     }
        // }else{
        //     return null
        // }
        if(appStateSvc.streamRowObj.length){
            if(appStateSvc.streamRowObj[0][str]){
                return appStateSvc.streamRowObj[0][str]
            }else{
                return ''
            }
        }else{
            return null;
        }
    };

    function generateStreamTableBody(){
        // console.log('firing')
        // console.log('projectsSvc.streamStabilityRes', projectsSvc.streamStabilityRes);
        if(projectsSvc.streamStabilityRes == null || !projectsSvc.streamStabilityRes){
            // console.log('condition works')
            return null;
        }
        // console.log('projectsSvc.streamStabilityRes',projectsSvc.streamStabilityRes);
        // return projectsSvc.streamStabilityRes.map((i) => {
            return(
                <>
                <tr className="existing-data-row">
                    <td className="stream-header">
                        <div class="stream-id-container">
                            <p>{projectsSvc.streamStabilityRes.ffi_id}</p>
                            <p>Existing</p>
                        </div>
                    </td>
                    <td>{projectsSvc.streamStabilityRes.rd_miles ? roundNumbers(projectsSvc.streamStabilityRes.rd_miles) : 0}</td>
                    <td>{projectsSvc.streamStabilityRes.ag_acres ? roundNumbers(projectsSvc.streamStabilityRes.ag_acres) : 0}</td>
                    <td>{projectsSvc.streamStabilityRes.ir ? roundNumbers(projectsSvc.streamStabilityRes.ir) : 0}</td>
                    <td>-</td>
                    {/* <td>{i.long_deduc ? roundNumbers(i.long_deduc) : '-'}</td>
                    <td>{i.temp_deduc ? roundNumbers(i.temp_deduc) : '-'}</td> */}
                </tr>
                <tr className="proposed-data-row">
                    <td>
                        <div class="stream-id-container">
                            <p></p>
                            <p>Proposed Project Values</p>
                        </div>
                    </td>
                    <td>
                        <input type="number" value={lookUpValue('rd_miles')} onChange={(k) => updateFunction('rd_miles', k)}/>
                    </td>
                    <td>
                        <input type="number" value={lookUpValue('ag_acres')} onChange={(k) => updateFunction('ag_acres', k)}/>
                    </td>
                    <td>
                        <input type="number" value={lookUpValue('ir')} onChange={(k) => updateFunction('ir', k)}/>
                    </td>
                    <td>
                        <input type="number" value={lookUpValue('areavertchange')} onChange={(k) => updateFunction('areavertchange', k)}/>
                    </td>
                </tr>
                </>
            )
        // })
    }

    useEffect(() => {
        console.log('useEffect');
        
    }, [appStateSvc.streamRowObj]);

    return(
        <>
            <table className="stream-stability-table">
                <thead>
                    <tr>
                        <th className="stream-header">Stream ID</th>
                        <th>Length of Road Drainage in HUC12 / Road Disconnection in Project (miles)</th>
                        <th>Area of Agriculture in HUC12 / Disconnection in Project (acres)</th>
                        <th>Incision Ratio</th>
                        <th>Area with Vertical Change (acres)</th>
                        {/* <th>Longitudinal Deductions (Structures and IR)</th>
                        <th>Temporal Deductions (Structures and IR)</th> */}
                    </tr>
                </thead>
                <tbody style={appStateSvc.streamRowObj.length ? null : {display: 'none'}}>
                    {/* <tr> */}
                        {generateStreamTableBody()}
                    {/* </tr> */}
                </tbody>
            </table>
        </>
    )
}