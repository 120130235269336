import React, {useEffect, useState} from 'react';
// import OutcomeTable from '../OutcomeTable/OutcomeTable';
import './Manager.scss';
import {Icon} from '../SVGs/Icon';
import Criteria from '../Criteria/Criteria';
import { useCriteria } from '../../services/Criteria_Service';
import { useHistory } from 'react-router-dom';
import { useRegions } from '../../services/Region_Service';
import { useAppState } from '../../services/App_State_Service';
import { useProjects } from '../../services/Projects_Service';


export default function Manager(props) {
	const [renderFilterRoute, setRenderFilterRoute] = useState(false);
	const [hideManager, setHideManager] = useState(false);
	const history = useHistory();
	const criteriaSvc = useCriteria();
	const regionSvc = useRegions();
	const appStateSvc = useAppState();
	const projectsSvc = useProjects()

	const clickExploreOutcomes = function(){
		// props.setCanOpenOutcomes(true);
		appStateSvc.setTableSelected("projects");
		appStateSvc.setShowTable(true);
		projectsSvc.fetchProjectPlanningList(regionSvc.selectedRegion, criteriaSvc.getCheckedCriteria());
	}

	const cookie_check = () => {
		console.log('it fired');
		let cookieScale;
		let cookieRegion;
		let cookieRegionName
		console.log(document.cookie.split(';'))
		let cookieArray = document.cookie.split(';')
		console.log('cookieArray', cookieArray)
		for(let i = 0; i < cookieArray.length; i++){
			let tempCookie = cookieArray[i].split("=");
			if(tempCookie[0] === ' scale_cookie' || tempCookie[0] === 'scale_cookie'){
				console.log('1', tempCookie[1]);
				cookieScale = tempCookie[1];
			}
			if (tempCookie[0] === ' region_cookie' || tempCookie[0] === 'region_cookie'){
				console.log('2', tempCookie[1]);
				cookieRegion = tempCookie[1];
			}
			if(tempCookie[0] === ' region_name_cookie' || tempCookie[0] == 'region_name_cookie'){
				console.log('3', tempCookie[1]);
				cookieRegionName = tempCookie[1];
			}
		}
		if(cookieScale && cookieRegion){
			regionSvc.setSelectedRegion({scale: cookieScale, region: cookieRegion});
			criteriaSvc.getFilterCriteria(cookieScale.toLowerCase(), cookieRegion);
			criteriaSvc.setSelectedScale(cookieScale);
            regionSvc.setSelectedRegionName(cookieRegionName);
		} else {
			setRenderFilterRoute(true)
		}
	}

	// const renderScaleRegionSubtitle = () => {
	// 	let returnSelected;
	// 	let returnRegion;
	// 	if(!props.selectedRegion && !props.selectedCriteria){
	// 		returnSelected = document.cookie()
	// 	}
	// }

	useEffect(() => {
		// console.log('what do we got here', criteriaSvc.criteriaList);
		// console.log('test',Object.keys(criteriaSvc.criteriaList), Object.keys(criteriaSvc.criteriaList).length)
		if(Object.keys(criteriaSvc.criteriaList) === [] || Object.keys(criteriaSvc.criteriaList).length > 0) {
			// console.log('it fired, woot!');
			// removed cookie check cause it was shifting to Africa
			// cookie_check();
		}
	}, [])

	return (
		<div className={hideManager? "project-manager project-manager-hidden" : "project-manager project-manager-shown"}>
			<div className="project-manager-content">
				<div id="main-container">
					<div className="project-planning-modal">
						<div className="icon-title">
							<button 
								onClick={() => {setHideManager(!hideManager)}} 
								style={
									hideManager ? 
									{transform: "translateX(394px) rotate(-180deg)", transition: "200ms ease", margin: 0} : 
									{transform: "translateX(0) rotate(0deg)", transition: "200ms ease", margin: 0} 
								}
							>
								{"<"}
							</button>
							<div className="icon_stage_three">
								<Icon />
							</div>
						</div>
						<div className="instructions">
							<h1 id="project-planning">Project Planning</h1>
							<p>Projects have been added to your Potential Projects table based on your chosen criteria. You may continue to add projects by clicking specific reaches. Select ‘Add’ from the reach/stream pop-up window.</p><br/>
							<p>These projects have been identified to be beneficial to restoring and protecting river-floodplain connectivity. The screening and listing of potential projects does not imply that the project is ready for design and implementation. Potential projects listed here have not been reviewed with exact boundaries, vetted with landowners, or feasibility explored in detail.</p>
						</div>
						<div className="form">
							{renderFilterRoute ?
								<div id="return-to-filter">
									<p>Return to filter page to continue</p>
									<button onClick={() => history.push('/project-planning/filter')}>Return to filter</button>
								</div> :
								<>
								{criteriaSvc.selectedScale && regionSvc.selectedRegionName ? <p>Scale: {criteriaSvc.selectedScale}, Region: {regionSvc.selectedRegionName}</p> : null}
								<Criteria/>
								</>
							}
							<div className="button-container">
								<button className="primary" id="explore-outcomes-button"onClick={() => clickExploreOutcomes()}>Load Projects</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
