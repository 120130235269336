import React, {useEffect, useState} from 'react';
import { useProjects } from '../../services/Projects_Service';
import { useAppState } from '../../services/App_State_Service';
import './StreamStabilityRivers.scss';

export default function StreamStability(props){
    const projectsSvc = useProjects();
    const appStateSvc = useAppState();

    function numberInput(e, field, theId, idx){
        // e = the value, field = the field that's getting targeted, theId = the ffi_id/dissid2021
        let newArr = []
        let targetObj;
        appStateSvc.riverRowObj.forEach((i) => {
            // console.log('i', i);
            // console.log('keys check', Object.keys(i)[0], theId);
            if(Object.keys(i)[0] == theId){
                targetObj = Object.values(i);
            };
        });
        // console.log('targetObj', targetObj);
        // let localAdd = riverStability;
        if(!e.target.value){
            targetObj[0][field] = null;
        }else{
            targetObj[0][field] = e.target.value;
        }
        appStateSvc.riverRowObj.forEach((i) => {
            if(Object.keys[i] == theId){
                targetObj = i
            }
            newArr.push(i);
        })
        return appStateSvc.setRiverRowObj(newArr);
    };

    function lookUpValue(theId, str){
        for(let i = 0; i < appStateSvc.riverRowObj.length; i++){
            //first, they find the riverRowObj backed on the id
            if(Object.keys(appStateSvc.riverRowObj[i])[0] == theId){
                //if it matches, get the values; target = the values object we want...
                let target = Object.values(appStateSvc.riverRowObj[i])[0];
                //how do we handle things when there are values
                if(target[str]){
                    //this means that there is something to return
                    return target[str];
                }else{
                    return ''
                }
            }
        }
    };

    function roundNumbers(num){
        return Number.parseFloat(num).toFixed(1)
    }

    function generateTableBody(){
        // console.log('props check', projectsSvc.riverStabilityRes)
        if(!projectsSvc.riverStabilityRes || !projectsSvc.riverStabilityRes){
            return null;
        }
        // console.log('projectsSvc.riverStabilityRes', projectsSvc.riverStabilityRes)
        return projectsSvc.riverStabilityRes.map((i, idx) => {
            return(
                <>
                    <tr className="river-existing-data-row">
                        <td>
                            <div className="river-existing-data-row_id">
                                <p>{i.ffi_id}</p>
                                <p>Existing</p>
                            </div>
                        </td>
                        <td>{i.aoiac ? roundNumbers(i.aoiac) : 0}</td>
                        <td>{i.ac50 ? roundNumbers(i.ac50) : 0}</td>
                        <td>{i.ir ? roundNumbers(i.ir) : 0}</td>
                        <td>{i.unconsac ? roundNumbers(i.unconsac) : 0}</td>
                        <td>{i.robust ? roundNumbers(i.robust) : 0}</td>
                        <td>{i.mod ? roundNumbers(i.mod) : 0}</td>
                        <td>{i.low ? roundNumbers(i.low) : 0}</td>
                        <td>{i.none ? roundNumbers(i.none) : 0}</td>
                        <td>{i.veg_ac50 ? roundNumbers(i.veg_ac50) : 0}</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr className="river-proposted-data-row">
                        <td className="river-proposted-data-row_id">
                            <p></p>
                            <p>Proposed Project Values</p>
                        </td>
                        <td>-</td>
                        <td>-</td>
                        <td>
                            <input type="number" value={lookUpValue(i.ffi_id, 'ext_ir')} onChange={(k) => numberInput(k, 'ext_ir', i.ffi_id)}/>
                        </td>
                        <td>
                            <label className="hide" for="unconsac_input + i.">unconsac input</label><input id="unconsac_input" type="number" value={lookUpValue(i.ffi_id, 'unconsac')} onChange={(k) => numberInput(k, 'unconsac', i.ffi_id)}/>
                        </td>
                        <td>
                            <label className="hide" for='robust + i.robust'>robust input</label><input id="robust" type="number" value={lookUpValue(i.ffi_id, 'robust')} onChange={(k) => numberInput(k, 'robust', i.ffi_id)}/>
                        </td>
                        <td>
                            <input type="number" value={lookUpValue(i.ffi_id, 'mod')} onChange={(k) => numberInput(k, 'mod', i.ffi_id)}/>
                            {/* <input type="number" value={appStateSvc.riverRowObj[i.ffi_id] ? appStateSvc.riverRowObj[i.ffi_id].mod : null} onChange={(k) => numberInput(k, 'mod', i.ffi_id)}/> */}
                        </td>
                        <td>
                            <input type="number" value={lookUpValue(i.ffi_id, 'low')} onChange={(k) => numberInput(k, 'low', i.ffi_id)}/>
                        </td>
                        <td>
                            <input type="number" value={lookUpValue(i.ffi_id, 'none')} onChange={(k) => numberInput(k, 'none', i.ffi_id)}/>
                        </td>
                        <td>
                            <input type="number" value={lookUpValue(i.ffi_id, 'veg_ac50')} onChange={(k) => numberInput(k, 'veg_ac50', i.ffi_id)}/>
                        </td>
                        <td>
                            <input type="number" value={lookUpValue(i.ffi_id, 'prop_ir')} onChange={(k) => numberInput(k, 'prop_ir', i.ffi_id)}/>
                        </td>
                        <td>
                            <input type="number" value={lookUpValue(i.ffi_id, 'areavertchange')} onChange={(k) => numberInput(k, 'areavertchange', i.ffi_id)}/>
                        </td>
                    </tr>
                </>
            )
        })
    };

    return(
        <table className="stream-stability-table">
            <thead>
                <tr>
                    <th class="river-stability-id-header">River Corridor ID</th>
                    <th>River Corridor Area (acres)</th>
                    <th>50-ft Riparian Area (acres)</th>
                    <th>Existing Incision Ratio</th>
                    <th>Unconstrained River Corridor Area (acres)</th>
                    <th>Robust Protection Area (acres)</th>
                    <th>Moderate Protection Area (acres)</th>
                    <th>Low Protections Area (acres)</th>
                    <th>No Protection Area (acres)</th>
                    <th>Naturally Vegetated Buffer Area (acres)</th>
                    <th>Proposed Incision Ratio</th>
                    <th>Area with Vertical Change (acres)</th>
                </tr>
            </thead>
            <tbody>
                {generateTableBody()}
            </tbody>
        </table>
    )
}