import React, { useEffect, useState } from 'react';
import Layers from './Layers';
import Search from './Search';
import Popup from './Popup';
import { useAppState } from '../../services/App_State_Service';
import { useProjects } from '../../services/Projects_Service'; 
import './MapTools.scss';

export default function MapTools(props) {
    const appStateSvc = useAppState();
    const projectSvc = useProjects()
    const [hideMapTools, setHideMapTools] = useState(false);
    useEffect( () => {
		// Stuff to do if only there is a mapview
	},[appStateSvc.mapView]);

    useEffect(() => {
        projectSvc.setFilteredProjectPlanningList([]);
        appStateSvc.setSelectedMapId(null);
    }, []);

    return (
        <>
            <button 
                onClick={() => {setHideMapTools(!hideMapTools)}} 
                className={
                    hideMapTools ? 
                    "maptools-button-closed" : "maptools-button-open"
                }
            >
                {"<"}
            </button>
            <div className={hideMapTools? "maptools-container maptools-container-hidden" : "maptools-container maptools-container-shown"}>
                <div className="maptools-header">
                    &nbsp;
                </div>
                <div className="maptools-content">
                    {appStateSvc.mapView ? <Popup /> : null}
                    {appStateSvc.mapView ? <Layers /> : null}
                    {appStateSvc.mapView ? <Search /> : null}
                </div>
            </div>
        </>
        
    )
}