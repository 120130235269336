import React, { useEffect } from 'react';
import { useAppState } from '../../services/App_State_Service';
import '../AlertModal/AlertModal.scss';

export default function AlertModal_Calc(){
    const appStateSvc = useAppState();

    function closeModal(){
        appStateSvc.setMakeAlert_Calc(false);
        appStateSvc.setAlertText_Calc([]);
    }

    // useEffect(() => {
    //     console.log('appStateSvc.alertText_Calc', appStateSvc.alertText_Calc);
    // }, []);

    function generateTextBox(){
        return appStateSvc.alertText_Calc.map((val, idx) => {
            // delete(object_name[key_name]);
            let theId = val.ffi_id;
            delete(val['ffi_id']);
            let keyArr = Object.keys(val);
            let valArr = Object.values(val);
            return <div className="ffi_id_popup">
                <div className="popup_id">
                    <p>{theId}</p>
                </div>
                <div className="popup_data">
                    {genIssues(keyArr, valArr)}
                </div>
            </div>
        });
    }

    function genIssues(key, val){
        return key.map((label, idx) => {
            return <>
                <p><span className="issue-key">{label}</span>: <span className="issue-value">{val[idx]}</span></p>
            </>
        }) 
    }

    return(
        <div className="modal-container">
            <div className="inner-modal-container-calc">
                <p>Please fix the following to run a calculation</p>
                {generateTextBox()}
                <div className="modal-button-container">
                    <button onClick={() => closeModal()}>Close</button>
                </div>
            </div>
            
        </div>
    )
};