import React, {useState} from 'react';
import { useAppState } from '../../services/App_State_Service';
import { useProjects } from '../../services/Projects_Service';
import {MdClose} from 'react-icons/md';
import '../AlertModal/AlertModal.scss';

export default function AlertModal_Export(){
    // const [wid_id, setWid_Id] = useState('');
    const appStateSvc = useAppState();
    const projSvc = useProjects();

    function closeModal(){
        appStateSvc.setExport_wid(-1);
        appStateSvc.setMakeAlert_Export(false);
        appStateSvc.setAlertText('');
    };

    function nameChange(val){
        appStateSvc.setExport_wid(val)
    }

    function downloadExport(){
        if(appStateSvc.reportToExport === 'proj-screening'){
            projSvc.exportProjectScreening();
        } else if (appStateSvc.reportToExport === 'phos-calc'){
            projSvc.exportCalcInputs();
        }else if (appStateSvc.reportToExport === 'wq-tab'){
            projSvc.exportWQTab_Summary();
            projSvc.exportWQTab_Details();
        }else if(appStateSvc.reportToExport === 'watershed-reporting'){
            projSvc.exportWaterResiliencyCSV()
        } else if (appStateSvc.reportToExport === 'watershed-reporting-conn-details'){
            projSvc.exportConnDetailsReport();
        }else if (appStateSvc.reportToExport === 'resil-report'){
            projSvc.exportResilReport();
        }else if (appStateSvc.reportToExport === 'proj-report'){
            projSvc.exportProjectReport();
        }
        return closeModal();
    }

    return(
        <div className="modal-container">
            <div className="inner-modal-container">
                <span className="close-icon" onClick={() => closeModal()}><MdClose/></span>
                <h1 id="alert-instructions">Please Enter a File Name for the export</h1>
                <div>
                    <form className="wid_form">
                        <input type="string" onChange={(e) => nameChange(e.target.value)}/>
                    </form>
                </div>
                <div className="modal-button-container">
                    <button onClick={() => downloadExport()}>Export CSV</button>
                </div>
            </div>
            
        </div>
    );
}