import React, {useState, useEffect} from 'react';
import {TriangleIcon, FunctionIcon, ProjectTypeIcon, ProjectPriorityIcon } from '../SVGs/Icon';
import { useCriteria } from '../../services/Criteria_Service';
import { useProjects } from '../../services/Projects_Service';
import { useRegions } from '../../services/Region_Service';
import './Criteria.scss';

export default function Criteria(props){
    const [displayOne, handleDisplayOne] = useState(false);
    const [displayTwo, handleDisplayTwo] = useState(false);
    const [displayThree, handleDisplayThree] = useState(false);
    const [displayFour, handleDisplayFour] = useState(false);
    const [showDefaultSelect, setDefaultSelect] = useState(true);
    const criteria = useCriteria();
    const projectsSvc = useProjects();
    const regionSvc = useRegions();

    const clickFunctionCheckbox = (val) => {
        if(!criteria.checkedFunctions[val.label]){
            let localAdd = criteria.checkedFunctions
            localAdd[val.label] = val.value;
            filterProperties(val.label)
            criteria.setCheckedFunctions(localAdd);
            // console.log('fullFunctionList', criteria.fullFunctionList)
            criteria.filterProjectCriteria(criteria.fullFunctionList, 'func')
        }
        else{
            let localDel = criteria.checkedFunctions;
            delete localDel[val.label];
            criteria.setCheckedFunctions(localDel);
            filterProperties(false);
            criteria.filterProjectCriteria(criteria.fullFunctionList, 'func')
        }
    };

    const clickApplicabilityCheckbox = (val) => {
        // ok so the val is the string of what we want
        setDefaultSelect(false);
        if(!criteria.checkedApplicability[val]){
            let localAdd = criteria.checkedApplicability
            localAdd[val] = true;
            criteria.setCheckedApplicability(localAdd)
            filterProperties(val)
            criteria.filterProjectCriteria(criteria.fullFunctionList, 'app')
        }
        else{
            let localDel = criteria.checkedApplicability;
            delete localDel[val];
            // console.log(localDel)
            criteria.setCheckedApplicability(localDel)
            filterProperties(false);
            criteria.filterProjectCriteria(criteria.fullFunctionList, 'app')
        }
    }

    const clickProjectsCheckbox = (val) => {
        if(!criteria.checkedProjects[val.label]){
            let localAdd = criteria.checkedProjects;
            localAdd[val.label] = val.value;
            filterProperties(val.label)
            // why is this undefined???? i can literally see it
            criteria.setCheckedProjects(localAdd);
            criteria.filterProjectCriteria(criteria.fullFunctionList, 'proj')

        }
        else{
            let localDel = criteria.checkedProjects;
            delete localDel[val.label];
            criteria.setCheckedProjects(localDel)
            filterProperties(false);
            criteria.filterProjectCriteria(criteria.fullFunctionList, 'proj')
        }
    }

    const clickPrioritiesCheckbox = (val) => {
        if(!criteria.checkedPriorities[val]){
            let localAdd = criteria.checkedPriorities;
            localAdd[val] = val;
            criteria.setCheckedPriorities(localAdd)
            filterProperties(val);
            criteria.filterProjectCriteria(criteria.fullFunctionList, 'prior')

        }
        else{
            let localDel = criteria.checkedPriorities;
            delete localDel[val];
            criteria.setCheckedPriorities(localDel);
            filterProperties(false);
            criteria.filterProjectCriteria(criteria.fullFunctionList, 'prior')
        }
    }

    const filterProperties = (val) => {
        // if (val === false){
        //     return console.log('item removed from filter');
        // }
        // console.log('new filter value:', val);
        projectsSvc.fetchProjectPlanningList(regionSvc.selectedRegion, criteria.getCheckedCriteria())
    }

    const renderApplicabilityInput = () => {
        if(!criteria.criteriaList ||criteria.criteriaList == {} || 
            criteria.criteriaList.applicability === {} || !criteria.criteriaList.applicability){
            return (
                <div className="selectGroup">
                    <p>No results found. Please update your search and try again</p>
                </div>
            )
        }else{
            let sortedArr = [];
            for(let i = 0; i < criteria.criteriaList.applicability.length; i++){
                if(criteria.criteriaList.applicability[i].name == 'High'){
                    sortedArr.unshift(criteria.criteriaList.applicability[i].name)
                }else{
                    sortedArr.push(criteria.criteriaList.applicability[i].name);
                }
            }
            return(
                <div className="selectCriteria" id="applicability-container">
                    <div>
                        {sortedArr.map((i, idx) => { 
                            return(
                                <div className="selectItem">
                                    <input 
                                        key={idx}
                                        type="checkbox" 
                                        id={`app-custom-checkbox-${i}`}
                                        name={i}
                                        value={i}
                                        onChange={() => clickApplicabilityCheckbox(i)}
                                        // checked={handleCheck(i.name)}
                                        // defaultChecked={handleDefaultCheckedApplicability(i)}
                                        checked={handleDefaultCheckedApplicability(i)}
                                    />
                                    <label htmlFor={`app-custom-checkbox-${i}`}>{i}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            );
        }
    }

    const renderFunctionInput = () => {
        if(!criteria.criteriaList || criteria.criteriaList == {} || 
            criteria.criteriaList.functions === {} || !criteria.criteriaList.functions){
            return (
                <div className="selectGroup">
                    <p>No results found. Please update your search and try again</p>
                </div> 
            )
        };
        let localKeys = Object.keys(criteria.criteriaList.functions);
        localKeys.sort((a,b) => {
            if(a > b){
                return -1
            }else{
                return 1;
            }
        });
        //stop gap so that sorting is right cause i wanna keep this moving - CRA 6/27/23
        const temp = localKeys[1];
        localKeys[1] = localKeys[2]
        localKeys[2] = temp;
        localKeys.forEach((item) => {
            if(item){
                criteria.criteriaList.functions[item].sort((a,b) => {
                    if(a.label > b.label){
                        return 1
                    }else{
                        return -1;
                    }
                })
            }
        })
        // console.log('criteria.criteriaList', criteria.criteriaList);
        return(
            <div className="selectCriteria" id="select-criteria-for-section-1">
                {/* {localKey_Sorted.map((item, idx) => { */}
                {localKeys.map((item, idx) => {
                    return (
                        <div>
                            <div className="selectGroupLabel" key={idx}>{item}</div>
                            <div className="selectGroup">
                                {
                                    criteria.criteriaList.functions[item].map(
                                        (funcItem, idx) => {
                                            return (
                                                <div className="selectItem" key={idx}>
                                                    <input
                                                        type="checkbox"
                                                        id={`func-custom-checkbox-${funcItem.value}`}
                                                        name={funcItem.label}
                                                        value={funcItem.value}
                                                        // checked={criteria.checkedFunctions[funcItem.value]}
                                                        onChange={() => clickFunctionCheckbox(funcItem)}
                                                        checked={handleDefaultCheckedFunctions(funcItem)}
                                                    />
                                                    <label htmlFor={`func-custom-checkbox-${funcItem.value}`}>{funcItem.label}</label>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderProjTypeInputs = () => {
        if(!criteria.criteriaList || criteria.criteriaList == {} || 
            criteria.criteriaList.projectTypes === {} || !criteria.criteriaList.projectTypes){
            return (
                <div className="selectGroup">
                    <p>No results found. Please update your search and try again</p>
                </div> 
            )
        };
        return (
            <div className="selectCriteria">
                {Object.keys(criteria.criteriaList.projectTypes).map((item) => {
                    return (
                        <div>
                            <div className="selectGroupLabel">{item}</div>
                            <div className="selectGroup">
                                {
                                    criteria.criteriaList.projectTypes[item].map(
                                        (prjItem, idx) => {
                                            return (
                                                <div className="selectItem" key={idx}>
                                                    <input
                                                        type="checkbox"
                                                        id={`proj-custom-checkbox-${prjItem.value}`}
                                                        name={prjItem.label}
                                                        value={prjItem.value}
                                                        // checked={criteria.checkedProjects[prjItem.va]}
                                                        onChange={() => clickProjectsCheckbox(prjItem)}
                                                        key={prjItem.value}
                                                        checked={handleDefaultCheckedProjectTypes(prjItem)}
                                                    />
                                                    <label htmlFor={`proj-custom-checkbox-${prjItem.value}`}>{prjItem.label}</label>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderPriorityInputs = () => {
        // console.log('criteriaList priorities', criteria.criteriaList.priorities)
        if(!criteria.criteriaList || criteria.criteriaList == {} || 
            criteria.criteriaList.priorities === {} || !criteria.criteriaList.priorities){
            return (
                <div className="selectGroup">
                    <p>No results found. Please update your search and try again</p>
                </div> 
            )
        }
        let sortedArr = [];
        for(let i = 0; i < criteria.criteriaList.priorities.length; i++){
            sortedArr.push(criteria.criteriaList.priorities[i].name);
        }
        // console.log('sortedArr', sortedArr);
        return(
            <div className="selectCriteria">
                <div className="selectGroup" id="project-priority-container">
                    {
                        sortedArr.map(
                            (priority, idx) => {
                                // console.log('priority', priority)
                                return (
                                    <div className="selectItem" key={idx}>
                                        <input
                                            type="checkbox"
                                            id={`prior-custom-checkbox-${idx}`}
                                            name={priority}
                                            value={priority}
                                            // checked={criteria.checkedPriorities[criteria.criteriaList.priorities[priority]]}
                                            onChange={() => clickPrioritiesCheckbox(priority)}
                                            checked={handleDefaultCheckedProjectPriority(priority)}
                                        />
                                        
                                        <label htmlFor={`prior-custom-checkbox-${idx}`}>{priority}</label>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        )
    }
    //ok these are gonna need to be re-written
    const handleDefaultCheckedFunctions = (val) => {
        // console.log('checkbox', val.label);
        // console.log(criteria.checkedFunctions[val]);
        if(criteria.checkedFunctions[val.label]){
            // console.log('returning true')
            return true;
        }else{
            return false
        }
    }

    const handleDefaultCheckedApplicability = (val) => {
        // for (let i in criteria.checkedApplicability){
        //     if (criteria.checkedApplicability[val]){
        //         return true
        //     }
        // }
        if(criteria.checkedApplicability[val]){
            return true;
        }else{
            return false
        }
    }

    const handleDefaultCheckedProjectTypes = (val) => {
        // console.log('checkbox', val.label);
        // for (let i in criteria.checkedProjects){
        //     if (criteria.checkedProjects[val]){
        //         return true
        //     }
        // }
        if(criteria.checkedProjects[val.label]){
            return true;
        }else{
            return false
        }
    }

    const handleDefaultCheckedProjectPriority = (val) => {
        // console.log(val)
        // console.log(criteria.checkedPriorities)
        // console.log('val', val, criteria.checkedPriorities)
        if(criteria.checkedPriorities[val]){
            return true;
        }else{
            return false
        }
    }

    useEffect(() => {
    }, [criteria.criteriaList, criteria.checkedFunctions, criteria.checkedProjects, criteria.checkedApplicability, criteria.checkedPriorities]);

    return(
        <>
            <div className="criteria" id="criteria-outer-container">
                <div className="accordian-container">
                    <button 
                        className="accordian accordion-button criteriaSection functionsCriteriaGroup" 
                        id="section-1" 
                        onClick={() => handleDisplayOne(!displayOne)}>
                        {TriangleIcon(displayOne)}
                        Functions
                    </button>
                    {displayOne ? renderFunctionInput() : null}
                    <button className="accordian section-4 accordion-button criteriaSection applicabilitiesCriteriaGroup" id="section-4" onClick={() => handleDisplayFour(!displayFour)}>
                       {TriangleIcon(displayFour)}
                        <span>Function Applicability</span>
                    </button>
                    {displayFour ? renderApplicabilityInput() : null}
                    <button className="accordian section-2 accordion-button criteriaSection projectsCriteriaGroup" id="section-2" onClick={() => handleDisplayTwo(!displayTwo)}>
                        {TriangleIcon(displayTwo)}
                        Project Types
                    </button>
                    {displayTwo ? renderProjTypeInputs()
                        : null}
                    <button className="accordian accordion-button criteriaSection prioritiesCriteriaGroup" id="section-3" onClick={() => handleDisplayThree(!displayThree)}>
                        {TriangleIcon(displayThree)}
                        Project Priority
                    </button>
                    {displayThree ? renderPriorityInputs()
                        : null
                    }
                </div>
            </div>

        </>
    )
}
