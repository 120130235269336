import {React, useEffect, useState} from 'react';
import {useProjects} from '../../services/Projects_Service';
import {useAppState} from '../../services/App_State_Service';
import { useRegions } from '../../services/Region_Service';
import { useCriteria } from '../../services/Criteria_Service';
import {BsFillTriangleFill} from 'react-icons/bs';
import Select from 'react-select';

export default function ProjReport(props){
    const [scaleLevels, setScaleLevels] = useState([
        { value: 'BASINS', label: 'Watershed Planning Basins' },
        { value: 'HUC10', label: 'HUC10' },
        { value: 'HUC12', label: 'HUC12'},
        { value: 'TOWNS', label: 'By Town' },
        { value: 'COUNTIES', label: 'By County' }
    ]);
    const [geographyOpen, setGeographyOpen] = useState(false);
    const [floodplainOpen, setFloodplainOpen] = useState(false);
    const [streamOpen, setStreamOpen] = useState(false);
    const [protectionOpen, setProtectionOpen] = useState(false);
    const [watershedOpen, setWatershedOpen] = useState(false);
    const [aggregationOpen, setAggregationOpen] = useState(false);
    const [projPriorityOpen, setProjPriorityOpen] = useState(false);

    const [checkedGeography, setCheckedGeography] = useState([]);
    const [checkedFloodplain, setCheckedFloodplain] = useState([]);
    const [checkedStream, setCheckedStream] = useState([]);
    const [checkedProtectionRank, setCheckedProtectionRank] = useState([]);
    const [checkedWatershed, setCheckedWatershed] = useState([]);
    const [checkedAggScale, setCheckedAggScale] = useState('');
    const [checkedProjPriority, setCheckedProjPriority] = useState([]);

    const [projPriority, setProjPriority] = useState([]);
    const [localFilteredRegion, setLocalFilteredRegion] = useState([]);
    const [selectedScale, setSelectedScale] = useState(null);



    const projectSvc = useProjects();
    const appStateSvc = useAppState();
    const regionSvc = useRegions();
    const criteriaSvc = useCriteria();

    function genProjectReport(str){
        //assemble the payload
        let regionsArr = [];
        let regionsArrName = [];
        for(let i = 0; i < checkedGeography.length; i++){
            regionsArr.push(checkedGeography[i].regionid);
            regionsArrName.push(checkedGeography[i].value);
        }
        let payload = {
            'regionsfilter': regionsArr,
            'latconnfilters': checkedFloodplain,
            'longconnfilters': checkedStream,
            'protectionfilters': checkedProtectionRank,
            'positionfilters': checkedWatershed,
            'scale': criteriaSvc.selectedScale,
            'aggregation': checkedAggScale,
            'prjtpriorityfilters': checkedProjPriority
        };

        let payload2 = {
            'regionsfilter': regionsArrName.length ? regionsArrName : '-',
            'latconnfilters': checkedFloodplain.length ? checkedFloodplain.toString() : '-',
            'longconnfilters': checkedStream.length ? checkedStream.toString() : '-',
            'protectionfilters': checkedProtectionRank.length ? checkedProtectionRank.toString() : '-',
            'positionfilters': checkedWatershed.length ? checkedWatershed.toString() : '-',
            'scale': criteriaSvc.selectedScale ? criteriaSvc.selectedScale : '-',
            'aggregation': checkedAggScale ?checkedAggScale : '-',
            'prjtpriorityfilters': checkedProjPriority.length ? checkedProjPriority.toString() : '-'
        }

        console.log('payload', payload);
        if(payload.aggregation == ''){
            appStateSvc.setMakeAlert(true);
            appStateSvc.setAlertText('Please Select an Aggregation Scale to Generate a Report');
            return;
        }
        // appStateSvc.setMakeAlert_Export(true);
        // appStateSvc.setReportToExport(str);
        // //what does this hook do? follow up - CRA 5/22/23
        // projectSvc.setWatershedExportData([]);
        appStateSvc.setBuffering(true);
        regionSvc.get_ProjectReport(payload, str);
        projectSvc.setWqReportHeader(payload2);
    };

    function clearResults(){
        setCheckedGeography([]);
        setCheckedFloodplain([]);
        setCheckedProtectionRank([]);
        setCheckedWatershed([]);
        setCheckedAggScale('');
        setSelectedScale(null);
        setLocalFilteredRegion([]);
        setCheckedStream([]);
        setCheckedProjPriority([]);
    }

    function generateProjPriorityCheckbox(){
        if(projPriority.length){
            return projPriority.map((proj) => {
                return (
                    <div>
                        <input id={'proj-' + proj.priority} type="checkbox" onChange={() => toggleProjectPriority(proj.priority)} checked={checkedProjPriority.indexOf(proj.priority) > -1 ? true : false}/>
                        <label for={'proj-' + proj.priority}>{proj.priority}</label>
                    </div>
                )
            })
        }
    }

    function scaleSelected(val){
        setCheckedGeography([]);
        
        if(!val || val == ''){
            regionSvc.setToggleRegions(false);
            criteriaSvc.setToggleCriteria(false);
            criteriaSvc.setSelectedScale('');
            regionSvc.setSelectedRegion('');
            criteriaSvc.setCriteriaList({});
            setSelectedScale(null)
            return null;
        }
        regionSvc.setSelectedRegion(''); // ARH
        regionSvc.setSelectedRegionName(''); // ARH
        regionSvc.setToggleRegions(true);
        criteriaSvc.setSelectedScale(val.value);
        setSelectedScale(val.value)
        sortRegions(val.value);
    };

    function toggleProjectPriority(str){
        let updatedCheck = [...checkedProjPriority];
        if(checkedProjPriority.indexOf(str) == -1){
            updatedCheck = [...checkedProjPriority, str];
        }else{
            updatedCheck.splice(checkedProjPriority.indexOf(str), 1);
        }
        return setCheckedProjPriority(updatedCheck);
    }

    function sortRegions(val){
        let catcher = [];
        if(regionSvc.region.length && val){
            for(let i = 0; i < regionSvc.region.length; i++){
                if(val === regionSvc.region[i].scale.toUpperCase()){
                    catcher.push({
                        value: regionSvc.region[i].region,
                        label: regionSvc.region[i].region,
                        regionid: regionSvc.region[i].regionid,
                        scale: regionSvc.region[i].scale
                    });
                }
            }
            setLocalFilteredRegion(catcher)
        };
    };

    useEffect(() => {
        let fullScale = [
            { value: 'BASINS', label: 'Watershed Planning Basins' },
            { value: 'HUC10', label: 'HUC10' },
            { value: 'HUC12', label: 'HUC12'},
            { value: 'TOWNS', label: 'By Town' },
            { value: 'COUNTIES', label: 'By County' }
        ];
        let filteredScale;
        if(checkedAggScale == 'Basin'){
            filteredScale = fullScale.filter((item) => item.value === 'BASINS');
        } else if (checkedAggScale == 'Huc10'){
            filteredScale = fullScale.filter((item) => item.value === 'BASINS' || item.value === 'HUC10');
        } else if (checkedAggScale == 'Huc12'){
            filteredScale = fullScale.filter((item) => item.value !== 'COUNTIES' && item.value !== 'TOWNS');
        } else if (checkedAggScale == 'County'){
            filteredScale = fullScale.filter((item) => item.value !== 'TOWNS');
        } else {
            filteredScale = fullScale;
        }
        setScaleLevels(filteredScale);
    }, [checkedAggScale]);

    useEffect(() => {
        regionSvc.get_projectPriorityFilters().then((res) => {
            let dataRes = res;
            dataRes = dataRes = dataRes.sort((a, b) => {
                if(a.sorder > b.sorder){
                    return 1;
                } else{
                    return -1;
                }
            })
            setProjPriority(dataRes);
        })
        .catch((err) => console.log("get_projectPriorityFilters err", err));
    }, []);

    return <>
        <span className="clear-results"><button onClick={() => clearResults()}>Clear</button></span>
        <button className="selectGroupLabel light" onClick={() => setAggregationOpen(!aggregationOpen)}><span className={aggregationOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Aggregation Scale <span id="required">*required</span></button>
        {aggregationOpen ? props.generateAggregationCheckboxes(checkedAggScale, setCheckedAggScale) : null}
        <button className="selectGroupLabel dark" onClick={() => setGeographyOpen(!geographyOpen)}><span className={geographyOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Geography</button>
        {geographyOpen ? <>
            <Select
                name="select-scale-level"
                className="selectMenu"
                id="scale-select"
                inputId="scale-select-options"
                // name="scale-options"
                options={scaleLevels}
                isMulti={false}
                onChange={(i) => scaleSelected(i)}
                data-testid="scale-select-options"
                // isDisabled={
                //     checkedAggScale === 'Basin' ||

                // }
                value = {
                    scaleLevels.filter(option => option.value == selectedScale)
                }
            />
            {props.generateGeographyCheckboxes(checkedGeography, setCheckedGeography, localFilteredRegion)}
        </> : null}
        <button className="selectGroupLabel light" onClick={() => setFloodplainOpen(!floodplainOpen)} aria-label="Floodplain (Lateral-Vertical) Connectivity"><span className={floodplainOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Floodplain (Lateral-Vertical) Connectivity</button>
        {floodplainOpen ? props.generateFloodplainCheckboxes(checkedFloodplain, setCheckedFloodplain) : null}
        <button className="selectGroupLabel dark" onClick={() => setStreamOpen(!streamOpen)} aria-label="Stream (Longitidinal-Temporal) Connectivity"><span className={streamOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Stream (Longitidinal-Temporal) Connectivity</button>
        {streamOpen ? props.generateStreamCheckboxes(checkedStream, setCheckedStream) : null}
        <button className="selectGroupLabel light" onClick={() => setProtectionOpen(!protectionOpen)} aria-label="Protection Rank"><span className={protectionOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Protection Rank</button>
        {protectionOpen ? props.generateProtection(checkedProtectionRank, setCheckedProtectionRank) : null}
        <button className="selectGroupLabel dark" onClick={() => setWatershedOpen(!watershedOpen)} aria-label="Watershed Position"><span className={watershedOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Watershed Position</button>
        {watershedOpen ? props.generateResiliency(checkedWatershed, setCheckedWatershed) : null}
        <button className="selectGroupLabel light" onClick={() => setProjPriorityOpen(!projPriorityOpen)}><span className={projPriorityOpen ? "arrow open" : "arrow"}><BsFillTriangleFill/></span>Project Priority</button>
        {projPriorityOpen ? generateProjPriorityCheckbox() : null}
        <button className="gen-watershed-report" onClick={() => genProjectReport("proj-report")}>Generate Report</button>
    </>
}