import React, {useState, useEffect} from 'react';
import { useAppState } from '../../services/App_State_Service';
import './CostEffectiveness.scss'

export default function CostEffectiveness(){
    const appStateSvc = useAppState();
    return(
        <>
            <div className="cost-effect-container" style={{paddingBottom: appStateSvc.currentSliderHeight + 20}}>
                <p>
                    Note that these cost-effectiveness values were calculated based on completed projects in Vermont and actual cost effectiveness will vary for a specific project implemented for the FFI or TMDL. This table should be used for planning purposes to compare average cost-effectiveness values between project types. Project-specific cost-effectiveness values for the purposes of project prioritization and screening under Formula Grants need to be calculated using the cost effectiveness methodology outlined in Act 76 Guidance. The VTDEC tool available at <a href="https://dec.vermont.gov/water-investment/cwi/grants/resources" target="_blank" id="cost-effect-link">https://dec.vermont.gov/water-investment/cwi/grants/resources</a> can be used to calculate cost effectiveness following the Act 76 Guidance methodology.
                </p>
                <table>
                    <thead className="cost-table-header">
                        <tr>
                            <th>Project Class</th>
                            <th>Project Type</th>
                            <th>Practice</th>
                            <th>Cost-Effectiveness ($USD/lb TP/yr)</th>
                            <th>Cost-Effectivenss ($USD/kg TP/yr)</th>
                        </tr>
                    </thead>
                    <tbody className="cost-table-body">
                        {/* 1 */}
                        <tr>
                            <td>Natural Resource / Re-Connection Project</td>
                            <td>Floodplain Restoration</td>
                            <td>Berm Removal</td>
                            <td>$2,050</td>
                            <td>$4,519</td>
                        </tr>
                        {/* 2 */}
                        <tr>
                            <td>Natural Resource / Re-Connection Project</td>
                            <td>Buffers</td>
                            <td>Buffers</td>
                            <td>$2,786</td>
                            <td>$6,142</td>
                        </tr>
                        {/* 3 */}
                        <tr >
                            <td>Natural Resource / Re-Connection Project</td>
                            <td>Corridor Easement</td>
                            <td>Corridor Easement</td>
                            <td>$5,944</td>
                            <td>$13,104</td>
                        </tr>
                        {/* 4*/}
                        <tr>
                            <td>Natural Resource / Re-Connection Project</td>
                            <td>Floodplain Restoration</td>
                            <td>Create Flood Bench</td>
                            <td>$12,351</td>
                            <td>$27,230</td>
                        </tr>
                        {/* 5 */}
                        <tr className="gray">
                            <td>Stormwater Best Management Practice</td>
                            <td>Infiltration Practices</td>
                            <td>Surface Infiltration</td>
                            <td>$12,500</td>
                            <td>$27,558</td>
                        </tr>
                        {/* 6 */}
                        <tr>
                            <td>Natural Resource / Re-Connection Project</td>
                            <td>Dam Removal</td>
                            <td>Medium ROR Dam</td>
                            <td>$13,438</td>
                            <td>$29,627</td>
                        </tr>
                        {/* 7 */}
                        <tr>
                            <td>Natural Resource / Re-Connection Project</td>
                            <td>Floodplain Restoration</td>
                            <td>Lower Floodplain</td>
                            <td>$14,017</td>
                            <td>$30,902</td>
                        </tr>
                        {/* 8 */}
                        <tr className="gray">
                            <td>Stormwater Best Management Practice</td>
                            <td>Infiltration Practices</td>
                            <td>Subsurface Infiltration</td>
                            <td>$15,000</td>
                            <td>$33,069</td>
                        </tr>
                        {/* 9 */}
                        <tr className="gray">
                            <td>Stormwater Best Management Practice</td>
                            <td>Infiltration Practices</td>
                            <td>Infiltration Trench</td>
                            <td>$15,000</td>
                            <td>$33,069</td>
                        </tr>
                        {/* 10 */}
                        <tr>
                            <td>Natural Resource / Re-Connection Project</td>
                            <td>Floodplain Restoration</td>
                            <td>Raise Channel</td>
                            <td>$16,224</td>
                            <td>$35,769</td>
                        </tr>
                        {/* 11 */}
                        <tr className="gray">
                            <td>Stormwater Best Management Practice</td>
                            <td>Infiltration Practices</td>
                            <td>Rain Garden &#40;no underdrain&#41;</td>
                            <td>$17,500</td>
                            <td>$38,581</td>
                        </tr>
                        {/* 12 */}
                        <tr>
                            <td>Natural Resource / Re-Connection Project</td>
                            <td>Dam Removal</td>
                            <td>Medium Breached Dam</td>
                            <td>$19,814</td>
                            <td>$43,682</td>
                        </tr>
                        {/* 13 */}
                        <tr>
                            <td>Natural Resource / Re-Connection Project</td>
                            <td>Infiltration Practices</td>
                            <td>Small ROR Dam</td>
                            <td>$20,519</td>
                            <td>$45,237</td>
                        </tr>
                        {/* 14 */}
                        <tr className="gray">
                            <td>Stormwater Best Management Practice</td>
                            <td>Filtering Practices</td>
                            <td>Constructed Wetlands</td>
                            <td>$30,000</td>
                            <td>$66,139</td>
                        </tr>
                        {/* 15 */}
                        <tr className="gray">
                            <td>Stormwater Best Management Practice</td>
                            <td>Ponds</td>
                            <td>Wet Ponds</td>
                            <td>$30,000</td>
                            <td>$66,139</td>
                        </tr>
                        {/* 16 */}
                        <tr className="gray">
                            <td>Stormwater Best Management Practice</td>
                            <td>Filtering Practices</td>
                            <td>Gravel Wetland</td>
                            <td>$35,000</td>
                            <td>$77,162</td>
                        </tr>
                        {/* 17 */}
                        <tr className="gray">
                            <td>Stormwater Best Management Practice</td>
                            <td>Filtering Practices</td>
                            <td>Rain Garden &#40;with underdrain&#41;</td>
                            <td>$40,000</td>
                            <td>$88,185</td>
                        </tr>
                        {/* 18 */}
                        <tr className="gray">
                            <td>Stormwater Best Management Practice</td>
                            <td>Filtering Practices</td>
                            <td>Sand Filter</td>
                            <td>$52,500</td>
                            <td>$115,743</td>
                        </tr>
                        {/* 19 */}
                        <tr className="gray">
                            <td>Stormwater Best Management Practice</td>
                            <td>Filtering Practices</td>
                            <td>Grass Conveyance Swale</td>
                            <td>$60,000</td>
                            <td>$132,277</td>
                        </tr>
                        {/* 20 */}
                        <tr className="gray">
                            <td>Stormwater Best Management Practice</td>
                            <td>Ponds</td>
                            <td>Extended Dry Detention Ponds</td>
                            <td>$135,000</td>
                            <td>$297,624</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}