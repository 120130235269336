import React, {useEffect, useState} from 'react';
import './WatershedReporting.scss';
import { Icon } from '../SVGs/Icon';
import Select from 'react-select';
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai';
import {useProjects} from '../../services/Projects_Service';
import {useAppState} from '../../services/App_State_Service';
import { useRegions } from '../../services/Region_Service';
import { useCriteria } from '../../services/Criteria_Service';
import {BsFillTriangleFill} from 'react-icons/bs';
import ConnSummReport from './ConnSummaryReport';
import ConnDetailsReport from './ConnDetailsReport';
import ResilReport from './ResilReport';
import ProjReport from './ProjReport';

export default function WatershedReporting(){
    //TO-DO: ordering for menu here
    const [scaleLevels, setScaleLevels] = useState([
        { value: 'BASINS', label: 'Watershed Planning Basins' },
        { value: 'HUC10', label: 'HUC10' },
        { value: 'HUC12', label: 'HUC12'},
        { value: 'TOWNS', label: 'By Town' },
        { value: 'COUNTIES', label: 'By County' }
    ]);
    const [reportType, setReportType] = useState(null);
    const [showOne, setShowOne] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const [showFour, setShowFour] = useState(false);
    // const [floodplainConn, setFloodplainConn] = useState([]);
    // const [streamConn, setStreamConn] = useState([]);
    // const [protectionRank, setProtectionRank] = useState([]);
    // const [watershedReporting, setWatershedReporting] = useState([]);
    const [geographyOpen, setGeographyOpen] = useState(false);
    const [floodplainOpen, setFloodplainOpen] = useState(false);
    const [streamOpen, setStreamOpen] = useState(false);
    const [protectionOpen, setProtectionOpen] = useState(false);
    const [watershedOpen, setWatershedOpen] = useState(false);
    const [aggregationOpen, setAggregationOpen] = useState(false);

    const [checkedGeography, setCheckedGeography] = useState([]);
    const [checkedFloodplain, setCheckedFloodplain] = useState([]);
    const [checkedStream, setCheckedStream] = useState([]);
    const [checkedProtectionRank, setCheckedProtectionRank] = useState([]);
    const [checkedWatershed, setCheckedWatershed] = useState([]);
    const [checkedAggScale, setCheckedAggScale] = useState('');

    const projectSvc = useProjects();
    const appStateSvc = useAppState();
    const regionSvc = useRegions();
    const criteriaSvc = useCriteria();

    const scaleOptions = [
        {value: 'Connectivity Summary Report', label: 'Connectivity Summary Report'},
        {value: 'Connectivity Details Report', label: 'HUC10'},
        {value: 'Project Report', label: 'Project Report'},
        {value: 'Resiliency Report', label: 'Resiliency Report'}
    ];

    const reportOptions = [
        {value: 'Connectivity/Departure Analysis Report', label: 'Connectivity/Departure Analysis Report'},
        {value: 'Project Report', label: 'Project Report'},
        {value: 'Habitat Report', label: 'Habitat Report'}
    ];

    const filterOptions = [
        {value: 'Project Type', label: 'Project Type'},
        {value: 'Function Type', label: 'Function Type'},
        {value: 'Other', label: 'Habitat Report'}
    ];

    // useEffect(() => {
    //     console.log('useEffect firing');
    // }, [selectedScale, reportType]);

    function genConnSummCheckbox(arg){
        return projectSvc.connSummReport.map((val) => {
            return <div className="watershed-checkbox">
                <label>{val}</label><br/>
                <input type="checkbox" value={val}/>
            </div>
        });
    };

    function fetchRegions(){
        return regionSvc.getAllRegions()
        .then(res => {return res.json()})
        .then(resJson => {
            let dataRes = resJson[0].get_regions;
            regionSvc.setRegion(dataRes)
        })
        .catch(e => console.warn('warning', e))
    };

    function fetchFloodplainConn(){
        return regionSvc.get_LatConnFilters()
        .then(res => {return res.json()})
        .then((resJson) => {
            let dataRes = resJson[0].get_latconnfilters;
            dataRes = dataRes.sort((a, b) => {
                if(a.sorder > b.sorder){
                    return 1;
                } else{
                    return -1;
                }
            });
            regionSvc.setFloodplainConn(dataRes);
        })
        .catch((err) => console.log('fetchFloodplainConn err', err));
    };

    function fetchStreamConn(){
        return regionSvc.get_LongConnFilters()
        .then(res => {return res.json()})
        .then((resJson) => {
            let dataRes = resJson[0].get_longconnfilters;
            dataRes = dataRes.sort((a, b) => {
                if(a.sorder > b.sorder){
                    return 1;
                } else{
                    return -1;
                }
            });
            regionSvc.setStreamConn(dataRes);
        })
        .catch((err) => console.log('fetchStreamConn err', err));
    };

    function fetchProtectionRanks(){
        return regionSvc.get_ProtectionFilters()
        .then(res => {return res.json()})
        .then((resJson) => {
            let dataRes = resJson[0].get_protectionfilters;
            dataRes = dataRes.sort((a, b) => {
                if(a.sorder > b.sorder){
                    return 1;
                } else{
                    return -1;
                }
            })
            regionSvc.setProtectionRank(dataRes);
        })
        .catch((err) => console.log('fetchProtectionRanks err', err));
    };

    function fetchWatershedPosition(){
        return regionSvc.get_PositionFilters()
        .then(res => {return res.json()})
        .then((resJson) => {
            let dataRes = resJson[0].get_positionfilters;
            dataRes = dataRes.sort((a, b) => {
                if(a.sorder > b.sorder){
                    return 1;
                } else{
                    return -1;
                }
            });
            regionSvc.setWatershedReporting(dataRes);
        })
        .catch((err) => console.log('fetchWatershedPosition err', err));
    };

    function fetchAggregationScale(){
        return regionSvc.get_AggregationScale()
        .then(res => {return res.json()})
        .then((resJson) => {
            let dataRes = resJson[0].get_aggregationscale;
            dataRes = dataRes.sort((a, b) => {
                if(a.agorder > b.agorder){
                    return 1;
                } else{
                    return -1;
                }
            })
            regionSvc.setAggregationScale(dataRes);
        })
        .catch((err) => console.log('fetchAggregationScale', err))
    }
//Geography; regArr = the local instance of geography check boxes since they're being tracked within each child component
    function generateGeographyCheckboxes(state, func, regArr){
        if(regArr.length){
            return regArr.map((reg) => {
                return (
                    <div>
                        <input id={'reg-' + reg.value} type="checkbox" onChange={() => toggleGeography(reg, state, func)} checked={lookUpCheckedRegion(reg, state)}/>
                        <label for={'reg-' +reg.value}>{reg.value}</label>
                    </div>
                )   
            })
        }
    }
//Floodplain (Lateral-Vertical) Connectivity section
    function generateFloodplainCheckboxes(state, func){
        if(regionSvc.floodplainConn.length){
            return regionSvc.floodplainConn.map((fp,idx) => {
                return (
                    <>
                        <div>
                            <input id={'fp-' + fp.latconn} type="checkbox" onChange={() =>toggleCheckFloodplain(fp.latconn, state, func)} checked={state.indexOf(fp.latconn) > -1}/>
                            <label for={'fp-' + fp.latconn}>{fp.latconn}</label>
                        </div>
                    </>
                )
            })
        }
    };
//Stream (Longitidinal-Temporal) Connectivity
    function generateStreamCheckboxes(state, func){
        if(regionSvc.streamConn.length){
            return regionSvc.streamConn.map((stm) => {
                return (
                    <>
                        <div>
                            <input id={'stm-' + stm.longconnectivity} type="checkbox" onChange={() => toggleCheckedStreams(stm.longconnectivity, state, func)} checked={state.indexOf(stm.longconnectivity) > -1}/>
                            <label for={'stm-' +stm.longconnectivity}>{stm.longconnectivity}</label>
                        </div>
                    </>
                )
            })
        }
    };
//Protection Rank
    function generateProtection(state, func){
        if(regionSvc.protectionRank.length){
            return regionSvc.protectionRank.map((pro) => {
                return (
                    <>
                        <div>
                            <input id={'pro-' + pro.protectionrnk} type="checkbox" onChange={() => toggleCheckedProtectionRank(pro.protectionrnk, state, func)} checked={state.indexOf(pro.protectionrnk) > -1}/>
                            <label for={'pro-' + pro.protectionrnk}>{pro.protectionrnk}</label>
                        </div>
                    </>
                )
            })
        }
    };
//Watershed Position
    function generateResiliency(state, func){
        if(regionSvc.watershedReporting.length){
            return regionSvc.watershedReporting.map((water) => {
                return (
                    <>
                        <div>
                            <input id={'water' + water.position} type="checkbox" onChange={() => toggleCheckedWatershed(water.position, state, func)} checked={state.indexOf(water.position) > -1}/>
                            <label for={'water' + water.position}>{water.position}</label>
                        </div>
                    </>
                )
            })
        }
    }
//Geography
    function generateAggregationCheckboxes(state, func){
        if(regionSvc.aggregationScale.length){
            return regionSvc.aggregationScale.map((agg) => {
                return (
                    <>
                        <div>
                            <input id={'agg' + agg.scale} type="checkbox" onChange={() => toggleAggregationScale(agg.scale, state, func)} checked={state === agg.scale}/>
                            <label for={'agg' + agg.scale}>{agg.scale}</label>
                        </div>
                    </>
                )
            })
        }
    }

    function lookUpCheckedRegion(val, hook){
        for(let i = 0; i < hook.length; i++){
            if(hook[i] === val){
                return true
            }
        }
        return false
    }

    function toggleGeography(val, hook, func){
        let updatedChecked = [...hook]
        if(hook.indexOf(val) == -1){
            updatedChecked = [...hook, val];
        }else{
            updatedChecked.splice(hook.indexOf(val), 1)
        }
        return func(updatedChecked);
    }

    function toggleCheckFloodplain(val, hook, func){
        let updatedChecked = [...hook];
        if(hook.indexOf(val) == -1){
            updatedChecked = [...hook, val]
        }else{
            updatedChecked.splice(hook.indexOf(val), 1)
        }
        return func(updatedChecked);
    }

    function toggleCheckedStreams(val, hook, func){
        let updatedCheck = [...hook];
        if(hook.indexOf(val) == -1){
            updatedCheck = [...hook, val];
        }else{
            updatedCheck.splice(hook.indexOf(val), 1);
        }
        return func(updatedCheck);
    };

    function toggleCheckedProtectionRank(val, hook, func){
        let updatedCheck = [...hook];
        if(hook.indexOf(val) == -1){
            updatedCheck = [...hook, val];
        }else{
            updatedCheck.splice(hook.indexOf(val), 1);
        }
        return func(updatedCheck);
    };

    function toggleCheckedWatershed(val, hook, func){
        let updatedCheck = [...hook];
        if(hook.indexOf(val) == -1){
            updatedCheck = [...hook, val];
        }else{
            updatedCheck.splice(hook.indexOf(val), 1);
        }
        return func(updatedCheck);
    };
    //hook = state var, func = useState call
    function toggleAggregationScale(val, hook, func){
        if(hook == val){
            func('');
        }else{
            func(val);
        };
    }

    useEffect(() => {
        criteriaSvc.setSelectedScale('');
        regionSvc.setFilteredRegion([]);
        fetchRegions();
        fetchFloodplainConn();
        fetchStreamConn();
        fetchProtectionRanks();
        fetchWatershedPosition();
        fetchAggregationScale();
        // callingAlert(alertCall);
    }, []);

    useEffect(() => {
        let fullScale = [
            { value: 'BASINS', label: 'Watershed Planning Basins' },
            { value: 'HUC10', label: 'HUC10' },
            { value: 'HUC12', label: 'HUC12'},
            { value: 'TOWNS', label: 'By Town' },
            { value: 'COUNTIES', label: 'By County' }
        ];
        let filteredScale;
        if(checkedAggScale == 'Basin'){
            filteredScale = fullScale.filter((item) => item.value === 'BASINS');
        } else if (checkedAggScale == 'Huc10'){
            filteredScale = fullScale.filter((item) => item.value === 'BASINS' || item.value === 'HUC10');
        } else if (checkedAggScale == 'Huc12'){
            filteredScale = fullScale.filter((item) => item.value !== 'COUNTIES' && item.value !== 'TOWNS');
        } else if (checkedAggScale == 'County'){
            filteredScale = fullScale.filter((item) => item.value !== 'TOWNS');
        } else {
            filteredScale = fullScale;
        }
        setScaleLevels(filteredScale);
    }, [checkedAggScale]);

    // function setTheScale(val){
    //     console.log('val', val);
    //     setSelScale(val.value);
        
    //     localRegions = filteredRegion.filter((str) => str.scale === selScale);
    //     setFilteredRegion(localRegions);
    // }

    function scaleSelected(val){
        setCheckedGeography([]);
        
        if(!val || val == ''){
            regionSvc.setToggleRegions(false);
            criteriaSvc.setToggleCriteria(false);
            criteriaSvc.setSelectedScale('');
            regionSvc.setSelectedRegion('');
            criteriaSvc.setCriteriaList({});
            return null;
        }
        regionSvc.setSelectedRegion(''); // ARH
        regionSvc.setSelectedRegionName(''); // ARH
        regionSvc.setToggleRegions(true);
        criteriaSvc.setSelectedScale(val.value);
        sortRegions(val.value);
    }

    function sortRegions(val){
        console.log("val", val)
        let catcher = [];
        if(regionSvc.region.length && val){
            for(let i = 0; i < regionSvc.region.length; i++){
                if(val === regionSvc.region[i].scale.toUpperCase()){
                    catcher.push({
                        value: regionSvc.region[i].region,
                        label: regionSvc.region[i].region,
                        regionid: regionSvc.region[i].regionid,
                        scale: regionSvc.region[i].scale
                    });
                }
            }
            regionSvc.setFilteredRegion(catcher)
        };
    };

    // function alertCall(){
    //     return alert('good morning dave');
    // }

    // function callingAlert(func){
    //     return func();
    // }

    return(
        <div className="watershed-reporting">
            <div className="watershed-reporting-content">
                <div className="watershed-reporting-export-container">
                    <div className="watershed-reporting-menu">
                        <p>Connectivity Summary Report</p>
                        <button className="watershed-icon" aria-label="Connectivity Details Report" onClick={() => setShowOne(!showOne)}>{showOne ? <AiOutlineMinus/> : <AiOutlinePlus/>}</button>
                    </div>
                </div>
                {/* <div className="watershed-checkbox-container" style={showOne ? null : 'display: hidden'}> */}
                <div className={showOne ? "watershed-checkbox-container" : "hide"}>
                    <ConnSummReport
                        generateAggregationCheckboxes={generateAggregationCheckboxes}
                        generateGeographyCheckboxes={generateGeographyCheckboxes}
                        generateFloodplainCheckboxes={generateFloodplainCheckboxes}
                        generateStreamCheckboxes={generateStreamCheckboxes}
                        generateProtection={generateProtection}
                        generateResiliency={generateResiliency}
                        scaleLevels={scaleLevels}
                        lookUpCheckedRegion={lookUpCheckedRegion}
                        scaleSelected={scaleSelected}
                    />
                </div>
                <div className="watershed-reporting-export-container">
                    <div className="watershed-reporting-menu">
                        <p>Connectivity Details Report</p>
                        <button className="watershed-icon" aria-label="Connectivity Details Report" onClick={() => setShowTwo(!showTwo)}>{showTwo ? <AiOutlineMinus/> : <AiOutlinePlus/>}</button>
                    </div>
                </div>
                <div className={showTwo ? "watershed-checkbox-container" : "hide"}>
                        {/* {genConnSummCheckbox('Two')}
                        <button className="gen-watershed-report" onClick={() => projectSvc.exportCSVTemplate()}>Generate Report</button> */}
                        <ConnDetailsReport
                            generateAggregationCheckboxes={generateAggregationCheckboxes}
                            generateGeographyCheckboxes={generateGeographyCheckboxes}
                            generateFloodplainCheckboxes={generateFloodplainCheckboxes}
                            generateStreamCheckboxes={generateStreamCheckboxes}
                            generateProtection={generateProtection}
                            generateResiliency={generateResiliency}
                            scaleLevels={scaleLevels}
                            lookUpCheckedRegion={lookUpCheckedRegion}
                            scaleSelected={scaleSelected}
                        />
                </div>
                <div className="watershed-reporting-export-container">
                    <div className="watershed-reporting-menu">
                        <p>Project Report</p>
                        <button className="watershed-icon" aria-label="Project Report" onClick={() => setShowThree(!showThree)}>{showThree ? <AiOutlineMinus/> : <AiOutlinePlus/>}</button>
                    </div>
                </div>
                <div className={showThree ? "watershed-checkbox-container" : "hide"}>
                    {/* {genConnSummCheckbox('Three')}
                    <button className="gen-watershed-report" onClick={() => projectSvc.exportCSVTemplate()}>Generate Report</button> */}
                    <ProjReport
                        generateAggregationCheckboxes={generateAggregationCheckboxes}
                        generateGeographyCheckboxes={generateGeographyCheckboxes}
                        generateFloodplainCheckboxes={generateFloodplainCheckboxes}
                        generateStreamCheckboxes={generateStreamCheckboxes}
                        generateProtection={generateProtection}
                        generateResiliency={generateResiliency}
                        scaleLevels={scaleLevels}
                        lookUpCheckedRegion={lookUpCheckedRegion}
                        scaleSelected={scaleSelected}
                    />
                </div>
                <div className="watershed-reporting-export-container">
                    <div className="watershed-reporting-menu">
                        <p>Resiliency Report</p>
                        <button className="watershed-icon" aria-label="Resiliency Report" onClick={() => setShowFour(!showFour)}>{showFour ? <AiOutlineMinus/> : <AiOutlinePlus/>}</button>
                    </div>
                </div>
                <div className={showFour ? "watershed-checkbox-container" : "hide"}>
                    {/* {genConnSummCheckbox('Four')} */}
                    <ResilReport
                        generateAggregationCheckboxes={generateAggregationCheckboxes}
                        generateGeographyCheckboxes={generateGeographyCheckboxes}
                        generateFloodplainCheckboxes={generateFloodplainCheckboxes}
                        generateStreamCheckboxes={generateStreamCheckboxes}
                        generateProtection={generateProtection}
                        generateResiliency={generateResiliency}
                        scaleLevels={scaleLevels}
                        lookUpCheckedRegion={lookUpCheckedRegion}
                        scaleSelected={scaleSelected}
                    />
                    {/* <button className="gen-watershed-report" aria-label="generate report" onClick={() => projectSvc.exportCSVTemplate()}>Generate Report</button> */}
                </div>
            </div>
        </div>
    );
};