import React from 'react';
import { useAppState } from '../../services/App_State_Service';
import './AlertModal.scss';

export default function AlertModal(){
    const appStateSvc = useAppState();

    function closeModal(){
        appStateSvc.setMakeAlert(false);
        appStateSvc.setAlertText('');
    }

    return(
        <div className="modal-container">
            <div className="inner-modal-container">
                <h1>{appStateSvc.alertText}</h1>
                <div className="modal-button-container">
                    <button onClick={() => closeModal()}>Close</button>
                </div>
            </div>
            
        </div>
    )
};