import React from 'react';
import vermont_logo from '../../photos/vermont-logo.png';
import './Header.css';
import config from '../../config';


export default function Header(){
    return(
        <div className="header">
            <div className="title">
                <span id="FFI">FF<span id="FFI-span">I</span></span>
                <span>Functioning Floodplain Initiative</span>
            </div>
            {window.location.hostname === "ffi.stone-env.net" ? null : <div className="versionInfo">
                Version {config.VERSION_INFO} -- {config.COMMIT_INFO}
            </div>}
            <div className="logo-vermont">
                <img src={vermont_logo} alt="vermont logo"/>
            </div>
        </div>
    )
}
