import React, {
    createRef,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import SplitPaneContext from "./SliderContext";
import { useAppState } from "../../services/App_State_Service";
import './SplitPane.scss';

const SplitPane = ({children, ...props}) =>{
    const yDividerPos = useRef(null);
	const thisNodeRef = useRef();
	const appStateSvc = useAppState();
    // const [clientHeight, setClientHeight] = useState('100vh' - 50); 

    const onMouseHoldDown = (e) => {
		// console.log('mouse down')
		if(appStateSvc.currentSliderHeight == window.innerHeight - 70 && appStateSvc.showTable == false){
			return null;
		}
		document.getElementById('root').style.userSelect = 'none';
		yDividerPos.current = e.clientY;
	};
	
	const onMouseHoldUp = (e) => {
		// console.log('mouse up')
		yDividerPos.current = null;
		appStateSvc.sliderHeightChecker()
		// appStateSvc.setCurrentSliderHeight(appStateSvc.currentSliderHeight + e.clientY - yDividerPos.current);
	};

	const onMouseHoldMove = (e) => {
		// console.log('mouse hold')
		if(!yDividerPos.current){
			return;
		};
		if (appStateSvc.currentSliderHeight + e.clientY - yDividerPos.current < 100) {
			return;
		}
		if (appStateSvc.currentSliderHeight + e.clientY - yDividerPos.current > (thisNodeRef.current.clientHeight - 100)) {
			return;
		}
		appStateSvc.setCurrentSliderHeight(appStateSvc.currentSliderHeight + e.clientY - yDividerPos.current);
		yDividerPos.current = e.clientY;
		// console.log('clientHeight', clientHeight);

	};

    useEffect(() => {
		// console.log('inner height test', window.innerHeight);
		document.addEventListener("mouseup", onMouseHoldUp);
		document.addEventListener("mousemove", onMouseHoldMove);
		
		return () => {
			document.removeEventListener("mouseup", onMouseHoldUp);
			document.removeEventListener("mousemove", onMouseHoldMove);
		};
	});

	useEffect(() => {
		// console.log('está haciendo algo');
		appStateSvc.sliderHeightChecker();
	}, [appStateSvc.currentSliderHeight, window.innerHeight]);

    return (
		<div ref={thisNodeRef} {...props}>
			<SplitPaneContext.Provider
				value={{
					// clientHeight,
					// setClientHeight,
					onMouseHoldDown,
				}}
			>
				{children}
			</SplitPaneContext.Provider>
		</div>
	);
};

export const Divider = (props) => {
    const { onMouseHoldDown } = useContext(SplitPaneContext);
	return <div onMouseDown={onMouseHoldDown} className="split-pane-divider" id="split-pane-divider"/>
};

export const SplitPaneTop = (props) => {
    const topRef = createRef();
	const appStateSvc = useAppState();
    // const {clientHeight, setClientHeight} = useContext(SplitPaneContext);

    useEffect(() => {
		if (!appStateSvc.currentSliderHeight) {
			appStateSvc.setCurrentSliderHeight(topRef.current.clientHeight / 2);
			return;
		}
		topRef.current.style.minHeight = appStateSvc.currentSliderHeight + "px";
		topRef.current.style.maxHeight = appStateSvc.currentSliderHeight + "px";
	}, [appStateSvc.currentSliderHeight]);

	useEffect(() => {
		// console.log('use effect firing')
	}, [appStateSvc.resetSliderHeight, appStateSvc.currentSliderHeight]);

    return (
		<div className="split-pane-top" ref={topRef}>
			{props.children}
		</div>
	);
};

export const SplitPaneBottom = (props) => {

	function enableScrolling(){
		let outerContainer = document.getElementById('slider-container');
		let splitPane = document.getElementById('split-pane-row');

		outerContainer.style.pointerEvents = 'all';
		splitPane.style.pointerEvents = 'all';
		return;
	}

	function disableScrolling(){
		let outerContainer = document.getElementById('slider-container');
		let splitPane = document.getElementById('split-pane-row');

		outerContainer.style.pointerEvents = 'none';
		splitPane.style.pointerEvents = 'none';
		return;
	}

	return(
		<div className="split-pane-bottom" onMouseOver={() => enableScrolling()} onMouseOut={() => disableScrolling()}>
			{props.children}
		</div>
	)
};

export default SplitPane;
