import React, {useState, useEffect} from 'react';
import { useAppState } from '../../services/App_State_Service';
import { useCriteria } from '../../services/Criteria_Service';
import { useProjects } from '../../services/Projects_Service';
import './SummarySection.scss';

export default function SummarySection(props){
    const appStateSvc = useAppState();
    const criteriaSvc = useCriteria();
    const projectsSvc = useProjects();

    function genSubUnitIds(){
        if(appStateSvc.subUnitIds.length){
            return appStateSvc.subUnitIds.map((i, idx) => {
                if(idx + 1 == appStateSvc.subUnitIds.length){
                    return ' ' + i
                }
                return ' ' + i + ', '
            })
        }else{
            return '-';
        }
    };

    function genProjectLabels(){
        if(!appStateSvc.uniqueProjects.length){
            return '-'
        }
        return appStateSvc.uniqueProjects.map((i, idx) => {
            if(idx + 1 == appStateSvc.uniqueProjects.length){
                return ' ' + i
            }
            return ' ' + i + ', '
        })
    };

    function genTownLabels(){
        // console.log('uniqueTowns',uniqueTowns);
        if(!appStateSvc.uniqueTowns.length){
            return '-'
        }
        return appStateSvc.uniqueTowns.map((i, idx) => {
            if(idx + 1 == appStateSvc.uniqueTowns.length){
                return ' ' + i 
            }
            return ' ' + i + ', '
        })
    };

    function getStreamNameLabels(){
        if(appStateSvc.uniqueStreamNames === '-'){
            return '-';
        }
        return appStateSvc.uniqueStreamNames.map((i, idx) => {
            if(idx + 1 == appStateSvc.uniqueStreamNames.length){
                return ' ' + i
            }else{
                return ' ' + i + ', '
            }
        })
    };

    function genProjectArea(){
        console.log('projectsSvc.riverStabilityRes', projectsSvc.riverStabilityRes);
        if(projectsSvc.riverStabilityRes.length){
            let total = 0;
            for(let i = 0; i < appStateSvc.riverRowObj.length; i++){
                let unconsac = Object.values(appStateSvc.riverRowObj[i])[0].unconsac ? Object.values(appStateSvc.riverRowObj[i])[0].unconsac : 0;
                let areavertchange = Object.values(appStateSvc.riverRowObj[i])[0].areavertchange ? Object.values(appStateSvc.riverRowObj[i])[0].areavertchange : 0;
                let prop_proj_area = Object.values(appStateSvc.storageRowObj[i])[0].prop_proj_area ? Object.values(appStateSvc.storageRowObj[i])[0].prop_proj_area : 0;
                let max = Math.max(unconsac, areavertchange, prop_proj_area);
                // console.log('project area values check', max, unconsac, areavertchange, prop_proj_area);
                total = total + max;
            }
            appStateSvc.setProjectArea(total);
            return total;
        }else{
            if(appStateSvc.streamRowObj.length){
                let target = appStateSvc.streamRowObj[0]
                console.log('we got a live one')
                if(target.areavertchange){
                    return target.areavertchange
                }else{
                    return 0
                }
            }else{
                console.log('we got a 0')
                return 0
            }
        }
    };

    function filterSubUnitIds(){
        let projArr = [];
        console.log('length check', appStateSvc.checkedRivers, appStateSvc.checkedStreams)
        for(let i = 0 ; i < appStateSvc.checkedRivers.length; i++){
            // console.log('appStateSvc', appStateSvc.checkedRivers[i]);
            projArr.push(appStateSvc.checkedRivers[i].ffi_id);
        }
        if(appStateSvc.checkedStreams.length){
            projArr.push(appStateSvc.checkedStreams[0].ffi_id);
        }

        // console.log('projArr',projArr);
        appStateSvc.setSubUnitIds(projArr);
    };

    function filterRiverProjects(){
        let localArr = [];
        for(let i = 0; i < criteriaSvc.riverProjectTypes.length; i++){
            // console.log('criteriaSvc.riverProjectTypes[i]', criteriaSvc.riverProjectTypes[i]);
            if(criteriaSvc.riverProjectTypes[i].checked == true){
                localArr.push(criteriaSvc.riverProjectTypes[i].project);
            }
        }
        for(let i = 0; i < appStateSvc.streamProjects.length; i++){
            // console.log('appStateSvc.streamProjects[i]', appStateSvc.streamProjects[i]);
            if(appStateSvc.streamProjects[i].checked == true){
                localArr.push(appStateSvc.streamProjects[i].project)
            }
        }
        // console.log('localArr', localArr);
        return appStateSvc.setUniqueProjects(localArr);
    };

    function filterTowns(){
        let townsArr = [];
        //getting towns from streams
        if(appStateSvc.checkedStreams.length && appStateSvc.checkedStreams[0].townname){
            townsArr.push(appStateSvc.checkedStreams[0].townname);
        }
        //getting towns from rivers
        for(let i = 0; i < appStateSvc.checkedRivers.length; i++){
            // console.log('appStateSvc.checkedRivers[i]', appStateSvc.checkedRivers[i]);
            let town = appStateSvc.checkedRivers[i].townname;
            if(townsArr.indexOf(town) == -1){
                townsArr.push(town);
            }
        }
        appStateSvc.setUniqueTowns(townsArr);
    }

    function filterStreams(){
        // console.log('checked items', appStateSvc.checkedStreams, appStateSvc.checkedRivers);
        let streamNames = [];
        if(appStateSvc.checkedStreams.length && appStateSvc.checkedStreams[0].stream_name && appStateSvc.checkedStreams[0].stream_name !== ' '){
            streamNames.push(appStateSvc.checkedStreams[0].stream_name);
        }
        for(let i = 0; i < appStateSvc.checkedRivers.length; i++){
            for(let k = 0; k < appStateSvc.checkedRivers[i].st_data.length; k++){
                // console.log('appStateSvc.checkedRivers[i].st_data[k].stream_name', appStateSvc.checkedRivers[i].st_data[k]);
                if(appStateSvc.checkedRivers[i].st_data[k] !== null){
                    if(streamNames.indexOf(appStateSvc.checkedRivers[i].st_data[k].stream_name) == -1 && appStateSvc.checkedRivers[i].st_data[k].stream_name !== ' '){
                        streamNames.push(appStateSvc.checkedRivers[i].st_data[k].stream_name);
                    }
                }
            }
        }
        if(!streamNames.length){
            streamNames = '-';
        }
        // console.log('streamNames', streamNames);
        appStateSvc.setUniqueStreamNames(streamNames);
    }

    useEffect(() => {
        filterSubUnitIds();
        filterRiverProjects();
        filterTowns();
        filterStreams();
    }, [appStateSvc.checkedStreams, appStateSvc.checkedRivers, criteriaSvc.riverProjectTypes]);

    return(
        <div className="resiliency-container">
            {appStateSvc.tableSelected == 'water-quality-benefit' ? <h2 className="resiliency-header-main">Estimated Phosphorus Credit for Stream Stability and Storage</h2> : null}
            {appStateSvc.tableSelected == 'resiliency' ? <h2 className="resiliency-header-main">Predicted Floodplain Resiliency Benefit</h2> : null}
            <div className="sub-unit-section">
                <div>
                    <p className="sub-unit-header">SubUnit(s) IDs:</p>
                    <p className="sub-unit-text">{genSubUnitIds()}</p>
                </div>
                <div>
                    <p className="sub-unit-header">Town:</p>
                    <p className="sub-unit-text">{genTownLabels()}</p>
                </div>
                <div>
                    <p className="sub-unit-header">Projects Included:</p>
                    <p className="sub-unit-text">{genProjectLabels()}</p>
                </div>
                <div>
                    <p className="sub-unit-header">Stream Names: </p>
                    <p className="sub-unit-text">{getStreamNameLabels()}</p>
                </div>
                <div>
                    <p className="sub-unit-header">Project Area (acres):</p>
                    <p className="sub-unit-text">{genProjectArea()}</p>
                </div>
            </div>
        </div>
    )
}
