import React from 'react';
import './SubmitProjectInfo.css';


export default function SubmitProjectInfo(){
    return(
        <div className="submit-project">
            <div className="submit-project-content">
                Submit Project Info Goes Here
            </div>
        </div>
    )
}