import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import MapView from "@arcgis/core/views/MapView";
import WebMap from '@arcgis/core/WebMap';
import FeatureFilter from '@arcgis/core/layers/support/FeatureFilter';
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";
import Extent from "@arcgis/core/geometry/Extent";
//import Feature from "@arcgis/core/widgets/Feature";
import Measurement from "@arcgis/core/widgets/Measurement";
//import CustomContent from '@arcgis/core/popup/content/CustomContent';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Point from "@arcgis/core/geometry/Point";
import ActionButton from "@arcgis/core/support/actions/ActionButton";
import { useProjects } from '../../services/Projects_Service';
import { useRegions } from '../../services/Region_Service';
import { useAppState } from '../../services/App_State_Service';
//import {AppStateProvider} from '../../services/App_State_Service';
import LocalPopupItem from './LocalPopupItem';
import config from '../../config';
import './Map.css';
//import { rgbToHex } from '@material-ui/core';
//import { CriteriaProvider } from '../../services/Criteria_Service';

export default function Map(props) {
  const [theMapView, setMapView] = useState(null);
  const [mapHomeExtent, setMapHomeExtent] = useState(null);
  const [highlight, setHighlight] = useState([]);
  const [showConnectivity, setShowConnectivity] = useState(true);
  const [localStateTest, setLocalStateTest] = useState(null);
  const [testing, setTesting] = useState(null);
  const [mapDivStyle, setMapDivStyle] = useState(
    {
      opacity: '100%'
    }
  );
  const mapDiv = useRef(null);
  const projectsSvc = useProjects();
  const regionSvc = useRegions();
  const appStateSvc = useAppState();
  const [layers, setLayers] = useState(null);
  const [isMeasuring, setIsMeasuring] = useState(false);
  const [theMeasure, setTheMeasure] = useState(null);
  const [wqGraphicsLayer,setWQGraphicsLayer] = useState(null);

  // let isMeas = false;
  useEffect( () => {
    const map = new WebMap({
      portalItem: {id: '5cf8d46eeaab4b37a88972b05f7aabb5'}
      //portalItem: {id: '46542ed589fc49acb2288e407bbc42ca'}
    })
    const mapView = new MapView({
      container: mapDiv.current,
      map: map,
      highlightOptions: {
        color: [255, 251, 10],
        haloOpacity: 0.9,
        fillOpacity: 0.2
      }
    });

    const measurement = new Measurement({
      view: mapView,
      linearUnit: "imperial",
      areaUnit: "acres"
    });

    setTheMeasure(measurement)
    // Disabale popups immediatley to not interfere with "Feature Widget"
    mapView.allLayerViews.on("change", function(event) {
      mapView.allLayerViews.forEach(lyr => {
        if (lyr.layer.title == config.riverName || lyr.layer.title == config.streamName) {
          lyr.layer.popupEnabled = false;
        }
      });
      if (event.added) {
        setLayers(mapView.allLayerViews);
      }
    });

    setMapView(mapView);

    mapView.when().then(
      () => {
        console.log("[Map Initialized]");

        appStateSvc.setMapView(mapView);
        setMapHomeExtent(mapView.extent);
        if (mapView) {
          const basemapTog = new BasemapToggle({
              view: mapView,
              nextBasemap: "hybrid"
          });
          mapView.ui.add(basemapTog, "top-right");

          mapView.on("pointer-down", function(event) {
              mapClickHandler(event, mapView);
            }
          );
        }
        loadView(mapView);
      }
    );
    return () => mapView && mapView.container == null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function mapClickHandler(event, mapView) {
    if(isMeasuring){
      return;
    }
    if (mapView.layerViews){
      // add names to config file
      const studySitesLayer = mapView.allLayerViews.find((layerView) => {
          if(layerView.layer.title === config.streamName){
            // Do nothing
          }
          return layerView.layer.title === config.riverName
        }
      )

      let popupWidgetTarget = document.getElementById("popup-widget-target");
      if(popupWidgetTarget){
        ReactDOM.unmountComponentAtNode(document.getElementById("popup-widget-target"))
        document.getElementById("popup-widget-target").innerHTML = "";
      }
      mapView.hitTest(event).then((response) => {
          if (response.results.length) {
            // BOLD ASSUMPTION; CHECK TITLE YO
            //USE SIMILAR LOGIC TO LINE 80ISH TO CHECK THAT YOU'RE GETTING THE RIGHT LAYER
            // HIGHLIGHTING ISSUE IS LIKELY CAUSED HERE AS WELL
            //STEP 1.) CODIFY WHERE THE RIVER LAYER IS
            let layerTitle;
            let allLayersAndSublayers = mapView.layerViews.flatten(
              function(item) {
                return item.layerViews || item.sublayers;
              }
            );
            // Test if we "hit" a graphic or item in the River layer
            if(response.results[0].graphic.layer.title == config.riverName || response.results[0].graphic.layer.title == config.streamName) {
              layerTitle = response.results[0].graphic.layer.title
              if(layerTitle == config.riverName){
                appStateSvc.setPopupType('river');
              }else if(layerTitle == config.streamName){
                appStateSvc.setPopupType('stream');
              }

              let selectedLayer = response.results[0].graphic.layer;
              let center = response.results[0].graphic.geometry.centroid;
              let highlightCollection = highlight;
              console.log('highlightCollection.length', highlightCollection.length)
              if(highlightCollection.length > 0){
                highlightCollection.forEach((item) => {
                    item.remove();
                  }
                );
                // highlightCollection = [];
                // console.log('highlightCollection post remove', highlightCollection);
              }
              let selLayer = allLayersAndSublayers.find((layerView) => {
                  return layerView.layer.title === layerTitle;
                }
              );
              highlightCollection.push(selLayer.highlight(response.results[0].graphic.attributes.objectid));
              setHighlight(highlightCollection);
              // MAKE A SIMILAR-ISH QUERY TO ACCESS ALL THE STUFF YOU NEED FOR THE POPUP
              // EVERYTHING SHOULD BE MORE OR LESS THE SAME AFTER YOU GET THE LAYER
              let layerQuery = selectedLayer.createQuery();
              layerQuery.where = "objectid = " + response.results[0].graphic.attributes.objectid;
              layerQuery.outFields = "*";
              let htmlString = response.results[0].graphic.layer.popupTemplate.content[0].text;
              // console.log('htmlString check', htmlString);
              selectedLayer.queryFeatures(layerQuery).then((res) => {
                  // console.log('query res', res);
                  let idToFetch;
                  if(layerTitle == config.streamName) {
                    idToFetch = res.features[0].attributes.dissid2021;
                    projectsSvc.fetchPopupProjectList_Stream(idToFetch).then((resJson) => {
                        return resJson.json()
                      }
                    )
                    .then((theRes) => {

                        let projects = theRes[0].get_projectlistbystream;

                        if(projects[0] !== null){
                          console.log('handleStreams', projects);
                          handleStreamProjects(projects, idToFetch);
                        }

                        if(projects[0].project == null){
                          appStateSvc.setHideProjScreeningBtn(true)
                        }
                        else{
                          appStateSvc.setHideProjScreeningBtn(false)
                        }

                        let newContainer = document.getElementById("popup-widget-target");

                        appStateSvc.setSelectedMapId(res.features[0].attributes.dissid2021);
                        console.log('can we capture the thing', res.features[0].attributes, appStateSvc.selectedMapId, res.features[0].attributes.dissid2021);
                        ReactDOM.render(<LocalPopupItem popupAttributes={res.features[0].attributes} htmlStr = {htmlString} layerTitle={layerTitle} projects={projects} setHideProjScreeningBtn = {props.setHideProjScreeningBtn}/>, newContainer)
                      }
                    )
                    .catch((err) => {
                        console.log('err', err);
                      }
                    );
                  }
                  else if (layerTitle == config.riverName){
                    idToFetch = res.features[0].attributes.ffi_id;
                    projectsSvc.fetchPopupProjectList_River(idToFetch).then((resJson) => {
                        return resJson.json()
                      }
                    )
                    .then((theRes) => {
                        let projects = theRes[0].get_projectlistbyriver;
                        if(projects == null){
                          appStateSvc.setHideProjScreeningBtn(true)
                        }
                        else{
                          appStateSvc.setHideProjScreeningBtn(false)
                        }
                        let newContainer = document.getElementById("popup-widget-target");
                        appStateSvc.setSelectedMapId(res.features[0].attributes.ffi_id);
                        console.log('can we capture the thing', res.features[0].attributes, appStateSvc.selectedMapId, res.features[0].attributes.ffi_id);
                        ReactDOM.render(<LocalPopupItem popupAttributes={res.features[0].attributes} htmlStr = {htmlString} layerTitle={layerTitle} projects={projects} setHideProjScreeningBtn = {props.setHideProjScreeningBtn}/>, newContainer)
                      }
                    )
                    .catch((err) => {
                        console.log('err', err);
                      }
                    );
                  }
                }
              )
              .catch((err) => console.log('queryError', err))
            }
            else {
                let highlightCollection = highlight;
                if(highlightCollection.length > 0){
                  highlightCollection.forEach((item) => {
                      item.remove();
                    }
                  )
                  setHighlight([]);
                }
              appStateSvc.setSelectedMapId(null)
            }

            let graphic = response.results[0].graphic;

            console.log('graphic', graphic)

            const connectivityButton = new ActionButton(
              {
                title: 'Connectivity',
                id: 'connectivity-button'
              }
            );

            const projectScaleButton = new ActionButton(
              {
                title: 'Project Scale',
                id: 'project-scale-button'
              }
            )

            const template = new PopupTemplate(
              {
                overwriteActions: true
              }
            );

            const graphicLayer = new Graphic(
              {
                popupTemplate: template
              }
            );

            mapView.popup.on(
              'trigger-action', (e) => {
                if(e.action.id === 'connectivity-button'){
                  alert('connectivity fired')
                }
                else if(e.action.id === 'project-scale-button'){
                  alert('project-action-fired')
                }
              }
            )

            // we need to attach click events to this somehow.....
            let clickTest = document.getElementsByClassName('popup-header');

            for(let i = 0; i < clickTest.length; i++){
              clickTest[i].addEventListener('click', console.log('good morning, dave'))
            }
          }
        }
      )
    }
  }

  function handleStreamProjects(arr){
    console.log('arr', arr);
    let localArr = [];
    for(let i = 0; i < arr.length; i++){
      if(appStateSvc.streamProjects.indexOf(arr[i].project) < 0){
        localArr.push({
          'project': arr[i].project,
          'checked': false,
          'projectid': arr[i].projectid
        });
      }
    };
    appStateSvc.setStreamProjects(localArr);
    // console.log('final test', appStateSvc.streamProjects);
  };

  function handleStreams(){
    let streamString = "dissid2021 = '" + projectsSvc.selectedProjects + "'";
    let streamFilter = new FeatureFilter({
      where: streamString
    });

    let finalExtent = null;
    let streamExtent = null;
    let streamLayer = null;
    let streamQuery = null;

    if(!theMapView){
      return null;
    }

    let allLayersAndSublayers = theMapView.layerViews.flatten(function(item){
      return item.layerViews || item.sublayers;
    });

    if (streamString != "") {
      try {
        streamLayer = allLayersAndSublayers.find((layerView) => {
          return layerView.layer.title === config.streamName
        });
        let myHighlight = highlight;
        // console.log('streamLayer', streamLayer);
        // console.log('streamQuery')
        //streamLayer.filter = streamFilter;
        streamQuery = streamFilter.createQuery();
        streamQuery.returnGeometry = true;
        streamQuery.outSpatialReference = theMapView.spatialReference;
        streamQuery.where = streamString;
        streamQuery.outFields = ['objectid'];
        // console.log('streamLayer', streamLayer);
        // console.log('streamQuery', streamQuery);
        streamLayer.layer.queryFeatures(streamQuery).then((res) => {
          // console.log('streamLayer', streamLayer);
          if(res && res.features.length){
            // highlight = streamLayer.highlight(res.features[0]);
            // console.log('highlight', highlightTest)
            if(myHighlight.length){
              // highlight.remove();
              myHighlight.forEach((i) => {
                i.remove();
              })
              console.log('removing highlight', myHighlight);
            }
            // console.log('res.features[0].attributes.objectid', res.features[0].attributes.objectid);
            // setHighlight(streamLayer.highlight(res.features[0].attributes.objectid));
            myHighlight.push(streamLayer.highlight(res.features[0].attributes.objectid));
            setHighlight(myHighlight);
            // console.log('highlightTest', highlightTest);
          }
          // everything happens here; you need to get the layer view + pass it the first feature in the res from the query;
          // highlight = streamLayer.highlight(res.features[0]);

        });

        streamLayer.layer.queryExtent(streamQuery).then((results) => {
          streamExtent = results.extent;
          // highlightSelect = streamLayer.highlight(streamExtent);
          if(streamExtent){
            finalExtent = streamExtent.clone();
            finalExtent.union(streamExtent);
            // theMapView.goTo(finalExtent);
          }
          else{
            finalExtent = streamExtent;
          };

          if (theMapView){
            // finalExtent = finalExtent.expand(6)
            let yDelta = finalExtent.ymax - finalExtent.ymin;
            finalExtent.ymin = finalExtent.ymin - yDelta;
            theMapView.goTo(finalExtent);
          };
          // return highlight;
        });
      }
      catch (ex) {
        console.log("Error filtering streams");
      };
    }
    else {
      try {
        streamLayer = allLayersAndSublayers.find((layerView) => {
          // return layerView.layer.title === "Stream Connectivity Score"
          return layerView.layer.title === config.streamName
        })
        streamLayer.filter = null;
      }
      catch (ex) {
        console.log("Error filtering streams");
      };
    }
  };

  // draft of river handling function
  function handleRivers(){
    let riverString = "ffi_id = '" + projectsSvc.selectedProjects + "'";
    let riverFilter = new FeatureFilter({
      where: riverString
    });
    let finalExtent = null;
    let riverExtent = null;
    let riverLayer = null;
    let riverQuery = null;


    if (!theMapView){
      return null;
    }
    let allLayersAndSublayers = theMapView.layerViews.flatten(function(item){
      return item.layerViews || item.sublayers;
    });

    // let myHighlight = highlight;

    if (riverString != "") {
      try {
        riverLayer = allLayersAndSublayers.find((layerView) => {
          return layerView.layer.title === config.riverName
        });
        let myHighlight = highlight;
        //riverLayer.filter = riverFilter;
        riverQuery = riverFilter.createQuery();
        riverQuery.returnGeometry = true;
        riverQuery.outSpatialReference = theMapView.spatialReference;
        riverQuery.where = riverString;
        riverQuery.outFields = ['objectid'];
        // console.log('riverQuery', riverQuery);
        riverLayer.layer.queryFeatures(riverQuery).then((res) => {

          console.log('river query res', res);
          // console.log('river query res.features', res.features);
          // console.log('river query res.features.length', res.features.length);
          // console.log('river query res.features[0]', res.features[0]);
          // console.log('river query res.features[0].attributes', res.features[0].attributes);
          // console.log('river query res.features[0].attributes.objectid', res.features[0].attributes.objectid);
          if(res && res.features.length){
            // console.log('highlight',highlightTest);
            /*
            let highlightCollection = highlight;
              if(highlightCollection.length > 0){
                highlightCollection.forEach((item) => {
                    item.remove();
                  }
                )
              }
            */
            if(myHighlight.length){
              // highlight.remove();
              myHighlight.forEach((i) => {
                i.remove();
              })
              console.log('removing highlight', myHighlight);
            }
            console.log('res.features[0].attributes.objectid', res.features[0].attributes.objectid);
            myHighlight.push(riverLayer.highlight(res.features[0].attributes.objectid));
            setHighlight(myHighlight);
            console.log('highlight', myHighlight);
          }
        })

        riverLayer.layer.queryExtent(riverQuery).then((results) => {
          riverExtent = results.extent;
          if (riverExtent) {
            finalExtent = riverExtent.clone();
            finalExtent.union(riverExtent);
          }
          else {
            finalExtent = riverExtent;
          };

          if (theMapView){
            let yDelta = finalExtent.ymax - finalExtent.ymin;
            finalExtent.ymin = finalExtent.ymin - yDelta;
            theMapView.goTo(finalExtent);
            // theMapView.goTo(finalExtent.expand(6));
          }
        })
        .catch((err) => console.log('queryExtent zoom issue', err));

      }
      catch (ex) {
        console.log("Error filtering rivers");
      }
    }
    else {
      try {
        riverLayer = allLayersAndSublayers.find((layerView) => {
          return layerView.layer.title === config.riverName
        });
        riverLayer.filter = null;
      }
      catch (ex) {
        console.log("Error filtering rivers");
      }
    }
  }

  useEffect(() => {
    if (theMapView) loadWQPdata();
    },
    [projectsSvc.allWQProjects]
  );

  function loadWQPdata() {
    let projs = projectsSvc.allWQProjects;

    console.log("map component has WQ projects", projs);

    let projList = [];
    let statusList = [];
    projs.forEach(
      (projItem) => {
        if (statusList.indexOf(projItem.Status) < 0) {
          statusList.push(projItem.Status);
          console.log('STATUS', projItem.Status);
        }
        projList.push(
          new Graphic(
            {
              geometry: new Point(
                {
                  latitude: projItem.Lat,
                  longitude: projItem.Long
                }
              ),
              attributes: {
                WPDID: projItem.WPDID,
                Name: projItem.Name,
                Type: projItem.Type,
                Lat: projItem.Lat,
                Long: projItem.Long,
                Status: projItem.Status,
                url: projItem.url
              }
            }
          )
        );
      }
    );

    let gLayer = new FeatureLayer({
      title: "DEC WQ Projects",
      fields: [
        {name: "WPDID", type:"oid"},
        {name: "Name", type:"string"},
        {name: "Type", type:"string"},
        {name: "Lat", type:"double"},
        {name: "Long", type: "double"},
        {name: "Status", type: "string"},
        {name: "url", type: "string"}
      ],
      objectIdField: "WPDID",
      geometryType: "point",
      spatialReference: {wkid: 4326},
      source: projList,
      popupTemplate: {
        content: "<p>{Name}</p>" +
          "<ul><li>WPDID: {WPDID}</li>" +
          "<li>Type: {Type}</li>" +
          "<li>URL: <a href='{url}' target='_blank'>Click Here</a></li>" +
          "<li>Status: {Status}</li><ul>"
      },
      renderer: {
        type: "unique-value",
        field: "Status",
        defaultSymbol: { type: "simple-marker", color: "yellow", size: "6px" },
        uniqueValueInfos: [
          {
            value: "Completed",
            symbol: {
              type: "simple-marker",
              // o.g. color = #4daf4a
              color: "#0000cc",
              size: "8px"
            }
          },
          {
            value: "Funded",
            symbol: {
              type: "simple-marker",
              // o.g. color = #e41a1c
              //orange color = ff9900
              color: "#ff9900",
              size: "8px"
            }
          },
          {
            value: "Superseded",
            symbol: {
              type: "simple-marker",
              color: "#984ea3",
              size: "8px"
            }
          },
          {
            value: "Pending Closeout",
            symbol: {
              type: "simple-marker",
              color: "#a65628",
              size: "8px"
            }
          },
          {
            value: "Terminated",
            symbol: {
              type: "simple-marker",
              color: "black",
              size: "8px"
            }
          },
          {
            value: "Proposal Submitted",
            symbol: {
              type: "simple-marker",
              color: "#377eb8",
              size: "6px"
            }
          },
          {
            value: "Proposed",
            symbol: {
              type: "simple-marker",
              color: [100,100,100],
              size: "4px",
              outline: {
                width: "0px"
              }
            }
          }
        ]
      }
      // Completed
      // Proposed
      // Funded
      // Superseded
      // Terminated
      // Proposal Submitted
      // Pending Closeout

      // renderer: {
      //   type: "simple",  // autocasts as new SimpleRenderer()
      //   symbol: {
      //     type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
      //     size: 6,
      //     color: "black",
      //     outline: {  // autocasts as new SimpleLineSymbol()
      //       width: 0.5,
      //       color: "white"
      //     }
      //   }
      // }

    });

    theMapView.map.add(gLayer);
  }

  useEffect(() => {
    // console.log('highlight', highlight)
    if(projectsSvc.selectedFeatureType == 'stream'){
      console.log('streams is activating');
      handleStreams();
    }
    else if (projectsSvc.selectedFeatureType == 'rivercorridor'){
      console.log('river is activating');
      handleRivers();
    }
  }, [projectsSvc.selectedProjects])

  useEffect(
    () => {
		  // https://developers.arcgis.com/javascript/latest/api-reference/esri-views-layers-FeatureLayerView.html#filter
      console.log("Region Changed - Updating Map");
      let scaleLayerName = "";  // Each scale is represented by a different layer - this is a string for the name
      let scaleLayer = null;    // The actual layer object
      let regionIdColumn = "";  // Each scale layer we use has a different column name for the IDs we'd match
      let regionQuery = null;   // Used to match features in the layer and select them
      let regionFilter = null;  // Used to filter the features shown in the layer
      let regionFilterString = null; // Input to filter
      let regionExtent = null;  // Envelope of region after query
      let finalExtent = null;   // Overlapping extents maybe combined into this extent
      let scale = null;         // The scale choice the user made in the filter screen
      let region = null;        // The region choice the user made in the filter screen

      scale = regionSvc.selectedRegion.scale; // Assign the user's selection to the the scale var
      region = regionSvc.selectedRegion.region; // ditto w/ region

      if (!scale || !region) return;

      // Each scale option requires us to search for matches in a different scale layer
      // and thusly in a different ID column of the scale layer, so this switch will map
      // the appropriate scale layer name and ID column to the variables based on the user's
      // input selection.
      scaleLayerName = config.scales[scale].layer;
      regionIdColumn = config.scales[scale].idcol;

      if (!scaleLayerName || !regionIdColumn) {
        console.log("Given Scale Layer Has Missing Config Definitions Necessary To Transform Map Based On Selection. Please Update The Config.js File's Scale Data.")
        return;
      }

      regionFilterString = `${regionIdColumn} = '${region.regionid}'`;
      console.log('regionFilterString', regionFilterString)
      regionFilter = new FeatureFilter({
        where: regionFilterString
      });

      // In case of not finding what we seek, use try/catch
      try {
        let allLayersAndSublayers = theMapView.layerViews.flatten(function(item){
          return item.layerViews || item.sublayers;
        });
        scaleLayer = allLayersAndSublayers.find((layerView) => {
          return layerView.layer.title === scaleLayerName;
        });
        console.log('scale layer', scaleLayer, regionFilter);
        if (scaleLayer) {
          scaleLayer.filter = regionFilter;
          regionQuery = regionFilter.createQuery();
          regionQuery.returnGeometry = true;
          regionQuery.outSpatialReference = theMapView.spatialReference;
          scaleLayer.layer.queryExtent(regionQuery).then(
            (results) => {
              regionExtent = results.extent;
              finalExtent = regionExtent;
              console.log('finalExtent', finalExtent);
              var tempExt = new Extent({xmax: -7753920.158512664,xmin: -8451637.352699718,ymax: 5800956.806556443,ymin: 5204747.985932097, spatialReference:{wkid: 102100}});
              console.log('tempExt', tempExt);
              //if (theMapView) theMapView.goTo(tempExt);
              if (theMapView) theMapView.goTo(finalExtent);
            },
            (err) => {
              console.log(`Error querying ${scaleLayerName}: ${err}`);
            }
          );
        } else {
          console.log('ERROR finding', scaleLayerName);
        }
      }
      catch (ex) {
        console.log("Error filtering region");
      };

    },
		[regionSvc.selectedRegion]
	);

  useEffect(() => {
    // console.log('window.location.href change', window.location.pathname);
    console.log('highlight', highlight)
    if(highlight.length){
      // console.log('removing', highlight)
      highlight.forEach((i) => {
        i.remove()
      })
      setHighlight([])
    }
  }, [window.location.pathname]);

  function loadView(thisMapView) {
    thisMapView.set({
      container: "map-container"
    });
    // Add the appropriate measurement UI to the bottom-right when activated
    //thisMapView.ui.add(measurement, "bottom-right");
    // Set the views for the widgets
    // theMeasurement.view = thisMapView;
    // console.log('loading view', measurement)
  }

  function distanceMeasurement() {
    document.getElementById("distance").classList.add("active");
    document.getElementById("area").classList.remove("active");
    theMeasure.activeTool = "distance";
    setIsMeasuring(true);

    theMeasure.watch("activeWidget", (evt) => {
      console.log('distanceMeasurement evt', evt);
      if(evt){
        if(evt.viewModel){
          evt.viewModel.palette.pathPrimaryColor = [255, 102, 255,255];
          evt.viewModel.palette.pathSecondaryColor = [255, 255, 255,255];
          evt.viewModel.palette.handleColor = [255, 102, 255, 1];
        }
      }

    });
  }

  // Call the AreaMeasurement2D or AreaMeasurement3D
  function areaMeasurement() {
    document.getElementById("distance").classList.remove("active");
    document.getElementById("area").classList.add("active");
    theMeasure.activeTool = "area";
    setIsMeasuring(true);

    theMeasure.watch("activeWidget", (evt) => {
      console.log('areaMeasurement evt', evt);
      if(evt){
        if(evt.viewModel){
          evt.viewModel.palette.handleColor = [255, 102, 255, 1];
          evt.viewModel.palette.fillColor = [255, 102, 255,0.5];
          evt.viewModel.palette.pathColor = [255, 102, 255, 1];
        }
      }
    });
  }

  // Clears all measurements
  function clearMeasurements() {
    const distanceButton = document.getElementById("distance").classList.remove("active");
    const areaButton = document.getElementById("area").classList.remove("active");
    theMeasure.clear();
    setIsMeasuring(false);
    console.log('clearMeasurements', theMeasure);
  }

  return (
    <>
      <div id="map-container" className="map-container" ref={mapDiv} style={mapDivStyle}></div>
      <div id="toolbarDiv" className="esri-component esri-widget">
        <button
          id="distance"
          className="esri-widget--button esri-interactive esri-icon-measure-line"
          title="Distance Measurement Tool"
          onClick={() => distanceMeasurement()}
        ></button>
        <button
          id="area"
          className="esri-widget--button esri-interactive esri-icon-measure-area"
          title="Area Measurement Tool"
          onClick={() => areaMeasurement()}
        ></button>
        <button
          id="clear"
          className="esri-widget--button esri-interactive esri-icon-trash"
          title="Clear Measurements"
          onClick={() => clearMeasurements()}
        ></button>
      </div>
    </>
  )
};
