import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import './Home.scss';

export default function Home(){
    const history = useHistory();
    const [dismissed,setDismissed] = useState(false);
    return(
        <>
            <div className="home" style={dismissed ? {'display': 'none'} : null}>
                <div className="home-content" style={{"backgroundImage":"url('/home-background.jpg')"}}>
                    <h1 id="main-title">Reconnecting Vermont's Rivers</h1>
                    <div className="intro-text" id="main-sub-title">
                        The Vermont Functioning Floodplains Initiative is an effort by Vermont DEC and partners to identify and prioritize natural infrastructure projects through scoring, tracking, and mapping of floodplain and wetland functions as well as quantification of their social values. The goal is to achieve the highest possible water quality, ecological integrity, and flood resilience by targeting restoration and reconnection where it is most beneficial.
                    </div>
                    <div className="buttons">
                        <Link to="/explore-data"><button id="explore-map-data">EXPLORE MAP DATA</button></Link>
                        <Link to="/project-planning/start"><button id="explore-project-planning">BEGIN NEW PROJECT PLANNING</button></Link>
                    </div>
                </div>
            </div>
            <div className="home-shroud" style={dismissed ? {'display': 'none'} : null} onClick={() => history.push('/explore-data')}></div>
        </>
    )
}