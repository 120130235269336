import React, { useState, createContext, useContext } from 'react';
import config from '../config';
import { useAppState } from './App_State_Service';
import { useCriteria } from './Criteria_Service';
import { useRegions } from './Region_Service';

const ProjectContext = createContext(null);

export const ProjectProvider = ({children, ...services}) => {
    const [projectOutcomes, setProjectOutcomes] = useState([]);
    const [masterProjectPlanningList, setMasterProjectPlanningList] = useState([]);
    const [filteredProjectPlanningList, setFilteredProjectPlanningList] = useState([]);
    const [selectedProjects,setSelectedProjects] = useState({streams:[],rivers:[]});
    const [loadedProjectOutcomes, setLoadedProjectOutcomes] = useState([]);
    const [pastSelectedRows, setPastSelectedRows] = useState([]);
    const [selectedFeatureType, setSelectedFeatureType] = useState('');
    const [emptyMasterRes, setEmptyMasterRes] = useState(false);
    const [riverStabilityRes, setRiverStabilityRes] = useState([]);
    const [streamStabilityRes, setStreamStabilityRes] = useState([]);
    const [watershedReporting, setWatershedReporting] = useState([]);
    const [allWQProjects, setAllWQProjects] = useState([]);
    const [watershedExportData, setWatershedExportData] = useState([]);
    const [connDetailsExportData, setConnDetailsExportData] = useState([]);
    const [projReportExport, setProjReportExport] = useState([]);
    const [resilReportExport, setResilReportExport] = useState([]);
    //object of the export parameters
    const [wqReportHeader, setWqReportHeader] = useState({});
    const appStateSvc = useAppState();
    const criteriaSvc = useCriteria();
    const regionSvc = useRegions();
    const [connSummReport, setConnSummReport] = useState([
        'Geography Filter', 
        'Floodplain (Lateral-Vertical) Connectivity Filter', 
        'Stream (Longitudinal-Temporal) Connectivity Filter',
        'Protection Rank',
        'Watershed Position'
    ]);
    const [projectReport, setProjectReport] = useState([
        'Geography Filter', 
        'Floodplain (Lateral-Vertical) Connectivity Filter', 
        'Stream (Longitudinal-Temporal) Connectivity Filter',
        'Protection Rank',
        'Watershed Position',
        'Project Priority',
        'Project Location'
    ]);
    const [resiliencyReport, setResiliencyReport] = useState([
        'Geography Filter', 
        'Floodplain (Lateral-Vertical) Connectivity Filter', 
        'Stream (Longitudinal-Temporal) Connectivity Filter',
        'Protection Rank',
        'Watershed Position'
    ]);

    const [mockExport, setMockExport] = useState([
        {
            "area": 'Mill',
            "total_floodplain_conn": 491,
            "potential_floodplain_conn": 41504020104,
            "floodplain_conn_att": 6800,
            "total_floodplain_conn": 5021,
            "floodplain_conn_dep": 50,
            "total_floodplain_proj": 3421,
            "floodplain_proj_feas": 50,
            "tot_stream_conn": 50,
            "pot_stream_conn": 74,
            "stream_conn_att": 50,
            "total_stream_conn_dep": 11400,
            "stream_conn_dep": 83,
            "stream_stab_p_load": 25
        },
        {
            "area": 'Mill',
            "total_floodplain_conn": 1425,
            "potential_floodplain_conn": 41504020302,
            "floodplain_conn_att": 8900,
            "total_floodplain_conn": 5738,
            "floodplain_conn_dep": 47,
            "total_floodplain_proj": 4217,
            "floodplain_proj_feas": 47,
            "tot_stream_conn": 53,
            "pot_stream_conn": 64,
            "stream_conn_att": 47,
            "total_stream_conn_dep": 14700,
            "stream_conn_dep": 81,
            "stream_stab_p_load": 25
        },
        {
            "area": 'Winooski',
            "total_floodplain_conn": 2103,
            "potential_floodplain_conn": 41504030201,
            "floodplain_conn_att": 11600,
            "total_floodplain_conn": 8174,
            "floodplain_conn_dep": 53,
            "total_floodplain_proj": 6167,
            "floodplain_proj_feas": 53,
            "tot_stream_conn": 47,
            "pot_stream_conn": 70,
            "stream_conn_att": 53,
            "total_stream_conn_dep": 22500,
            "stream_conn_dep": 81,
            "stream_stab_p_load": 25
        },
        {
            "area": 'Mad',
            "total_floodplain_conn": 1085,
            "potential_floodplain_conn": 41504030501,
            "floodplain_conn_att": 8200,
            "total_floodplain_conn": 5828,
            "floodplain_conn_dep": 57,
            "total_floodplain_proj": 4635,
            "floodplain_proj_feas": 57,
            "tot_stream_conn": 43,
            "pot_stream_conn": 71,
            "stream_conn_att": 57,
            "total_stream_conn_dep": 26500,
            "stream_conn_dep": 87,
            "stream_stab_p_load": 25
        }
    ]);

    const getAllProjects = (region,criteriaList) => {
        let dataToReturn = null;
        let queryBody = {
            "scale": region.scale.toLowerCase(),
            "regionid": region.region.regionid,
            "functions": criteriaList.functions,
            "applicabilities": criteriaList.applicabilities,
            "projects": criteriaList.projects,
            "priorities": criteriaList.priorities,
            "rangestart": 0,
            "rangeend": 10,
            "orderby": "functionid",
            "orderdir": "asc"
        };
        return(
            fetch(
                `${config.API_HOST}/get_projectplanning`,
                {
                    method: 'POST',
                    headers: {
                        'authorization': config.token,
                        'Content-Type': config.content_type
                    },
                    body: JSON.stringify(queryBody)
                }
            )
            .then(res => {
                console.log('getAllProjects res', res)
                return res.json();
            })
        )
    };

    const fetchWQProjects = () => {
        let queryBody = {}; // no query body needed
        return(
            fetch(
                `${config.API_HOST}/wqprojects`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then(res => {
                console.log('getAllProjects res', res)
                return res.json();
            })
        )
    };

    const fetchWQProjectList = () => {
        fetchWQProjects().then(
            (res) => {
                let data = res;
                console.log('data', data);
                setAllWQProjects(data.d);
            }
        )
    };

    const filterProjects = (res, criteriaList) => {
        console.log('getAllProjects res + criteria list', res, criteriaList);
        let filteredProjects = [];
        console.log('masterProjectPlanningList', masterProjectPlanningList);
        for (let proj in res) {
            // console.log('proj', proj)
            filteredProjects.push(res[proj]);
        }
        setFilteredProjectPlanningList(filteredProjects);
        console.log('filterProjects', filteredProjects);
        if(!filteredProjects.length){
            console.log('empty filteredProj', filteredProjectPlanningList, filteredProjects, masterProjectPlanningList, )
        }
        return filteredProjects;
    };

    const fetchProjectPlanningList = (region, criteriaList) => {
        getAllProjects(region,criteriaList).then(
            (res) => {
                let resJson = res[0].get_projectplanning;
                console.log('getAllProjects call res', resJson)
                if(resJson == null || !resJson.length){
                    console.log('getAllProjects empty res')
                    setMasterProjectPlanningList(null);
                    setFilteredProjectPlanningList(null);
                    setEmptyMasterRes(true);
                    return;
                }
                setEmptyMasterRes(false);
                console.log('getAllProjects res', resJson);
                setMasterProjectPlanningList(resJson);
                filterProjects(resJson, criteriaList)
            }
        )
        .catch((err) => console.log('getAllProjects err', err));
    };

    const fetchPopupProjectList_Stream = (uid) => {
        return fetch(
            `${config.API_HOST}/get_projectlistbystream`,
            {
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({
                    'dissid2021' : uid
                })
            }
        )
    };
    const fetchPopupProjectList_River = (uid) => {
        return fetch(
            `${config.API_HOST}/get_projectlistbyriver`,
            {
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({
                    'ffi_id' : uid,
                })
            }
        )
    };

    const handlePopupProjectList = (uid, str) => {
        if(str == config.riverName){
            return fetchPopupProjectList_River(uid);
        }else if(str == config.streamName){
            return fetchPopupProjectList_Stream(uid);
        }
    };

    const fetchProjectPlanningByPopupId = (uid, feature) => {
        // console.log('firing');
        //stream is always getting passed in here, how can we tell if its a river or stream
        return fetch(
            `${config.API_HOST}/get_projectplanning_byffiid`,
            {
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({
                    'ffiid': uid,
                    'feature': feature
                })
            }
        );
    };

    const fetchProjCalcInputs_Rivers = (arrId) => {
        return fetch(
            `${config.API_HOST}/get_projectcalcinputs_rivers`,
            {
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({
                    'ffi_ids': arrId
                })
            }
        );
    };

    const fetchProjCalcInputs_Streams = (arrId) => {
        return fetch(
            `${config.API_HOST}/get_projectcalcinputs_streams`,
            {
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({
                    'ffi_ids': arrId
                })
            }
        )
    }

    const postProjCalcInputs_Streams = (objArr) => {
        console.log('objArr', objArr)
        return fetch(
            `${config.API_HOST}/calcproject_streams`,{
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({
                    'ffi_ids': objArr
                })
            }
        )
    }

    const postProjCalcInputs_Rivers = (objArr) => {
        return fetch(
            `${config.API_HOST}/calcproject_rivers`, {
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({
                    'ffi_ids': objArr
                })
            }
        )
    };

    const postProjCalcInputs_Storage = (objArr) => {
        // console.log('postProjCalcInputs_Storage')
        return fetch(
            `${config.API_HOST}/calcproject_storage`, {
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({
                    'ffi_ids': objArr
                })
            }
        )
    };

    const getProjectList = () => {
        return fetch(
            `${config.API_HOST}/get_projectlist`, {
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({})
            }
        )
    };
    //calcproject_resiliency
    const getProjectResiliency_Unit = (objArr) => {
        return fetch(
            `${config.API_HOST}/calcproject_unitresiliency`, {
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({'ffi_ids': objArr})
            }
        )
    };

    const getProjectResiliency_Risk = (objArr) => {
        return fetch(
            `${config.API_HOST}/calcproject_unitrisk`, {
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({'ffi_ids': objArr})
            }
        )
    };

    const getProjectResiliency_Summary = (objArr) => {
        return fetch(
            `${config.API_HOST}/calcproject_summaryresiliency`, {
                method: 'POST',
                headers: {
                    'authorization': config.token,
                    'Content-Type': config.content_type
                },
                body: JSON.stringify({'ffi_ids': objArr})
            }
        )
    };

    function exportProjectScreening(){
        console.log('good morning dave', appStateSvc.export_wid);
        // code moved from tableWrapper.jsx
        // let title = 'good morning Dave';
        let titles = '"River Corridor ID","Floodplain (Lat-Vert) Connectivity (Score of 0-100)","Total Feasibility (Score of 0-100)","TP Load Assigned to Floodplains (kg)","Incision Ratio","Habitat Priority","Resilience Priority","Number of Priority Projects","Project Type","Connectivity Priority","Typical Project Unit Cost ($)","Simulated Watershed Average P Credit (kg/ac-yr)","Stream Segment ID","Stream (Long-Temp) Connectivity (Score of 0 - 100)","TP Load Assigned to Streams (kg)","Number of Priority Projects","Project Type","Connectivity Priority","Typical Project Unit Cost ($)","Avoided Damages ($)","Simulated Watershed Average P Credit (kg/ac-yr)"\r\n';
        let value = 'how are you feeling today?';
        let dataValues = titles;
        //this is what you use to make the new row
        for(let i  = 0; i < filteredProjectPlanningList.length; i++){
            // console.log('filteredProjectPlanningList', filteredProjectPlanningList[i].ffi_id.toString());
            if(filteredProjectPlanningList[i].rc_projects.length && filteredProjectPlanningList[i].rc_projects[0] !== null){
                // console.log('river projects building', filteredProjectPlanningList[i])
                for(let j = 0; j < filteredProjectPlanningList[i].rc_projects.length; j++){
                    // console.log('firing', filteredProjectPlanningList[i]);
                    // console.log('j', filteredProjectPlanningList[i].rc_projects[j]);
                    dataValues +=
                    '"'+ filteredProjectPlanningList[i].ffi_id + '","'
                    + filteredProjectPlanningList[i].conn_scr + '","'
                    + filteredProjectPlanningList[i].feas_tot + '","'
                    + filteredProjectPlanningList[i].conn_kgyr + '","'
                    + filteredProjectPlanningList[i].ir + '","'
                    + '-' + '","'
                    + '-' + '","' 
                    + filteredProjectPlanningList[i].rcprojcount + '","'
                    + filteredProjectPlanningList[i].rc_projects[j].project + '","'
                    + filteredProjectPlanningList[i].rc_projects[j].priority + '","'
                    + filteredProjectPlanningList[i].rc_projects[j].est_cost + '","'
                    + filteredProjectPlanningList[i].rc_projects[j].wshd_pcredit + '","' 
                    + '-' + '","'
                    + '-' + '","'
                    + '-' + '","'
                    + '-' + '","'
                    + '-' + '","'
                    + '-' + '","'
                    + '-' + '","'
                    + '-' + '","'
                    + '-' + '"\r\n'
                }
            }
            if(filteredProjectPlanningList[i].st_data.length && filteredProjectPlanningList[i].st_data[0] !== null){
                for(let j = 0; j < filteredProjectPlanningList[i].st_data.length; j++){
                    if(filteredProjectPlanningList[i].st_data[j].st_projects.length && filteredProjectPlanningList[i].st_data[j].st_projects[0] !== null){
                        for(let k = 0; k < filteredProjectPlanningList[i].st_data[j].st_projects.length; k++){
                            dataValues +=
                                '"'+ filteredProjectPlanningList[i].ffi_id + '","'
                                + filteredProjectPlanningList[i].conn_scr + '","'
                                + filteredProjectPlanningList[i].feas_tot + '","'
                                + filteredProjectPlanningList[i].conn_kgyr + '","'
                                + filteredProjectPlanningList[i].ir + '","'
                                + '-' + '","'
                                + '-' + '","' 
                                + filteredProjectPlanningList[i].rcprojcount + '","'
                                + '-' + '","'
                                + '-' + '","'
                                + '-' + '","'
                                + '-' + '","'
                                + filteredProjectPlanningList[i].st_data[j].ffi_id + '","'
                                + filteredProjectPlanningList[i].st_data[j].st_con_scr + '","'
                                + filteredProjectPlanningList[i].st_data[j].stcon_kgyr + '","'
                                + filteredProjectPlanningList[i].st_data[j].stprojcount + '","'
                                + filteredProjectPlanningList[i].st_data[j].st_projects[k].project + '","'
                                + filteredProjectPlanningList[i].st_data[j].st_projects[k].priority + '","'
                                + filteredProjectPlanningList[i].st_data[j].st_projects[k].est_cost + '","'
                                + filteredProjectPlanningList[i].st_data[j].st_projects[k].avoid_damages + '","'
                                + filteredProjectPlanningList[i].st_data[j].st_projects[k].wshd_pcredit + '"\r\n'
                            }
                    } else{
                        dataValues +=
                        '"' + filteredProjectPlanningList[i].ffi_id + '","'
                        + filteredProjectPlanningList[i].conn_scr + '","'
                        + filteredProjectPlanningList[i].feas_tot + '","'
                        + filteredProjectPlanningList[i].conn_kgyr + '","'
                        + filteredProjectPlanningList[i].ir + '","'
                        + '-' + '","'
                        + '-' + '","' 
                        + filteredProjectPlanningList[i].rcprojcount + '","'
                        + '-' + '","'
                        + '-' + '","'
                        + '-' + '","'
                        + '-' + '","'
                        + filteredProjectPlanningList[i].st_data[j].ffi_id + '","'
                        + filteredProjectPlanningList[i].st_data[j].st_con_scr + '","'
                        + filteredProjectPlanningList[i].st_data[j].stcon_kgyr + '","'
                        + filteredProjectPlanningList[i].st_data[j].stprojcount + '","'
                        + '-' + '","'
                        + '-' + '","'
                        + '-' + '","'
                        + '-' + '","'
                        + '-' + '"\r\n'
                    }
                }
            }
            if((filteredProjectPlanningList[i].st_data.length && filteredProjectPlanningList[i].st_data[0] !== null) && 
            (filteredProjectPlanningList[i].rc_projects.length && filteredProjectPlanningList[i].rc_projects[0] !== null)){
                dataValues +=
                '"' + filteredProjectPlanningList[i].ffi_id + '","'
                + filteredProjectPlanningList[i].conn_scr + '","'
                + filteredProjectPlanningList[i].feas_tot + '","'
                + filteredProjectPlanningList[i].conn_kgyr + '","'
                + filteredProjectPlanningList[i].ir + '","'
                + '-' + '","'
                + '-' + '","' 
                + filteredProjectPlanningList[i].rcprojcount + '","'
                + '-' + '","'
                + '-' + '","'
                + '-' + '","'
                + '-' + '","'
                + '-' + '","'
                + '-' + '","'
                + '-' + '","'
                + '-' + '","'
                + '-' + '","'
                + '-' + '","'
                + '-' + '","'
                + '-' + '","'
                + '-' + '"\r\n'
            }
        }
        // console.log('post loop data', dataValues);
        let theDate = new Date();
        theDate = theDate.toLocaleDateString();
        let data = titles + '\r\n' + dataValues + '\r\n';
        let filename = 'FFI_ProjectScreeningData_' + theDate + '_' + appStateSvc.export_wid;
        let testBlob = new Blob([dataValues], {type: 'text/csv;charset=utf-8;'});
        let link = document.createElement('a');
        let url = URL.createObjectURL(testBlob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        appStateSvc.setMakeAlert_Export(false);
        appStateSvc.setAlertText('');
        appStateSvc.setExport_wid(-1);
    };

    function roundNumbers(num){
        return Number.parseFloat(num).toFixed(1)
    };

    function exportCalcInputs(){
        // console.log('streamStabilityRes', streamStabilityRes);
        // console.log('riverRowObj', riverStabilityRes, appStateSvc.riverRowObj, appStateSvc.storageRowObj, appStateSvc.streamRowObj);
        let titles = '"River Corridor ID","Existing Incision Ratio","Existing Unconstrained River Corridor Area (acres)","Existing Robust Protection Area (acres)","Existing Moderate Protection Area (acres)","Existing Low Protection Area (acres)","Existing No Protection Area (acres)","Existing Naturally Vegetated Buffer Area (acres)","Proposed Incision Ratio","Existing Area with Vertical Change (acres)","Existing Connectivity in Project Area","Proposed Existing Incision Ratio","Proposed Unconstrained River Corridor Area (acres)","Proposed Robust Protection Area (acres)","Proposed Moderate Protection Area (acres)","Proposed Low Protection Area (acres)","Proposed No Protection Area (acres)","Proposed Naturally Vegetated Buffer Area (acres)","Proposed Incision Ratio","Proposed Area with Vertical Change (acres)","Connectivity in Project Area","Proposed Project Area (acres)","River Project List","Stream ID","Existing Length of Road Drainage Disconnection (miles)","Existing Agricultural Land Drainage Disconnection (acres)","Proposed Incition Ratio","Existing Area with Vertical Change (acres)","Proposed Length of Road Drainage Disconnection (miles)","Proposed Agricultural Land Drainage Disconnection (acres)","Incision Ratio","Proposed Area with Vertical Change (acres)","Stream Project List"\r\n';
        let dataValues = titles;
        let riverProjects = [];
        for(let i = 0; i < criteriaSvc.riverProjectTypes.length; i++){
            // console.log('i',criteriaSvc.riverProjectTypes[i])
            if(criteriaSvc.riverProjectTypes[i].checked){
                // console.log('caught one', criteriaSvc.riverProjectTypes);
                riverProjects.push(criteriaSvc.riverProjectTypes[i].project);
            }
        }
        let streamProjects = [];
        for(let i = 0; i < appStateSvc.streamProjects.length; i++){
            if(appStateSvc.streamProjects[i].checked){
                streamProjects.push(appStateSvc.streamProjects[i].project);
            }
        }
        // console.log('(appStateSvc.storageRowObj', appStateSvc.storageRowObj);
        riverProjects = riverProjects.join('; ');
        streamProjects = streamProjects.join('; ');
        // console.log('river projects joined', riverProjects, streamProjects);
        // if(!riverStabilityRes.length || !appStateSvc.streamRowObj.length){
        //     return alert('got ya!')
        // }
        for(let i = 0; i < riverStabilityRes.length; i++){
            console.log('riverStabilityRes', riverStabilityRes)
            dataValues +=
            '"' + riverStabilityRes[i].ffi_id + '","'
            + roundNumbers(riverStabilityRes[i].ir) + '","'
            + roundNumbers(riverStabilityRes[i].unconsac) + '","'
            + roundNumbers(riverStabilityRes[i].robust) + '","'
            + roundNumbers(riverStabilityRes[i].mod) + '","'
            + roundNumbers(riverStabilityRes[i].low) + '","'
            + roundNumbers(riverStabilityRes[i].none) + '","'
            + roundNumbers(riverStabilityRes[i].veg_ac50) + '","'
            + '-' + '","'
            + '-' + '","'
            + riverStabilityRes[i].stor_conn + '","'
            for(let j = 0; j < appStateSvc.riverRowObj.length; j++){
                // if(appStateSvc.riverRowObj[j] == )
                // console.log('dataValues', dataValues)
                if(Object.keys(appStateSvc.riverRowObj[j])[0] === riverStabilityRes[i].ffi_id){
                    dataValues +=
                    // '"' + Object.values(appStateSvc.riverRowObj[j])[0].unconsac + ',"'
                    '' + Object.values(appStateSvc.riverRowObj[j])[0].ext_ir + '","'                  
                    +  Object.values(appStateSvc.riverRowObj[j])[0].unconsac + '","'
                    +  Object.values(appStateSvc.riverRowObj[j])[0].robust + '","'
                    +  Object.values(appStateSvc.riverRowObj[j])[0].mod + '","'
                    +  Object.values(appStateSvc.riverRowObj[j])[0].low + '","'
                    +  Object.values(appStateSvc.riverRowObj[j])[0].none + '","'
                    +  Object.values(appStateSvc.riverRowObj[j])[0].veg_ac50 + '","'
                    +  Object.values(appStateSvc.riverRowObj[j])[0].prop_ir + '","'
                    +  Object.values(appStateSvc.riverRowObj[j])[0].areavertchange + '","'
                }
                // console.log('riverStabilityRes[i].ffi_id', appStateSvc.riverRowObj[j], riverStabilityRes[i].ffi_id, Object.keys(appStateSvc.riverRowObj[j])[0], Object.values(appStateSvc.riverRowObj[j])[0]);
            }
            //storage row is being angry with me
            for(let j = 0; j < appStateSvc.storageRowObj.length; j++){
                // console.log('dataValues 2', dataValues);
                // console.log('Object.values(appStateSvc.storageRowObj[j][0]).exist_stor_conn', Object.values(appStateSvc.storageRowObj[j][0]).exist_stor_conn)
                if(Object.keys(appStateSvc.storageRowObj[j])[0] === riverStabilityRes[i].ffi_id){
                    // console.log('dataValues 2', dataValues, typeof(dataValues));
                    dataValues +=
                    Object.values(appStateSvc.storageRowObj[j])[0].stor_conn + '","'
                    + Object.values(appStateSvc.storageRowObj[j])[0].prop_proj_area + '","'
                }
            }
            dataValues +=
            '"' + riverProjects + ',"'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            +'-' + '","'
            + '-' + '"\r\n'
            // if(streamStabilityRes && appStateSvc.streamRowObj.length){
            //     dataValues +=
            //     '"' + riverProjects + ',"'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '"\r\n'
            //     // console.log('stream', appStateSvc.streamRowObj, Object.values(appStateSvc.streamRowObj)[0], Object.keys(appStateSvc.streamRowObj)[0], streamStabilityRes)
            //     dataValues +=
            //     '"' + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     +'-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + streamStabilityRes.ffi_id + '","'
            //     + roundNumbers(streamStabilityRes.rd_miles) + '","'
            //     + roundNumbers(streamStabilityRes.ag_acres) + '","'
            //     + (streamStabilityRes.ir ? roundNumbers(streamStabilityRes.ir) : '0') + '","'
            //     + '-' + '","'
            //     //TODO: reformat this before publishing the stream patch
            //     + appStateSvc.streamRowObj[0].rd_miles + '","'
            //     + appStateSvc.streamRowObj[0].ag_acres + '","'
            //     + appStateSvc.streamRowObj[0].ir + '","'
            //     + appStateSvc.streamRowObj[0].areavertchange + '","'
            //     + streamProjects + '"\r\n'
            // }else{
            //     dataValues +=
            //     '"' + riverProjects + ',"'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '"\r\n'
            //     // console.log('stream', appStateSvc.streamRowObj, Object.values(appStateSvc.streamRowObj)[0], Object.keys(appStateSvc.streamRowObj)[0], streamStabilityRes)
            //     dataValues +=
            //     '"' + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     +'-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     //TODO: reformat this before publishing the stream patch
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '","'
            //     + '-' + '"\r\n'
            // }
            // console.log('testing', appStateSvc.riverRowObj[riverStabilityRes[i].ffi_id])
        }
        if(streamStabilityRes && appStateSvc.streamRowObj.length){
            dataValues +=
            '"' + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            +'-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + streamStabilityRes.ffi_id + '","'
            + roundNumbers(streamStabilityRes.rd_miles) + '","'
            + roundNumbers(streamStabilityRes.ag_acres) + '","'
            + (streamStabilityRes.ir ? roundNumbers(streamStabilityRes.ir) : '0') + '","'
            + '-' + '","'
            //TODO: reformat this before publishing the stream patch
            + appStateSvc.streamRowObj[0].rd_miles + '","'
            + appStateSvc.streamRowObj[0].ag_acres + '","'
            + appStateSvc.streamRowObj[0].ir + '","'
            + appStateSvc.streamRowObj[0].areavertchange + '","'
            + streamProjects + '"\r\n'
        }else{
            dataValues +=
            // '"' + riverProjects + ',"'
            '"' + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            // console.log('stream', appStateSvc.streamRowObj, Object.values(appStateSvc.streamRowObj)[0], Object.keys(appStateSvc.streamRowObj)[0], streamStabilityRes)
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            +'-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '"\r\n'
        }
        let theDate = new Date();
        theDate = theDate.toLocaleDateString();
        // console.log('exportCalcInputs firing', dataValues)
        let fileName = 'FFI_CalculationInputs_' + theDate + '_' + appStateSvc.export_wid;
        let values = '"How are you feeling today"';
        let testBlob = new Blob([dataValues], {type: 'text/csv;charset=utf-8;'});
        let link = document.createElement('a');
        let url = URL.createObjectURL(testBlob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    function exportWQTab_Summary(){
        // let wqSummaryTitles = '"Subunit ID(s)","Town(s)","Project(s)","Stream Name(s)","Floodplain Connectivity - Stream Stability Year 1 (kg/yr)","Floodplain Connectivity - Storage Year 1 (kg)","Floodplain Connectivity - Stream Stability Year 2+ (kg/yr)","Stream Connectivity - Stream Stability (kg/yr)","TOTAL P Credit Year 1 (kg)","TOTAL P Credit Year 2+ (kg/yr)"\r\n';
        let wqSummaryTitles = '"Subunit ID(s)","Town(s)","Project(s)","Stream Name(s)","Floodplain Connectivity - Stream Stability Year 1 (kg/yr)","Floodplain Connectivity - Storage Year 1 (kg)","Floodplain Connectivity - Stream Stability Year 2+ (kg/yr)","Floodplain Connectivity - Storage Year 2+ (kg)","Stream Connectivity - Stream Stability Year 1 (kg/yr)","Stream Connectivity - Stream Stability Year 2 + (kg/yr)","TOTAL P Credit Year 1 (kg)","TOTAL P Credit Year 2+ (kg/yr)"\r\n';
        let subunits;
        if(appStateSvc.subUnitIds && appStateSvc.subUnitIds !== '-'){
            subunits = appStateSvc.subUnitIds.join('; ');
        }
        let towns
        if(appStateSvc.uniqueTowns && appStateSvc.uniqueTowns !== '-'){
            towns = appStateSvc.uniqueTowns.join('; ');
        }
        let projects
        if(appStateSvc.uniqueProjects && appStateSvc.uniqueProjects !== '-'){
            projects = appStateSvc.uniqueProjects.join('; ');
        }
        let streams
        if(appStateSvc.uniqueStreamNames && appStateSvc.uniqueStreamNames !== '-'){
            streams = appStateSvc.uniqueStreamNames.join('; ');

        }
        let yearOneTotal = appStateSvc.summaryRows_Rivers.y_one + appStateSvc.summaryRows_Streams.y_one + appStateSvc.summaryRows_Storage.y_one;
        let yearTwoTotal = appStateSvc.summaryRows_Rivers.y_two + appStateSvc.summaryRows_Streams.y_two + appStateSvc.summaryRows_Storage.y_two;
        // console.log('appStateSvc.summaryRows_Rivers.y_one', appStateSvc.summaryRows_Rivers.y_one);
        wqSummaryTitles += 
        '"' + subunits + '","'
        + towns + '","'
        + projects + '","'
        + streams + '","'
        + appStateSvc.summaryRows_Rivers.y_one + '","'
        + appStateSvc.summaryRows_Storage.y_one + '","'
        + (appStateSvc.wq_Rivers.length ? appStateSvc.wq_Rivers[0].yr1_sumriversp_kgyr: '-') + '","'
        + (appStateSvc.wq_Storage.length ? appStateSvc.wq_Storage[0].yr2_sumstorp_kgyr: '-') + '","'
        + (appStateSvc.wq_Streams.length ? appStateSvc.wq_Streams[0].yr1_sumstreamp_kgyr : '-') + '","'
        + (appStateSvc.wq_Streams.length ? appStateSvc.wq_Streams[0].yr1_sumstreamp_kgyr : '-') + '","'
        + yearOneTotal + '","'
        + yearTwoTotal + '"\r\n'

        let theDate = new Date();
        theDate = theDate.toLocaleDateString();
        let exportTitle = 'FFI_WQBenefitSummary_' + theDate + '_' + appStateSvc.export_wid;
        let testBlob = new Blob([wqSummaryTitles], {type: 'text/csv;charset=utf-8;'});
        let link = document.createElement('a');
        let url = URL.createObjectURL(testBlob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportTitle);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    function exportWQTab_Details(){
        let wqDetailsTitles = '"River Corridor ID","Project Connectivity Credit Score","Existing Floodplain/Corridor Connectivity Score","Proposed Lateral Credit Score","Proposed Vertical Credit Score","Proposed Subunit Floodplain/Connectivity Score","Lateral P Reduction Credit (kg/yr)","Vertical P Reduction Credit (kg/yr)","Total P Reduction Credit (kg/yr)","Storage P Credit, Year 1 (kg)","Storage P Credit, Year 2+ (kg/yr)","Stream ID","Stream Name","Project Connectivity Credit Score","Existing Stream Segment Connectivity Score","Proposed Longitudinal Credit Score","Proposed Temporal Credit Score","Proposed Stream Segment Connectivity Score","Longitudinal P Reduction Credit (kg/yr)","Temporal P Reduction Credit (kg/yr)","Total Stream Connectivity P Credit (kg/yr)","Total Stream Connectivity P Credit (lb/yr)"\r\n';
        for(let i = 0; i < appStateSvc.wq_Rivers.length; i++){
            wqDetailsTitles +=
            '"' + appStateSvc.wq_Rivers[i].p_ffi_id + '","'
            + appStateSvc.wq_Rivers[i].prjt_conncredit + '","'
            + appStateSvc.wq_Rivers[i].curr_conn_scr + '","'
            + appStateSvc.wq_Rivers[i].prj_latconncredit + '","'
            + appStateSvc.wq_Rivers[i].prjt_vertconnncredit + '","'
            + appStateSvc.wq_Rivers[i].prop_conn_scr + '","'
            + appStateSvc.wq_Rivers[i].prjt_latpcred + '","'
            + appStateSvc.wq_Rivers[i].prjt_vertpcred + '","'
            + appStateSvc.wq_Rivers[i].prjt_pcredit_kgyr + '","'
            + appStateSvc.wq_Storage[i].pcred_stor_yr1_kgyr + '","'
            + appStateSvc.wq_Storage[i].pcred_stor_yr2_kgyr + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '","'
            + '-' + '"\r\n'
        }
        wqDetailsTitles +=
        '"' + '-' + '","'
        + '-' + '","'
        + '-' + '","'
        + '-' + '","'
        + '-' + '","'
        + '-' + '","'
        + '-' + '","'
        + '-' + '","'
        + '-' + '","'
        + '-' + '","'
        + '-' + '","'
        + appStateSvc.wq_Streams[0].p_ffi_id + '","'
        + appStateSvc.wq_Streams[0].stream_name + '","'
        + appStateSvc.wq_Streams[0].proj_con_scr + '","'
        + appStateSvc.wq_Streams[0].curr_st_con_scr + '","'
        + appStateSvc.wq_Streams[0].prop_long_crdt + '","'
        + appStateSvc.wq_Streams[0].prop_temporal_crdt + '","'
        + appStateSvc.wq_Streams[0].prop_st_con_scr + '","'
        + appStateSvc.wq_Streams[0].proj_longp_crdt_kgyr + '","'
        + appStateSvc.wq_Streams[0].proj_tempp_crdt_kgyr + '","'
        + appStateSvc.wq_Streams[0].proj_pcrdt_kgyr + '","'
        + appStateSvc.wq_Streams[0].proj_pcrdt_lbyr + '"\r\n'

        let theDate = new Date();
        theDate = theDate.toLocaleDateString();
        let exportTitle = 'FFI_WQBenefitsDetails_' + theDate + '_' + appStateSvc.export_wid;
        let testBlob = new Blob([wqDetailsTitles], {type: 'text/csv;charset=utf-8;'});
        let link = document.createElement('a');
        let url = URL.createObjectURL(testBlob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportTitle);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    function exportWaterResiliencyCSV(){
        let theDate = new Date();
        theDate = theDate.toLocaleDateString();

        //payload parsing is gonna come here
        // console.log("regionSvc.payload", regionSvc.wqPayload);
        let paramTitles = `"Aggregation Scale = ${wqReportHeader.aggregation};","Scale = ${wqReportHeader.scale};","Geography = ${wqReportHeader.regionsfilter};","Floodplain (Lateral-Vertical) Connectivity = ${wqReportHeader.latconnfilters};","Stream (Longitidinal-Temporal) Connectivity = ${wqReportHeader.longconnfilters};","Protection Rank = ${wqReportHeader.protectionfilters};","Watershed Position = ${wqReportHeader.positionfilters};"\r\n`;
        let watershedTitles = paramTitles += '"Area","Total Floodplain Connectivity (Score)","Potential Floodplain Connectivity (Score)","Floodplain Connectivity Attainment (%)","Floodplain Connectivity Departure (%)","Total Floodplain Connectivity Departure (Score)","Total Floodplain Project Feasibility (Score)","Floodplain Project Feasibility (%)","Floodplain Stability P Load (kg/yr)","Total Stream Connectivity (Score)","Potential Stream Connectivity (Score)","Stream Connectivity Attainment (%)","Total Stream Connectivity Departure (Score)","Stream Connectivity Departure (%)","Stream Stability P Load (kg/yr)"\r\n';
        // watershedTitles += '"' + 'How are you feeling today?' + '"\r\n';
        for(let i = 0; i < watershedExportData.length; i++){
            watershedTitles += '"' + `${watershedExportData[i].region ? watershedExportData[i].region : '-'}` + '","'
            + `${watershedExportData[i].fp_conn_scr ? watershedExportData[i].fp_conn_scr : '-'}` + '","'
            + `${watershedExportData[i].fp_con_pot ? watershedExportData[i].fp_con_pot : '-'}` + '","'
            + `${watershedExportData[i].fp_attainment ? watershedExportData[i].fp_attainment : '-'}` + '","'
            + `${watershedExportData[i].fp_dep_pct ? watershedExportData[i].fp_dep_pct : '-'}` + '","'
            + `${watershedExportData[i].fp_dep_scr ? watershedExportData[i].fp_dep_scr : '-'}` + '","'
            + `${watershedExportData[i].fp_feas ? watershedExportData[i].fp_feas : '-'}` + '","'
            + `${watershedExportData[i].fp_feas_pct ? watershedExportData[i].fp_feas_pct : '-'}` + '","'
            + `${watershedExportData[i].fp_allocation ? watershedExportData[i].fp_allocation : '-'}` + '","'
            + `${watershedExportData[i].st_con_scr ? watershedExportData[i].st_con_scr : '-'}` + '","'
            + `${watershedExportData[i].st_con_pot ? watershedExportData[i].st_con_pot : '-'}` + '","'
            + `${watershedExportData[i].st_attainment ? watershedExportData[i].st_attainment : '-'}` + '","'
            + `${watershedExportData[i].st_dep_scr ? watershedExportData[i].st_dep_scr : '-'}` + '","' 
            + `${watershedExportData[i].st_dep_pct ? watershedExportData[i].st_dep_pct : '-'}` + '","'
            + `${watershedExportData[i].stcon_kgyr ? watershedExportData[i].stcon_kgyr : '-'}` + '"\r\n';
        }

        let exportTitle = 'FFI_Connectivity_Summary_' + theDate + "_" + appStateSvc.export_wid;
        let blob = new Blob([watershedTitles], {type: 'text/csv;charset=utf-8;'});
        let link = document.createElement('a');
        let url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportTitle);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function exportConnDetailsReport(){
        //change watershedExportData to connDetailsExportData
        let theDate = new Date();
        theDate = theDate.toLocaleDateString();
        let paramTitles = `"Aggregation Scale = ${wqReportHeader.aggregation};","Scale = ${wqReportHeader.scale};","Geography = ${wqReportHeader.regionsfilter};","Floodplain (Lateral-Vertical) Connectivity = ${wqReportHeader.latconnfilters};","Stream (Longitidinal-Temporal) Connectivity = ${wqReportHeader.longconnfilters};","Protection Rank = ${wqReportHeader.protectionfilters};","Watershed Position = ${wqReportHeader.positionfilters};"\r\n`;
        let watershedTitles = paramTitles += '"Area","Total Floodplain Connectivity (Score)","Potential Floodplain Connectivity (Score)","Floodplain Connectivity Attainment (%)","Floodplain Connectivity Departure (%)","Total Floodplain Connectivity Departure (Score)","Total Floodplain Project Feasibility (Score)","Floodplain Project Feasibility (%)","Floodplain Stability P Load (kg/yr)","Total Stream Connectivity (Score)","Potential Stream Connectivity (Score)","Stream Connectivity Attainment (%)","Total Stream Connectivity Departure (Score)","Stream Connectivity Departure (%)","Stream Stability P Load (kg/yr)"\r\n'
        // watershedTitles += '"' + 'How are you feeling today?' + '"\r\n';
        for(let i = 0; i < connDetailsExportData.length; i++){
            watershedTitles += '"' + `${connDetailsExportData[i].region ? connDetailsExportData[i].region : '-'}` + '","'
            + `${connDetailsExportData[i].fp_conn_scr ? connDetailsExportData[i].fp_conn_scr : '-'}` + '","'
            + `${connDetailsExportData[i].fp_con_pot ? connDetailsExportData[i].fp_con_pot : '-'}` + '","'
            + `${connDetailsExportData[i].fp_attainment ? connDetailsExportData[i].fp_attainment : '-'}` + '","'
            + `${connDetailsExportData[i].fp_dep_pct ? connDetailsExportData[i].fp_dep_pct : '-'}` + '","'
            + `${connDetailsExportData[i].fp_dep_scr ? connDetailsExportData[i].fp_dep_scr : '-'}` + '","'
            + `${connDetailsExportData[i].fp_feas ? connDetailsExportData[i].fp_feas : '-'}` + '","'
            + `${connDetailsExportData[i].fp_feas_pct ? connDetailsExportData[i].fp_feas_pct : '-'}` + '","'
            + `${connDetailsExportData[i].fp_allocation ? connDetailsExportData[i].fp_allocation : '-'}` + '","'
            + `${connDetailsExportData[i].st_con_scr ? connDetailsExportData[i].st_con_scr : '-'}` + '","'
            + `${connDetailsExportData[i].st_con_pot ? connDetailsExportData[i].st_con_pot : '-'}` + '","'
            + `${connDetailsExportData[i].st_attainment ? connDetailsExportData[i].st_attainment : '-'}` + '","'
            + `${connDetailsExportData[i].st_dep_scr ? connDetailsExportData[i].st_dep_scr : '-'}` + '","' 
            + `${connDetailsExportData[i].st_dep_pct ? connDetailsExportData[i].st_dep_pct : '-'}` + '","'
            + `${connDetailsExportData[i].stcon_kgyr ? connDetailsExportData[i].stcon_kgyr : '-'}` + '"\r\n';
        }

        let exportTitle = 'FFI_Connectivity_Details_Report_' + theDate + "_" + appStateSvc.export_wid;
        let blob = new Blob([watershedTitles], {type: 'text/csv;charset=utf-8;'});
        let link = document.createElement('a');
        let url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportTitle);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    function exportResilReport(){
        let theDate = new Date();
        theDate = theDate.toLocaleDateString();
        let paramTitles = `"Aggregation Scale = ${wqReportHeader.aggregation};","Scale = ${wqReportHeader.scale};","Project Size Filter = ${wqReportHeader.prjtsizefilters}","Geography = ${wqReportHeader.regionsfilter};","Floodplain (Lateral-Vertical) Connectivity = ${wqReportHeader.latconnfilters};","Protection Rank = ${wqReportHeader.protectionfilters};","Watershed Position = ${wqReportHeader.positionfilters};"\r\n`;
        let watershedTitles = paramTitles += '"Area","Floodplain Connectivity Attainment (%)","Inundation Building Gross Value","Inundation Transportation Gross Value","Inundation Agriculture Gross Value","Inundation Gross Value","Erosion Building Gross Value","Erosion Transportation Gross Value","Erosion Agriculture Gross Value","Erosion Gross Value"\r\n';
        for(let i = 0; i < resilReportExport.length; i++){
            watershedTitles += '"' + `${resilReportExport[i].region2 ? resilReportExport[i].region2 : '-'}` + '","'
            + `${resilReportExport[i].fp_attainment ? resilReportExport[i].fp_attainment : '-'}`+ '","'
            + `${resilReportExport[i].fp_ibg ? resilReportExport[i].fp_ibg : '-'}`+ '","'
            + `${resilReportExport[i].fp_itg ? resilReportExport[i].fp_itg : '-'}`+ '","'
            + `${resilReportExport[i].fp_iag ? resilReportExport[i].fp_iag : '-'}`+ '","'
            + `${resilReportExport[i].fp_ig ? resilReportExport[i].fp_ig : '-'}`+ '","'
            + `${resilReportExport[i].fp_ebg ? resilReportExport[i].fp_ebg : '-'}`+ '","'
            + `${resilReportExport[i].fp_etg ? resilReportExport[i].fp_etg : '-'}`+ '","'
            + `${resilReportExport[i].fp_eag ? resilReportExport[i].fp_eag : '-'}` + '","'
            + `${resilReportExport[i].fp_eg ? resilReportExport[i].fp_eg : '-'}` + '"\r\n';
        }
        let exportTitle = 'FFI_Resiliency_Report' + theDate + "_" + appStateSvc.export_wid;
        let blob = new Blob([watershedTitles], {type: 'text/csv;charset=utf-8;'});
        let link = document.createElement('a');
        let url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportTitle);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function exportProjectReport(){
        let theDate = new Date();
        theDate = theDate.toLocaleDateString();
        let paramTitles = `"Aggregation Scale = ${wqReportHeader.aggregation};","Scale = ${wqReportHeader.scale};","Geography = ${wqReportHeader.regionsfilter};","Floodplain (Lateral-Vertical) Connectivity = ${wqReportHeader.latconnfilters};","Stream (Longitidinal-Temporal) Connectivity = ${wqReportHeader.longconnfilters};","Protection Rank = ${wqReportHeader.protectionfilters};","Watershed Position = ${wqReportHeader.positionfilters};","Project Priority = ${wqReportHeader.prjtpriorityfilters};"\r\n`;
        let watershedTitles = paramTitles += '"Area","Floodplain Connectivity Attainment (%)","Stream Connectivity Attainment (%)","Number of River Corridor Projects","Number of Stream Projects","Number of Lower Floodplain","Number of Reconnect Flood Chute","Number of Create Flood Bench","Number of Restore Channel Slope","Number of Restore Channel Roughness and Wood","Number of Raise Channel","Number of Remove Berm","Number of Restore Wetland","Number of Remove Minor Constraint","Number of Remove Major Constraint","Number of Implement River Corridor Easement","Number of NRCS Wetland Reserve","Number of Adopt River Corridor Bylaws","Number of Plant 50-Foot Riparian Area","Number of Plant River Corridor", "Number of Plant Floodplain","Number of Remove Large Flood Control Dam","Number of Remove/Convert Large Peaking Hydro Dam","Number of Remove Large Run of River Dam","Number of Remove/Convert Medium Peaking Hydro Dam","Number of Remove Medium Run of River Dam","Number of Remove Medium Breached Dam","Number of Remove Small Intact Run of River Dam","Number of Remove Small Breached Dam","Number of Replace Bridge (Wbkf>100%)","Number of Number of Replace Bridge (50%>Wbkf>100%)","Number of Replace Bridge (Wbkf<50%), shallow channel (< 2%)","Number of Replace Bridge (Wbkf<50%), steep channel (> 2%)","Number of Replace Culvert (Wbkf>100%)","Number of Replace Culvert (50%>Wbkf>100%), shallow channel (< 2%)","Number of Replace Culvert (50%>Wbkf>100%), steep channel (> 2%)","Number of Replace Culvert (Wbkf<50%), shallow channel (< 2%)","Number of Replace Culvert (Wbkf<50%), steep channel (> 2%)","Number of Stabilize Headcut in Perrenial Stream","Number of Stabilize Gully","Number of Stabilize Gully with Treatment of Stormwater","Number of Backwater Culvert with Weir or Other Approach","Number of Place Baffles in Culvert","Number of Replace Culvert (No Wbkf Data), shallow channel (< 2%)","Number of Replace Culvert (No Wbkf Data), steep channel (> 2%)"\r\n';
        for(let i = 0; i < projReportExport.length; i++){
            watershedTitles += '"' + `${projReportExport[i].region ? projReportExport[i].region : '-'}` + '","'
            + `${projReportExport[i].rc_attainment}` + '","'
            //missing value in row e in excel sheet
            + `${projReportExport[i].st_attainment}` + '","'
            + `${projReportExport[i].rc_prjts}` + '","'
            + `${projReportExport[i].st_prjts}` + '","'
            + `${projReportExport[i].rc_lwrfldpln}` + '","'
            + `${projReportExport[i].rc_fldcht}` + '","'
            + `${projReportExport[i].rc_fldbnch}` + '","'
            + `${projReportExport[i].rc_chnlslp}` + '","'
            + `${projReportExport[i].rc_chnlrghwd}` + '","'
            + `${projReportExport[i].rc_rschnl}` + '","'
            + `${projReportExport[i].rc_rmbrm}` + '","'
            + `${projReportExport[i].rc_rstwtlnd}` + '","'
            + `${projReportExport[i].rc_rstminor}` + '","'
            + `${projReportExport[i].rc_rstmajor}` + '","'
            + `${projReportExport[i].rc_crdesmt}` + '","'
            + `${projReportExport[i].rc_nrcswtlnd}` + '","'
            + `${projReportExport[i].rc_adptblws}` + '","'
            + `${projReportExport[i].rc_plntbuf}` + '","'
            + `${projReportExport[i].rc_plntcor}` + '","'
            + `${projReportExport[i].rc_plntfldpln}` + '","'
            + `${projReportExport[i].st_lgfcdam}` + '","'
            + `${projReportExport[i].st_lgpkgdam}` + '","'
            + `${projReportExport[i].st_lgrordam}` + '","'
            + `${projReportExport[i].st_mdpkgdam}` + '","'
            + `${projReportExport[i].st_mdrordam}` + '","'
            + `${projReportExport[i].st_mdbrchdam}` + '","'
            + `${projReportExport[i].st_smrordam}` + '","'
            + `${projReportExport[i].st_smbrchdam}` + '","'
            + `${projReportExport[i].st_rplbrdge1}` + '","'
            + `${projReportExport[i].st_rplbrdge2}` + '","'
            + `${projReportExport[i].st_rplbrdge3}` + '","'
            + `${projReportExport[i].st_rplbrdge4}` + '","'
            + `${projReportExport[i].st_rplcul1}` + '","'
            + `${projReportExport[i].st_rplcul2}` + '","'
            + `${projReportExport[i].st_rplcul3}` + '","'
            + `${projReportExport[i].st_rplcul4}` + '","'
            + `${projReportExport[i].st_rplcul5}` + '","'
            + `${projReportExport[i].st_hdcut}` + '","'
            + `${projReportExport[i].st_stbgly}` + '","'
            + `${projReportExport[i].st_stbglystwtr}` + '","'
            + `${projReportExport[i].st_weir}` + '","'
            + `${projReportExport[i].st_bffls}` + '","'
            + `${projReportExport[i].st_rplcul6}` + '","'
            + `${projReportExport[i].st_rplcul7}` + '"\r\n'
        };
        let exportTitle = 'FFI_Project_Report_' + theDate + "_" + appStateSvc.export_wid;
        let blob = new Blob([watershedTitles], {type: 'text/csv;charset=utf-8;'});
        let link = document.createElement('a');
        let url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportTitle);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    //exportCSVTemplate is a db function specifically for plugging into other csv templates as you build them #DontDelete #ProofOfConcept
    function exportCSVTemplate(){
        let theDate = new Date();
        theDate = theDate.toLocaleDateString();
        let titles = '"Good morning Dave"\r\n';
        titles += '"' + 'How are you feeling today?' + '"\r\n';
        let exportTitle = 'FFI_Demo_CSV_' + theDate + '_Demo';
        let testBlob = new Blob([titles], {type: 'text/csv;charset=utf-8;'});
        let link = document.createElement('a');
        let url = URL.createObjectURL(testBlob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportTitle);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const value = {
        // where is project outcomes getting set?
        projectOutcomes: projectOutcomes,
        masterProjectPlanningList: masterProjectPlanningList,
        filteredProjectPlanningList: filteredProjectPlanningList,
        selectedProjects: selectedProjects,
        loadedProjectOutcomes: loadedProjectOutcomes,
        pastSelectedRows: pastSelectedRows,
        selectedFeatureType: selectedFeatureType,
        emptyMasterRes: emptyMasterRes,
        riverStabilityRes: riverStabilityRes,
        streamStabilityRes: streamStabilityRes,
        // watershedReporting: watershedReporting,
        connSummReport: connSummReport,
        projectReport: projectReport,
        watershedReporting: watershedReporting,
        allWQProjects: allWQProjects,
        watershedExportData: watershedExportData,
        connDetailsExportData: connDetailsExportData,
        projReportExport: projReportExport,
        resilReportExport: resilReportExport,
        wqReportHeader: wqReportHeader,
        setProjectOutcomes: (val) => setProjectOutcomes(val),
        setMasterProjectPlanningList: (val) => setMasterProjectPlanningList(val),
        setFilteredProjectPlanningList: (val) => setFilteredProjectPlanningList(val),
        setSelectedProjects: (val) => setSelectedProjects(val),
        setLoadedProjectOutcomes: (val) => setLoadedProjectOutcomes(val),
        setPastSelectedRows: (val) => setPastSelectedRows(val),
        setSelectedFeatureType: (val) => setSelectedFeatureType(val),
        setEmptyMasterRes: (val) => setEmptyMasterRes(val),
        setRiverStabilityRes: (val) => setRiverStabilityRes(val),
        setStreamStabilityRes: (val) => setStreamStabilityRes(val),
        setWatershedReporting: (val) => setWatershedReporting(val),
        // setWatershedReporting: (val) => setWatershedReporting(val),
        setConnSummReport: (val) => setConnSummReport(val),
        setProjectReport: (val) => setProjectReport(val),
        setWatershedExportData: (val) => setWatershedExportData(val),
        setConnDetailsExportData: (val) => setConnDetailsExportData(val),
        setProjReportExport: (val) => setProjReportExport(val),
        setResilReportExport: (val) => setResilReportExport(val),
        setWqReportHeader: (val) => setWqReportHeader(val),
        getAllProjects: getAllProjects,
        filterProjects: filterProjects,
        fetchProjectPlanningList: fetchProjectPlanningList,
        // allWQProjects: allWQProjects,
        //fetchWQProjects: fetchWQProjects,
        fetchWQProjectList: fetchWQProjectList,
        fetchPopupProjectList_Stream: fetchPopupProjectList_Stream,
        fetchPopupProjectList_River: fetchPopupProjectList_River,
        handlePopupProjectList: handlePopupProjectList,
        fetchProjectPlanningByPopupId: fetchProjectPlanningByPopupId,
        fetchProjCalcInputs_Rivers: fetchProjCalcInputs_Rivers,
        fetchProjCalcInputs_Streams: fetchProjCalcInputs_Streams,
        postProjCalcInputs_Streams: postProjCalcInputs_Streams,
        postProjCalcInputs_Rivers: postProjCalcInputs_Rivers,
        postProjCalcInputs_Storage: postProjCalcInputs_Storage,
        getProjectList: getProjectList,
        exportProjectScreening: exportProjectScreening,
        exportCalcInputs: exportCalcInputs,
        exportWQTab_Summary: exportWQTab_Summary,
        exportWQTab_Details: exportWQTab_Details,
        getProjectResiliency_Unit: getProjectResiliency_Unit,
        getProjectResiliency_Risk: getProjectResiliency_Risk,
        getProjectResiliency_Summary: getProjectResiliency_Summary,
        exportWaterResiliencyCSV: exportWaterResiliencyCSV,
        exportConnDetailsReport: exportConnDetailsReport,
        exportResilReport: exportResilReport,
        exportProjectReport: exportProjectReport,
        exportCSVTemplate: exportCSVTemplate
    };
    return (
    <ProjectContext.Provider value={value}>
        {children}
    </ProjectContext.Provider>
    );
};

export const useProjects = () => {
    return useContext(ProjectContext);
};