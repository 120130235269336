import React, {useState, createContext, useContext, useEffect} from 'react';

const AppStateContext = createContext(null);

export const AppStateProvider = ({children, ...services}) => {
    const [canOpenOutcome, setCanOpenOutcomes] = useState(false);
    const [tableSelected, setTableSelected] = useState('projects');
    const [showTable, setShowTable] = useState(true);
    const [showOutcomesButton, setShowOutcomesButton] = useState(false);
    const [selectedScale, setSelectedScale] = useState('');
    const [mapView, setMapView] = useState(null);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [popupAttributes, setPopupAttributes] = useState({});
    const [selectedMapId, setSelectedMapId] = useState(null);
    const [makeAlert, setMakeAlert] = useState(false);
    const [makeAlert_Export, setMakeAlert_Export] = useState(false);
    const [makeAlert_Calc, setMakeAlert_Calc] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertText_Calc, setAlertText_Calc] = useState([]);
    const [defaultSliderHeight, setDefaultSliderHeight] = useState(window.innerHeight - 70);
    const [resetSliderHeight, setResetSliderHeight] = useState(false);
    const [currentSliderHeight, setCurrentSliderHeight] = useState(500);
    //checkedRivers = array of the checked off rivers
    const [checkedRivers, setCheckedRivers] = useState([]);
    //checkedStreams = array of the checked off stream segments
    const [checkedStreams, setCheckedStreams] = useState([]);
    const [checkedProjectTypes, setCheckedProjectTypes] = useState([]);
    //river row object when its checked off (used in phosphorus table)
    const [riverRowObj, setRiverRowObj] = useState([]);
    //stream row obj when its checked off (used in phosphorus table)
    const [streamRowObj, setStreamRowObj] = useState([]);
    const [storageRowObj, setStorageRowObj] = useState([]);
    //stream project checkboxes
    const [streamProjects, setStreamProjects] = useState([]);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [popupType, setPopupType] = useState('');
    const [wq_Rivers, setWq_Rivers] = useState([]);
    const [wq_Streams, setWq_Streams] = useState([]);
    const [wq_Storage, setWq_Storage] = useState([]);
    const [hideProjScreeningBtn, setHideProjScreeningBtn] = useState(false);
    const [defaultAutoCheck_river, setDefaultAutoCheck_river] = useState(true);
    const [defaultAutoCheck_stream, setDefaultAutoCheck_stream] = useState(true);
    const [export_wid, setExport_wid] = useState(-1);
    const [reportToExport, setReportToExport] = useState('');
    const [subUnitIds, setSubUnitIds] = useState([]);
    const [uniqueProjects, setUniqueProjects] = useState([]);
    const [uniqueTowns, setUniqueTowns] = useState([]);
    const [uniqueStreamNames, setUniqueStreamNames] = useState([]);
    const [summaryRows_Rivers, setSummaryRows_Rivers] = useState([]);
    const [summaryRows_Streams, setSummaryRows_Streams] = useState([]);
    const [summaryRows_Storage, setSummaryRows_Storage] = useState([]);
    const [resilUnits, setResilUnits] = useState([]);
    const [resilRisk, setResilRisk] = useState([]);
    const [resilSummary, setResilSummary] = useState([]);
    const [projectArea, setProjectArea] = useState(0);
    const [buffering, setBuffering] = useState(false);

    function addSliderHeight(){
        // console.log('adding height firing')
        if(currentSliderHeight == window.innerHeight){
            return null;
        }
        setCurrentSliderHeight(currentSliderHeight - 50);
        sliderHeightChecker('up')
    };

    function decreaseSliderHeight(){
        let target = document.getElementById('split-pane-divider');
		// console.log('target coordinates', target.getBoundingClientRect(), window.innerHeight);
        if(currentSliderHeight == defaultSliderHeight){
            return null;
        }
        setCurrentSliderHeight(currentSliderHeight + 50);
        sliderHeightChecker('down')
    };

    function sliderHeightChecker(str){
        let slider = document.getElementById('split-pane-divider');
        // console.log('target coordinates', slider.getBoundingClientRect());
        let target = slider.getBoundingClientRect();
        if(target.bottom + 50 > window.innerHeight){
            // console.log('¡activáte!', str)
            return setCurrentSliderHeight(window.innerHeight - 70);
        }
    };

    function updateProjectArea(num, str){
        projectArea[str] = num;
        return projectArea;
    };

    //Chris' attempt to make a custom hook for the window inner height
    function handleResize(){
        // console.log('handleResizefiring')
        setWindowHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    function clearSearch(){
        setCheckedRivers([]);
        setCheckedStreams([]);
        setCheckedProjectTypes([]);
        setRiverRowObj([]);
        setStreamRowObj([]);
        setStreamProjects([]);
        setStorageRowObj([]);
        setWq_Rivers([]);
        setWq_Streams([]);
        setWq_Storage([]);
        setShowTable(false);
    };

    const value = {
        canOpenOutcome: canOpenOutcome,
        tableSelected: tableSelected,
        showTable: showTable,
        showOutcomesButton: showOutcomesButton,
        selectedScale: selectedScale,
        mapView: mapView,
        selectedPoint: selectedPoint,
        popupAttributes: popupAttributes,
        selectedMapId: selectedMapId,
        makeAlert: makeAlert,
        alertText: alertText,
        alertText_Calc: alertText_Calc,
        makeAlert_Calc: makeAlert_Calc,
        defaultSliderHeight: defaultSliderHeight,
        resetSliderHeight: resetSliderHeight,
        currentSliderHeight: currentSliderHeight,
        checkedRivers: checkedRivers,
        checkedProjectTypes: checkedProjectTypes,
        checkedStreams: checkedStreams,
        riverRowObj: riverRowObj,
        streamRowObj: streamRowObj,
        streamProjects: streamProjects,
        storageRowObj: storageRowObj,
        windowHeight: windowHeight,
        popupType: popupType,
        wq_Rivers: wq_Rivers,
        wq_Streams: wq_Streams,
        wq_Storage: wq_Storage,
        hideProjScreeningBtn: hideProjScreeningBtn,
        defaultAutoCheck_river: defaultAutoCheck_river,
        defaultAutoCheck_stream: defaultAutoCheck_stream,
        makeAlert_Export: makeAlert_Export,
        export_wid: export_wid,
        reportToExport: reportToExport,
        subUnitIds: subUnitIds,
        uniqueProjects: uniqueProjects,
        uniqueTowns: uniqueTowns,
        uniqueStreamNames: uniqueStreamNames,
        summaryRows_Rivers: summaryRows_Rivers,
        summaryRows_Streams: summaryRows_Streams,
        summaryRows_Storage: summaryRows_Storage,
        projectArea: projectArea,
        resilUnits: resilUnits,
        resilRisk: resilRisk,
        resilSummary: resilSummary,
        buffering: buffering,
        setCanOpenOutcomes: (val) => setCanOpenOutcomes(val),
        setTableSelected: (val) => setTableSelected(val),
        setShowTable: (val) => setShowTable(val),
        setShowOutcomesButton: (val) => setShowOutcomesButton(val),
        setSelectedScale: (val) => setSelectedScale(val),
        setMapView: (val) => setMapView(val),
        setSelectedPoint: (val) => setSelectedPoint(val),
        setPopupAttributes: (val) => setPopupAttributes(val),
        setSelectedMapId: (val) => setSelectedMapId(val),
        setMakeAlert: (val) => setMakeAlert(val),
        setAlertText: (val) => setAlertText(val),
        setAlertText_Calc: (val) => setAlertText_Calc(val),
        setMakeAlert_Calc: (val) => setMakeAlert_Calc(val),
        setDefaultSliderHeight: (val) => setDefaultSliderHeight(val),
        setResetSliderHeight: (val) => setResetSliderHeight(val),
        setCurrentSliderHeight: (val) => setCurrentSliderHeight(val),
        setCheckedRivers: (val) => setCheckedRivers(val),
        setCheckedProjectTypes: (val) => setCheckedProjectTypes(val),
        setCheckedStreams: (val) => setCheckedStreams(val),
        setRiverRowObj: (val) => setRiverRowObj(val),
        setStreamRowObj: (val) => setStreamRowObj(val),
        setStreamProjects: (val) => setStreamProjects(val),
        setStorageRowObj: (val) => setStorageRowObj(val),
        setWindowHeight: (val) => setWindowHeight(val),
        setPopupType: (val) => setPopupType(val),
        setWq_Rivers: (val) => setWq_Rivers(val),
        setWq_Streams: (val) => setWq_Streams(val),
        setWq_Storage: (val) => setWq_Storage(val),
        setDefaultAutoCheck_river: (val) => setDefaultAutoCheck_river(val),
        setDefaultAutoCheck_stream: (val) => setDefaultAutoCheck_stream(val),
        setHideProjScreeningBtn: (val) => setHideProjScreeningBtn(val),
        setMakeAlert_Export: (val) => setMakeAlert_Export(val),
        setExport_wid: (val) => setExport_wid(val),
        setReportToExport: (val) => setReportToExport(val),
        setSubUnitIds: (val) => setSubUnitIds(val),
        setUniqueProjects: (val) => setUniqueProjects(val),
        setUniqueTowns: (val) => setUniqueTowns(val),
        setUniqueStreamNames: (val) => setUniqueStreamNames(val),
        setSummaryRows_Rivers: (val) => setSummaryRows_Rivers(val),
        setSummaryRows_Streams: (val) => setSummaryRows_Streams(val),
        setSummaryRows_Storage: (val) => setSummaryRows_Storage(val),
        setProjectArea: (val) => setProjectArea(val),
        setResilUnits: (val) => setResilUnits(val),
        setResilRisk: (val) => setResilRisk(val),
        setResilSummary: (val) => setResilSummary(val),
        setBuffering: (val) => setBuffering(val),
        addSliderHeight: addSliderHeight,
        decreaseSliderHeight: decreaseSliderHeight,
        clearSearch: clearSearch,
        sliderHeightChecker: sliderHeightChecker,
        updateProjectArea: updateProjectArea
    };
    return (
        <AppStateContext.Provider value={value}>
            {children}
        </AppStateContext.Provider>
    )
};

export const useAppState = () => {
    return useContext(AppStateContext);
};
