import React from 'react';
import './ImportData.css';

export default function ImportData(){
    return(
        <div className="import-data">
            <div className="import-data-content">
                Import Data Goes Here
            </div>
        </div>
    )
}